import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		alignItems: 'center',
		alignContent: 'center',
		alignSelf: 'center',
		justifyContent: 'center'
		// backgroundColor: theme.palette.background.paper,
	},
}));

export default function Home()
{
	const classes = useStyles();

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Grid
				container
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Grid item>

					<img src={require('shared/utils/images/NilfiskFood.png')} alt="NilfiskFood" />
				</Grid>
				<Grid item>

					<Typography variant='h3'>Stay connected with your machines</Typography>
				</Grid>
			</Grid>
			{/* {JSON.stringify(user, null, 2)} */}
		</div>
	);
}
