import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import { updateFactoryById, deleteFactory, getFactoriesByCompanyId } from 'shared/services/factory.service';
import MaterialTable, { MTableAction } from 'material-table';
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
    Refresh,
    DeviceHub,
    Visibility
} from '@mui/icons-material';
import { Link as RouterLink, Route, MemoryRouter, useParams } from 'react-router-dom';

import format from 'date-format';

import { Provider, Translate, Translator } from 'react-translated';
import {
    CardHeader, Typography, Divider, Button, Grid, CircularProgress,
    LinearProgress, Breadcrumbs, Paper, Card, Link, TextField, Box, Snackbar,
    Grow
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

import { useSelector } from 'react-redux';

import { getOwnFactoryNameById, getFactoryNameById } from 'shared/services/factory.service';

import { getOwnRoomsByFactoryId, getRoomsByFactoryId, createOwnRoom, createRoom, deleteOwnRoom, deleteRoom } from 'shared/services/room.service';
import { searchAndfilterFactories, searchAndfilterOwnFactories } from 'shared/services/factory.service';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { useFormik } from 'formik';
import * as yup from 'yup';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const validationSchema = yup.object({
    name: yup.string('Enter room name').required('Room name is required'),
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function ViewRooms(props) {
    const myAccount = useSelector((state) => state.user.user);
    const classes = useStyles();

    const [factoryName, setFactoryName] = useState(null);
    const [rooms, setRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    let params = useParams();

    useEffect(() => {

        if (props.location.state?.factoryName != null) {
            setFactoryName(props.location.state?.factoryName)
        }
        if (hasPermission("Room", "read"))
            getRoomsByFactoryId(params.factoryId).then(res => {
                console.log(res)
                getFactoryNameById(params.factoryId).then(res => {
                    console.log("factory", res)
                    setFactoryName(res.data.factory[0].name)
                })
                setRooms(res.data.rooms);
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
            })
        else
            if (hasPermission("RoomOwn", "read"))
                getOwnRoomsByFactoryId(params.factoryId).then(res => {
                    getOwnFactoryNameById(params.factoryId).then(res => {
                        console.log("factory", res)
                        setFactoryName(res.data.factory[0].name)
                    })
                    setRooms(res.data.rooms);
                    setIsLoading(false);
                }).catch(err => {
                    setIsLoading(false);
                })
    }, [])



    const formik = useFormik({
        initialValues: {
            name: "",
            FactoryId: params.factoryId
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            setIsLoading(true);
            setOpen(false);

            if (hasPermission("Room", "create"))
                createRoom(values).then(res => {
                    console.log(res);
                    // setIsLoading(false);
                    getRoomsByFactoryId(params.factoryId).then(res => {
                        setRooms(res.data.rooms);

                        setRemoveMachines(false);
                        setSuccessful(true);
                        setOpen(true);
                        setIsLoading(false);
                    }).catch(err => {

                        setIsLoading(false);
                    })
                }).catch((err) => {

                    setRemoveMachines(false);
                    setSuccessful(false);
                    setOpen(true);
                })
            else
                if (hasPermission("RoomOwn", "create"))
                    createOwnRoom(values).then(res => {
                        console.log(res);
                        // setIsLoading(false);
                        getOwnRoomsByFactoryId(params.factoryId).then(res => {
                            setRooms(res.data.rooms);

                            setRemoveMachines(false);
                            setSuccessful(true);
                            setOpen(true);
                            setIsLoading(false);
                        }).catch(err => {
                            setIsLoading(false);
                        })
                    }).catch((err) => {

                        setRemoveMachines(false);
                        setSuccessful(false);
                        setOpen(true);
                    })
                else
                    setIsLoading(false);
        },
    });

    /**
    * @param  {string} resource - Resource name
    * @param  {string} permission - Permission type (create/read/update/delete)
    */
    const hasPermission = (resource, permission) => {
        const result = myAccount.Role.PermissionResources.filter(item => item.Resource.name == resource)
        if (!result.length)
            return false;

        if (result[0].Permission[permission] == true)
            return true
        else
            return false

    }


    const [open, setOpen] = React.useState(false);
    const [successful, setSuccessful] = React.useState(true);
    const [removeMachines, setRemoveMachines] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Translator>
            {({ translate }) => (
                <>

                    <div className={classes.root}>

                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5">Rooms</Typography>

                                <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 20 }}>
                                    <LinkRouter underline="hover" color="inherit" to="/Home">
                                        Home
                		        </LinkRouter>
                                    <LinkRouter underline="hover" color="inherit" to="/factories">
                                        Factories
                		        </LinkRouter>
                                    <LinkRouter underline="hover" color="inherit" to={"/factory/view/" + params.factoryId + "/machines"}>
                                        {factoryName ??
                                            <Box sx={{ width: '100%' }}>
                                                Factory
                                            <LinearProgress />
                                            </Box>
                                        }
                                    </LinkRouter>
                                    <Typography color="text.primary">Rooms</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" >

                            <Grid item xs={12} sm={12} md={6} >
                                <Card variant="outlined" style={{ borderRadius: 20, margin: 5 }}>
                                    <CardHeader title={"Add Room"} />
                                    <Divider />


                                    <form onSubmit={formik.handleSubmit} style={{ paddingLeft: 20, paddingRight: 20 }}
                                    >
                                        <TextField
                                            name="name"
                                            type="name"
                                            label="Room name"
                                            variant="outlined"
                                            margin="normal"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                            disabled={isLoading}
                                            fullWidth
                                        />


                                        <Button type="submit" style={{ marginBottom: 15, marginTop: 10 }} variant="contained"
                                            disabled={isLoading} // startIcon={<SaveIcon />}
                                        >
                                            Create Room
           					        </Button>
                                    </form>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} style={{}}>


                                <Card variant="outlined" style={{ borderRadius: 20, margin: 5 }}>
                                    <CardHeader title={"Rooms"} />
                                    <Divider />

                                    <MaterialTable
                                        icons={tableIcons}
                                        columns={[
                                            // { title: 'Status', field: 'status' },
                                            // {
                                            //     title: <Translate text='Duration of last cleaning' />,
                                            //     field: 'durationOfLastCleaning',
                                            // },
                                            // {
                                            //     title: <Translate text='Production Start' />,
                                            //     field: 'productionStart'
                                            // },
                                            { title: <Translate text='Room Name' />, field: 'name' },
                                            // { title: <Translate text='Address' />, field: 'address' },
                                            // { title: <Translate text='City' />, field: 'city' },
                                            // { title: <Translate text='Country' />, field: 'country' }
                                        ]}
                                        data={rooms}
                                        actions={[
                                            rowData => ({
                                                icon: tableIcons.Delete,
                                                tooltip: 'Delete Room',
                                                onClick: (event, rowData) => {
                                                    console.log(rowData)
                                                    setIsLoading(true);

                                                    if (hasPermission("Room", "create"))
                                                        deleteRoom(rowData.id).then(res => {
                                                            console.log(res)
                                                            getRoomsByFactoryId(params.factoryId).then(res => {
                                                                setRooms(res.data.rooms);
                                                                setRemoveMachines(false);
                                                                setSuccessful(true);
                                                                setOpen(true);
                                                                setIsLoading(false);
                                                            }).catch(err => {
                                                                setIsLoading(false);
                                                            })
                                                        }).catch(err => {
                                                            if (err?.response?.data?.msg == "The room must be empty before removing the room") {
                                                                setRemoveMachines(true);
                                                                setSuccessful(false);
                                                                setOpen(true);
                                                            }
                                                            setIsLoading(false);
                                                        })
                                                    else
                                                        if (hasPermission("RoomOwn", "create"))
                                                            deleteOwnRoom(rowData.id).then(res => {
                                                                console.log(res)
                                                                getOwnRoomsByFactoryId(params.factoryId).then(res => {
                                                                    setRooms(res.data.rooms);
                                                                    setRemoveMachines(false);
                                                                    setSuccessful(true);
                                                                    setOpen(true);
                                                                    setIsLoading(false);
                                                                }).catch(err => {
                                                                    setIsLoading(false);
                                                                })
                                                            }).catch(err => {
                                                                if (err?.response?.data?.msg == "The room must be empty before removing the room") {
                                                                    setRemoveMachines(true);
                                                                    setSuccessful(false);
                                                                    setOpen(true);
                                                                }
                                                                setIsLoading(false);
                                                            })
                                                        else
                                                            setIsLoading(false);
                                                },
                                                // disabled: rowData.birthYear < 2000
                                            })
                                        ]}
                                        options={{
                                            showTitle: false,
                                            search: false,
                                            toolbar: false,
                                            actionsColumnIndex: -1,
                                            // headerStyle: {padding: 50}
                                            debounceInterval: 500,
                                            pageSize: 10,
                                            pageSizeOptions: [5, 10, 20, 50],
                                        }}
                                        isLoading={isLoading}
                                    />
                                </Card>
                            </Grid>

                        </Grid>
                    </div>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        TransitionComponent={(props) => <Grow {...props} />}
                    >
                        <Alert onClose={handleClose} severity={successful ? "success" : "error"} sx={{ width: '100%' }}>
                            {!successful && removeMachines ? "You must move the machines within the room before deleting the room" :
                                successful ? "The room was created successfully." : "There was an error while creating the room."
                            }
                        </Alert>
                    </Snackbar>
                </>
            )
            }
        </Translator >
    );
}


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    table: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    textField: {
        width: 300,
        margin: 10
    },
    datePicker: {
        width: 100,
        marginLeft: 10
    },
    button: {
        margin: theme.spacing(1)
    },
}));