// import apiClient from "@/api/apiClient"
import apiClient from "./apiClient";

/* ----- Get Methods ----- */
export const getMyAccount = () => {
  return apiClient.get("users/my-account");
};

export const getUserById = (UserId) => {
  return apiClient.get("users/" + UserId);
};

export const getOwnUserById = (UserId) => {
  return apiClient.get("user/" + UserId); //TODO change this method to /api/user/own/:id
};

export const getOwnUsersByFactoryId = (FactoryId, data) => {
  return apiClient.post("v2/users/own/factory/" + FactoryId, data);
};

export const getUsersByFactoryId = (FactoryId, data) => {
  return apiClient.post("v2/users/factory/" + FactoryId, data);
};

/* -- possible not used methods -- */

// export const getUsers = () => {
//   return apiClient.get("users");
// };
// export const getOwnEmployeeByUserId = (UserId, FactoryId) => {
//   return apiClient.get(`user/own/${UserId}/factory/${FactoryId}`);
// };
/* ----------------------- */

/* ----- Post Methods ----- */
export const confirmAccount = (user) => {
  console.log("confirmAccount", user);
  return apiClient.post("users/confirm_account", user, { skipAuth: true });
};
export const forgotPassword = (user) => {
  return apiClient.post(
    "users/forgot_password",
    {
      email: user.email,
    },
    { skipAuth: true }
  );
};

export const resetPassword = (data) => {
  console.log("resetPassword", data);
  return apiClient.post("users/reset_password", data, { skipAuth: true });
};

export const registerUser = (newUser) => {
  return apiClient.post("users/register", newUser, { skipAuth: true });
};
export const login = (user) => {
  return apiClient.post("users/login", {
    email: user.email,
    password: user.password,
  });
  // .then((response) => {
  //   localStorage.setItem("token", JSON.stringify(response.data)); //respnse.data.token to store only the token, probably will be better?
  //   return response.data;
  // });
};
export const createUser = (newUser) => {
  return apiClient.post("users", newUser);
};

export const createUserInAFactoryAdmin = (newUser) => {
  return apiClient.post("factory/users/admin", newUser);
};
export const createUserInMyFactory = (newUser) => {
  return apiClient.post("factory/users", newUser);
};
export const searchAndFilterUsers = (filters) => {
  return apiClient.post("users/search/order", filters);
};
export const searchUsers = (search) => {
  return apiClient.post("users/search/advance", search);
};

/* ----------------------- */

/* ----- Put Methods ----- */

export const changeMyPassword = (data) => {
  return apiClient.put("user/password/change-my-password", data);
};

export const updateMyAccount = (user) => {
  return apiClient.put("users/my-account", user);
};
export const updateOwnUser = (UserId, user) => {
  return apiClient.put("user/" + UserId, user);
};
export const updateUser = (UserId, user) => {
  return apiClient.put("users/" + UserId, user);
};
export const updateUserLanguage = (user) => {
  return apiClient.put("users/language", user);
};
export const updateUserPassword = (user) => {
  return apiClient.put("users/own/change-my-password", user);
};
export const getUserLanguage = () => {
  return apiClient.get("users/language");
};

export const deletePermanentlyMyAccount = () => {
  return apiClient.delete("user/delete-my-account");
};

export const CloseMyAccount = () => {
  return apiClient.delete("user/close-my-account");
};

export const searchOwnUsers = (search) => {
  return apiClient.post("users/own/search/advance", search);
};
export const deleteUser = (UserId) => {
  return apiClient.delete("users/" + UserId);
};
export const deleteUserAccountOwn = (UserId) => {
  return apiClient.delete("users/" + UserId + "/delete-account/own");
};

export const deactivateUserAccountOwn = (UserId) => {
  return apiClient.put("users/" + UserId + "/deactivate-account/own", {});
};
export const activateUserAccountOwn = (UserId) => {
  return apiClient.put("users/" + UserId + "/activate-account/own", {});
};

export const deactivateUserAccount = (UserId) => {
  return apiClient.put("users/" + UserId + "/deactivate-account", {});
};

export const activateUserAccount = (UserId) => {
  return apiClient.put("users/" + UserId + "/activate-account", {});
};

//ban user
export const banUser = (UserId, data) => {
  return apiClient.put("users/" + UserId + "/ban", data);
};

//ban user own
export const banUserOwn = (UserId, data) => {
  return apiClient.put("users/" + UserId + "/ban/own", data);
};

//unban user
export const unbanUser = (UserId) => {
  return apiClient.put("users/" + UserId + "/unban", {});
};

//unban user own
export const unbanUserOwn = (UserId) => {
  return apiClient.put("users/" + UserId + "/unban/own", {});
};
