import apiClient from "./apiClient";

/* ----- Get Methods ----- */
export const searchOwnFactories = (data) => {
  return apiClient.post("/v2/factories/own/filter", data);
};

export const searchFactories = (data) => {
  return apiClient.post("/v2/factories/filter", data);
};

export const createFactory = (data) => {
  return apiClient.post("/factories/own", data);
};

export const getOwnFactoryById = (id) => {
  return apiClient.get(`/factories/own/${id}`);
};

/* ----- Put Methods ----- */
export const updateFactory = ({ id, data }) => {
  return apiClient.put(`/factories/own/${id}`, data);
};
