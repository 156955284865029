import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { userService } from "shared/services/authentication.service";
import { useSelector } from "react-redux";

//isAuthenticated can be used in futer for a beter authentication check
export const PrivateRoute = ({ children }) => {
  const isAllowed = userService.loggedIn();
  return isAllowed ? <Outlet /> : <Navigate to="/login" replace />;
};

export const IsAuthenticatedRoute = ({ children }) => {
  const isAllowed = userService.loggedIn();
  return isAllowed ? <Outlet /> : <Navigate to="/login" replace />;
};

export const IsAdminRoute = ({ children }) => {
  const myAccount = useSelector((state) => state.user.user);
  const isAuthenticated = userService.loggedIn();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  const isAdmin = myAccount && (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return isAdmin ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

export const IsTechnicianRoute = ({ children }) => {
  const myAccount = useSelector((state) => state.user.user);
  const isAuthenticated = userService.loggedIn();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  const isAdmin = myAccount && (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin" || myAccount.Role.name === "Technician");

  return isAdmin ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

export const SuperAdminRoute = ({ component: Component, ...rest }) => {
  const myAccount = useSelector((state) => state.user.user);

  return (
    <Route
      {...rest}
      children={(props) =>
        userService.loggedIn() && myAccount && myAccount.Role.name === "Super Admin" ? (
          <Component {...props} />
        ) : (
          <Navigate to="/Home" />
        )
      }
    />
  );
};
