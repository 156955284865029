import React, { useState, useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import Grow from '@mui/material/Grow';
import { makeStyles } from '@mui/styles';
import { getOwnFactoryById, updateOwnFactoryById, updateFactoryById, getFactoryById, deleteOwnFactory, deleteFactory } from 'shared/services/factory.service';
import { Provider, Translate, Translator } from 'react-translated';
import { Paper, Card, Grid, CardHeader, Button, Breadcrumbs, Typography, TextField, Snackbar, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import NativeSelect from '@mui/material/NativeSelect';
import SaveIcon from '@mui/icons-material/Save';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useFormik } from 'formik';
import * as yup from 'yup';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { useParams, Navigate  } from 'react-router-dom';
import { Link as RouterLink, Route, MemoryRouter } from 'react-router-dom';
import { styled } from '@mui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';
import AdvanceSnackbar from 'components/AdvanceSnackbar';
import { Delete, DeleteForever } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref)
{
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const validationSchema = yup.object({
	name: yup.string('Enter factory name').required('Name is required'),
	CVR: yup.string('Enter CVR name').required('CVR is required'),
	address: yup.string('Enter address').required('Address is required'),
	city: yup.string('Enter city').required('City is required'),
	country: yup.string('Enter country').required('Country is required'),
	status: yup.string('Select status').required('Status is required'),
	zip: yup.string('Enter zip code').required('Zip code is required'),
	// productionStart: yup.string('Enter the time when the production starts'),
});


export default function FactoryEdit()
{
	const myAccount = useSelector((state) => state.user.user);
	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [openDeleteFactory, setOpenDeleteFactory] = useState(false);
	const [removeBtnLoading, setRemoveBtnLoading] = useState(false);
	const [snackbarSuccessful, setSnackbarSuccessful] = useState(false);
	const [openSnackbarRemove, setOpenSnackbarRemove] = useState(false);
	const [redirectToFactories, setRedirectToFactories] = useState(false);
	const [factoryName, setFactoryName] = useState(null);
	let params = useParams();

	const formik = useFormik({
		initialValues: {
			name: "",
			address: "",
			city: "",
			country: "",
			status: "",
			productionStart: "",
			zip: "",
			CVR: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) =>
		{

			setLoading(true);
			let time = values.productionStart;

			let copyValues = { ...values };

			var date = moment(time, 'HH:mm').utc().format("YYYY-MM-DD HH:mm:ss");
			copyValues.productionStart = date;
			if (myAccount.Role.name == "Super Admin" || myAccount.role.name == "Admin")
			{
				updateFactoryById(params.factoryId, copyValues).then(res =>
				{
					console.log("ressss", res)
					setLoading(false);
					setSuccessful(true);
					setOpen(true);

				}).catch(err =>
				{
					console.log(err)
					setLoading(false);
					setSuccessful(false);
					setOpen(true);

				})
			}
			if (myAccount.Role.name == "Manager")
			{
				updateOwnFactoryById(params.factoryId, copyValues).then(res =>
				{
					console.log("ressss", res)
					setLoading(false);
					setSuccessful(true);
					setOpen(true);

				}).catch(err =>
				{
					console.log(err)
					setLoading(false);
					setSuccessful(false);
					setOpen(true);

				})
			}


		},
	});

	useEffect(() =>
	{
		if (myAccount.Role.name == "Super Admin" || myAccount.role.name == "Admin")
		{
			getFactoryById(params.factoryId).then(res =>
			{
				console.log("factory", res);
				const factory = res.data.factory[0];
				let date;
				if (factory.productionStart == null)
					date = moment().format('HH:mm');
				else
					date = moment(factory.productionStart).format('HH:mm')

				console.log("dateeeee", date)

				let obj = {
					name: factory.name,
					address: factory.address,
					city: factory.city,
					zip: factory.zip,
					country: factory.country,
					status: factory.status,
					productionStart: date,
					CVR: factory.CVR
					// latitude: factory.latitude,
					// longitude: factory.longitude,
				}
				setFactoryName(factory.name);
				formik.setValues(obj);
				setLoading(false);
			}).catch(err => { setLoading(false); })
		}
		if (myAccount.Role.name == "Manager")
		{
			getOwnFactoryById(params.factoryId).then(res =>
			{
				const factory = res.data.factory[0];

				let date;
				if (factory.productionStart == null)
					date = moment().format('HH:mm');
				else
					date = moment(factory.productionStart).format('HH:mm')


				let obj = {
					name: factory.name,
					address: factory.address,
					city: factory.city,
					zip: factory.zip,
					country: factory.country,
					status: factory.status,
					productionStart: date,
					CVR: factory.CVR
					// latitude: factory.latitude,
					// longitude: factory.longitude,
				}
				setFactoryName(factory.name);
				formik.setValues(obj);
				setLoading(false);
			}).catch(err => { setLoading(false); })
		}




	}, []);


	const [open, setOpen] = React.useState(false);
	const [successful, setSuccessful] = React.useState(true);
	const handleClick = () =>
	{
		setOpen(true);
	};

	const handleClose = (event, reason) =>
	{
		if (reason === 'clickaway')
		{
			return;
		}

		setOpen(false);
	};

	const handleCloseDeleteFactory = () =>
	{
		setOpenDeleteFactory(false);
	};

	const onDeleteFactoryClick = () =>
	{

		setRemoveBtnLoading(true);

		if (hasPermission("Factory", "delete"))
			deleteFactory(params.factoryId).then(res =>
			{
				console.log("deletion", res)
				setRemoveBtnLoading(false);
				setOpenDeleteFactory(false);
				setSnackbarSuccessful(true);
				setOpenSnackbarRemove(true);
			}).catch(err =>
			{

				setSnackbarSuccessful(false);
				setOpenSnackbarRemove(true);
				setRemoveBtnLoading(false);
			})
		else
			if (hasPermission("FactoryOwn", "delete"))
				deleteOwnFactory(params.factoryId).then(res =>
				{
					console.log("deletion", res)
					setRemoveBtnLoading(false);
					setOpenDeleteFactory(false);
					setSnackbarSuccessful(true);
					setOpenSnackbarRemove(true);
				}).catch(err =>
				{

					setSnackbarSuccessful(false);
					setOpenSnackbarRemove(true);
					setRemoveBtnLoading(false);
				})

	}
	const onDeleteFactoryCloseHandler = React.useCallback(() =>
	{
		setOpenSnackbarRemove(false)
		setRedirectToFactories(true);
	}, [openSnackbarRemove])

	/**
	* @param  {string} resource - Resource name
	* @param  {string} permission - Permission type (create/read/update/delete)
	*/
	const hasPermission = (resource, permission) =>
	{
		const result = myAccount.Role.PermissionResources.filter(item => item.Resource.name == resource)
		if (!result.length)
			return false;

		if (result[0].Permission[permission] == true)
			return true
		else
			return false

	}

	return (
		<Translator>
			{({ translate }) => (
				<div className={classes.root}>
					{redirectToFactories ? (
						<Navigate  to={"/factories"} />
					) : null}

					{loading ? <BorderLinearProgress /> : null}

					<Typography variant="h5">{factoryName ? factoryName : "Factory Edit"}</Typography>
					<Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 20 }}>
						<LinkRouter underline="hover" color="inherit" to="/Home">
							Home
                		</LinkRouter>
						<LinkRouter underline="hover" color="inherit" to="/factories">
							Factories
                		</LinkRouter>
						<LinkRouter underline="hover" color="inherit" to={"/factory/view/" + params.factoryId + "/details"}>
							Factory
                		</LinkRouter>
						<Typography color="text.primary">Edit</Typography>
					</Breadcrumbs>

					<form onSubmit={formik.handleSubmit}>
						<Card variant="outlined" style={{ borderRadius: 20, padding: 20 }}>
							<Grid container
								direction="row"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={2}
							>
								<Grid container item xs={12} sm={12} md={6}
									direction="column"
								>
									<TextField
										name="name"
										type="name"
										label="Name"
										variant="outlined"
										// style={{margin}}
										margin="normal"
										value={formik.values.name}
										onChange={formik.handleChange}
										error={formik.touched.name && Boolean(formik.errors.name)}
										helperText={formik.touched.name && formik.errors.name}
										disabled={loading}
									/>
									<TextField
										name="address"
										type="address"
										label="Address"
										variant="outlined"
										margin="normal"
										value={formik.values.address}
										onChange={formik.handleChange}
										error={formik.touched.address && Boolean(formik.errors.address)}
										helperText={formik.touched.address && formik.errors.address}
										disabled={loading}
									/>
									<TextField
										name="city"
										type="city"
										label="City"
										variant="outlined"
										margin="normal"
										value={formik.values.city}
										onChange={formik.handleChange}
										error={formik.touched.city && Boolean(formik.errors.city)}
										helperText={formik.touched.city && formik.errors.city}
										disabled={loading}
									/>
									<TextField
										name="zip"
										type="zip"
										label="Zip"
										variant="outlined"
										margin="normal"
										value={formik.values.zip}
										onChange={formik.handleChange}
										error={formik.touched.zip && Boolean(formik.errors.zip)}
										helperText={formik.touched.zip && formik.errors.zip}
										disabled={loading}
									/>
									<TextField
										name="country"
										type="country"
										label="Country"
										variant="outlined"
										margin="normal"
										value={formik.values.country}
										onChange={formik.handleChange}
										error={formik.touched.country && Boolean(formik.errors.country)}
										helperText={formik.touched.country && formik.errors.country}
										disabled={loading}
									/>
								</Grid>
								<Grid container item xs={12} sm={12} md={6}
									direction="column"
								>
									<TextField
										name="CVR"
										type="CVR"
										label="CVR"
										variant="outlined"
										margin="normal"
										value={formik.values.CVR}
										onChange={formik.handleChange}
										error={formik.touched.CVR && Boolean(formik.errors.CVR)}
										helperText={formik.touched.CVR && formik.errors.CVR}
										disabled={loading}
									/>
									{myAccount.Role.name == "Super Admin" || myAccount.Role.name == "Admin" ?
										<TextField
											name="status"
											type="status"
											label="Status"
											variant="outlined"
											margin="normal"
											value={formik.values.status}
											onChange={formik.handleChange}
											error={formik.touched.status && Boolean(formik.errors.status)}
											helperText={formik.touched.status && formik.errors.status}
											disabled={loading}
										/>
										: null
									}
									<TextField
										name="productionStart"
										type="time"
										label="Production Start"
										variant="outlined"
										margin="normal"
										// type="time"
										defaultValue="07:30"
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											step: 300, // 5 min
										}}
										sx={{ width: 150 }}
										value={formik.values.productionStart}
										onChange={formik.handleChange}
										error={formik.touched.productionStart && Boolean(formik.errors.productionStart)}
										helperText={formik.touched.productionStart && formik.errors.productionStart}
										disabled={loading}
									/>
									{/* <TextField
										name="latitude"
										type="latitude"
										label="Latitude"
										variant="outlined"
										margin="normal"
										value={formik.values.latitude}
										onChange={formik.handleChange}
									/>
									<TextField
										name="longitude"
										type="longitude"
										label="Longitude"
										variant="outlined"
										margin="normal"
										value={formik.values.longitude}
										onChange={formik.handleChange}
									/> */}
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="flex-start"
							>
								<Grid item alignSelf='center'>
									<Button type="submit" style={{ marginBottom: 15, marginTop: 10 }} variant="contained"
										disabled={loading} startIcon={<SaveIcon />}
									>
										Update Factory
           							</Button>
								</Grid>
								<Grid item alignSelf='center'>
									<Button style={{ marginBottom: 15, marginTop: 10, backgroundColor: 'red' }} variant="contained"
										disabled={loading} startIcon={<Delete />}
										onClick={() => { setOpenDeleteFactory(true) }}
									>
										Delete Factory
           							</Button>
								</Grid>

							</Grid>



						</Card>

					</form>

					<Dialog
						open={openDeleteFactory}
						onClose={handleCloseDeleteFactory}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							Remove this Factory?
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Are you sure you want to delete
								<Box fontWeight='bold' display='inline'> {formik?.values.name} </Box> factory?

          				</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCloseDeleteFactory}>No</Button>
							<LoadingButton onClick={onDeleteFactoryClick} loading={removeBtnLoading}>
								Yes
         				 </LoadingButton>
						</DialogActions>
					</Dialog>


					<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						TransitionComponent={(props) => <Grow {...props} />}
					>
						<Alert onClose={handleClose} severity={successful ? "success" : "error"} sx={{ width: '100%' }}>

							{successful ? "The factory was updated successfully." : "There was an error while updating the factory."}
						</Alert>
					</Snackbar>

					<AdvanceSnackbar
						successfulMessage={"The factory was deleted successfully."}
						errorMessage={"There was an error while deleting the factory."}
						isSuccessful={snackbarSuccessful}
						open={openSnackbarRemove}
						onClose={onDeleteFactoryCloseHandler}
					/>
				</div>
			)}
		</Translator>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	textField: {
		marginLeft: 20, marginRight: 20
	},
	timePicker: {
		width: 100,
		margin: 10
	}
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	// height: 10,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
	},
}));