import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { RectangleButton as ButtonModel, UiElement } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";

export default function RectangleButton({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }): JSX.Element {
  return <InnerButton button={element as ButtonModel} ScreenUpdate={ScreenUpdate} disabled={disabled}></InnerButton>;
}

function InnerButton({ button, ScreenUpdate, disabled }: { button: ButtonModel, ScreenUpdate?: () => void, disabled: boolean }) {
  if (button.imageFile) return <ImgButton button={button}></ImgButton>;
  if (button.telemtryField && button.selected) return <TelemtryCommandButton button={button} ScreenUpdate={ScreenUpdate} disabled={disabled}></TelemtryCommandButton>;
  if (button.telemtryField) return <TelemtryButton button={button} ></TelemtryButton>;

  if (button.command) return <CommandButton button={button} ScreenUpdate={ScreenUpdate} disabled={disabled}></CommandButton>;
  //default case

  return (
    <Grid container justifyContent="center">
      <button style={{ minHeight: "10vh", width: "12vh", color: "white", backgroundColor: "darkblue", border: "solid ", borderRadius: "10%" }}>{button.display_name} </button>
    </Grid>
  );
}

function ImgButton({ button }: { button: ButtonModel }) {
  return (
    <button>
      <img src={"shared/uitls/images/" + button!.imageFile} />
      {button.display_name}
    </button>
  );
}
function TelemtryButton({ button }: { button: ButtonModel }) {
  const Server = GetServer();

  let [val, setVal] = React.useState<number>(0);
  function SetTelemetry(data: DatedIotData) {
    let val = data.data[button.telemtryField!] ? data.data[button.telemtryField!] : 0;
    setVal(val);
  }
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);

  return (
    <Grid container justifyContent="center">
      <button style={{ minHeight: "10vh", width: "12vh", color: "blue", backgroundColor: "darkblue", border: "solid ", borderColor: "darkblue", borderRadius: "10%" }}>
        <Typography fontSize={"1.5cqmin"}>{val + " " + button.display_name}</Typography>
      </button>
    </Grid>
  );
}

function CommandButton({ val, button, ScreenUpdate, disabled }: { button: ButtonModel; val?: number, ScreenUpdate?: () => void, disabled: boolean }) {
  const server = GetServer();
  function sendCommand() {

    let name = button.command!.name;
    server.SendCommand(name, null, () => { });
  }

  return (
    <Grid container justifyContent="center">
      <button onClick={() => {
        if (disabled) {
          return;
        }
        sendCommand();
        ScreenUpdate?.();
      }} style={{ cursor: disabled ? "default" : "pointer", minHeight: "10vh", width: "12vh", color: "white", backgroundColor: "darkblue", border: "solid ", borderRadius: "10%" }}>
        <Typography fontSize={"1.5cqmin"}>{button.display_name}</Typography>
      </button>
    </Grid>
  );
}

// function TelemtrySelectedButton({button} : {button : ButtonModel})  {
//     <button style={ (val ? {backgroundColor:"white", color: "darkblue"} : {})}
//     > {button.display_name} </button>
// }

function TelemtryCommandButton({ button, ScreenUpdate, disabled }: { button: ButtonModel, ScreenUpdate?: () => void, disabled: boolean }) {
  const Server = GetServer();

  let [val, setVal] = React.useState<number>(0);
  let [selected, setSelected] = React.useState<boolean>(false);

  function SetTelemetry(data: DatedIotData) {

    let val = data.data[button.telemtryField!] ? data.data[button.telemtryField!] : 0;

    let index = button.selected?.val!;
    let valueforButton = (val >> ((2 - index) * 8)) & 0xff;

    setVal(valueforButton);
    if (button.selected !== null) {
      let telemetryField = button.selected!.telemtry_field;
      let expectedVal = button.selected!.val;

      let val = data.data[telemetryField] ? data.data[telemetryField] : 0;
      setSelected(val - 1 === expectedVal);
    }
  }
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);
  function sendCommand() {

    let name = button.command!.name;
    let arg_val = button.command!.arg_val;

    Server.SendCommand(`${name}`, arg_val!, () => { });
  }
  if (selected) {
    return (
      <Grid container justifyContent="center">
        <button onClick={() => {
          if (disabled) {
            return;
          }
          sendCommand();
          ScreenUpdate?.();
        }} style={{ cursor: disabled ? "default" : "pointer", minHeight: "5vh", width: "12vh", backgroundColor: "white", border: "solid", borderColor: "darkblue", borderRadius: "10%" }}>
          <Typography color={"darkblue"} fontSize={"1.5cqmin"}>{val + " " + button.display_name}</Typography>
        </button>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <button onClick={() => {
        sendCommand();
        ScreenUpdate?.();
      }} style={{ cursor: "pointer", minHeight: "5vh", width: "12vh", color: "white", backgroundColor: "darkblue", border: "solid ", borderRadius: "10%" }}>
        <Typography fontSize={"1.5cqmin"}>{val + " " + button.display_name}</Typography>
      </button>
    </Grid>
  );
}
