import React from "react";
import { login, getMyAccount } from "shared/services/user.service";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { useDispatch } from "react-redux";
import { setUser } from "shared/store/user/actions/userActions";
import ErrorAlert from "components/ErrorAlert";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { registerUser } from "api/user.service";

const validationSchema = yup.object({
  firstName: yup.string("Enter first name").required("First name is required"),
  lastName: yup.string("Enter last name").required("Last name is required"),
  username: yup.string("Enter username").required("Username is required"),
  email: yup.string("Enter email").email("Must be a valid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/, "Password must contain at least one special character"),
  repeatPassword: yup.string("Enter repeat password").required("Repeat password required"),
  rememberMe: yup.boolean().oneOf([true], "Accepting Terms and Conditions is required"),
});

const Login = () => {
  const dispatch = useDispatch();

  const [accountBanned, setAccountBanned] = React.useState(false);
  const [hasPassError, setHasPassError] = React.useState(false);
  const [hasConnError, setHasConnError] = React.useState(false);
  const [invalidEmailError, setInvalidEmailError] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // const history = createBrowserHistory({ forceRefresh: true });

  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      phone: "",
      email: "",
      password: "",
      repeatPassword: "",
      rememberMe: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setFieldError }) => {
      setLoading(true);
      setError(false);
      if (values.password !== values.repeatPassword) {
        setFieldError("repeatPassword", "Passwords do not match");
        setLoading(false);
        return;
      }

      registerUser(values)
        .then((res) => {
          console.log("res", res);
          setLoading(false);
          navigate("/register-successfully/" + values.email);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (err?.response?.data?.msg?.includes("Validation isEmail on email failed")) {
            setFieldError("email", "Email is invalid");
          } else if (err?.response?.data?.msg?.includes("Email already exists!")) {
            setFieldError("email", "Email is already used");
          } else if (err?.response?.data?.msg?.includes("This username is already used!")) {
            setFieldError("username", "Username is already used");
          }
          // Alert.alert("Error", "There was a problem with the server. Please try again later. ", [{ text: "Okay" }]);
          else setError(true);
        });
    },
  });

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-nilfisk">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Set up your Nilfisk Food account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img className="mx-auto mb-6 w-auto" src={nilfiskFoodLogo} alt="Nilfisk Food" />
          <form className="space-y-6" id="signup" onSubmit={formik.handleSubmit}>
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  required
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                      ? "focus:ring-red-500 ring-red-500"
                      : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2">
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  required
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.lastName && formik.errors.lastName
                      ? "focus:ring-red-500 ring-red-500"
                      : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            <div>
              <label htmlFor="LastName" className="block text-sm font-medium leading-6 text-gray-900">
                Username
              </label>
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.username && Boolean(formik.errors.username)
                      ? "focus:ring-red-500 ring-red-500"
                      : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.email && Boolean(formik.errors.email)
                      ? "focus:ring-red-500 ring-red-500"
                      : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              {/* <!-- Strong Password --> */}
              <div className="mt-2">
                <div className="flex">
                  <div className="relative flex-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                        formik.touched.password && Boolean(formik.errors.password)
                          ? "focus:ring-red-500 ring-red-500"
                          : "focus:ring-blue-500 ring-gray-300"
                      }  sm:text-sm sm:leading-6`}
                    />
                    <div
                      id="hs-strong-password-popover"
                      className="hidden absolute z-10 w-full bg-white shadow-md rounded-lg p-4 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
                    >
                      <div
                        id="hs-strong-password-in-popover"
                        data-hs-strong-password='{
                          "target": "#password",
                          "hints": "#hs-strong-password-popover",
                          "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1",
                          "mode": "popover"
                        }'
                        className="flex mt-2 -mx-1"
                      ></div>

                      <h4 className="mt-3 text-sm font-semibold text-gray-800 dark:text-white">
                        Your password must contain:
                      </h4>

                      <ul className="space-y-1 text-sm text-gray-500">
                        <li
                          data-hs-strong-password-hints-rule-text="min-length"
                          className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Minimum number of characters is 6.
                        </li>
                        <li
                          data-hs-strong-password-hints-rule-text="lowercase"
                          className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Should contain lowercase.
                        </li>
                        <li
                          data-hs-strong-password-hints-rule-text="uppercase"
                          className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Should contain uppercase.
                        </li>
                        <li
                          data-hs-strong-password-hints-rule-text="numbers"
                          className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Should contain numbers.
                        </li>
                        <li
                          data-hs-strong-password-hints-rule-text="special-characters"
                          className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                        >
                          <span className="hidden" data-check>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span data-uncheck>
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                          Should contain special characters.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Strong Password --> */}
            </div>

            <div>
              <label htmlFor="repeatPassword" className="block text-sm font-medium leading-6 text-gray-900">
                Repeat Password
              </label>
              <div className="mt-2">
                <input
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)
                      ? "focus:ring-red-500 ring-red-500"
                      : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="rememberMe"
                  name="rememberMe"
                  type="checkbox"
                  value={formik.values.rememberMe}
                  onChange={formik.handleChange}
                  className={`h-4 w-4 rounded  text-blue-500 ${
                    formik.touched.rememberMe && formik.errors.rememberMe
                      ? "border-red-500 focus:ring-red-500"
                      : "border-gray-300 focus:ring-blue-500"
                  }`}
                />
                <label htmlFor="rememberMe" className="ml-3 block text-sm leading-6 text-gray-900">
                  I accept the{" "}
                  <a href="/register" className="font-semibold leading-6 text-sky-500 hover:text-sky-400">
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Sign Up
              </button>
              {loading ? (
                <div className="flex justify-center">
                  <div className="mt-4 animate-spin rounded-full w-8 h-8 border-t-4 border-r-4 border-b-4 border-l-4 border-blue-300 border-t-transparent"></div>
                </div>
              ) : null}
            </div>
            {formik.touched.lastName && formik.errors.lastName ? (
              <ErrorAlert text={formik.touched.lastName && formik.errors.lastName} />
            ) : null}
            {formik.submitCount > 0 && formik.errors.firstName ? <ErrorAlert text={formik.errors.firstName} /> : null}
            {formik.submitCount > 0 && formik.errors.lastName ? <ErrorAlert text={formik.errors.lastName} /> : null}
            {formik.submitCount > 0 && formik.errors.username ? <ErrorAlert text={formik.errors.username} /> : null}
            {formik.submitCount > 0 && formik.errors.email ? <ErrorAlert text={formik.errors.email} /> : null}
            {formik.submitCount > 0 && formik.errors.password ? <ErrorAlert text={formik.errors.password} /> : null}
            {formik.submitCount > 0 && formik.errors.repeatPassword ? (
              <ErrorAlert text={formik.errors.repeatPassword} />
            ) : null}
            {formik.submitCount > 0 && formik.errors.rememberMe ? <ErrorAlert text={formik.errors.rememberMe} /> : null}
            {error ? <ErrorAlert text="There was an error with the server. Please try again later." /> : null}
            {/* {error ? <ErrorAlert text="There was a problem. Please check the fields and try again." /> : null} */}
            {hasConnError ? <ErrorAlert text="There is a connection error." /> : null}
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
