import React, { useState } from "react";
import { useParams } from "react-router-dom";
import UsersTable from "../Components/TableUsers/UsersTable";
import { useUsersByFactoryIdQuery } from "hooks/user";

export default function ViewFactoryUsers(props) {
  let params = useParams();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchData, setSearchData] = useState({
    searchText: "",
    page,
    pageSize,
  });

  const { data, isLoading, isFetching, isPlaceholderData } = useUsersByFactoryIdQuery(params.factoryId, searchData);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setSearchData((prevSearchData) => ({ ...prevSearchData, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setSearchData((prevSearchData) => ({ ...prevSearchData, pageSize: newPageSize }));
  };

  return (
    <UsersTable
      data={data?.data?.rows}
      isLoading={isLoading}
      isFetching={isFetching}
      isPlaceholderData={isPlaceholderData}
      page={page}
      setPage={handlePageChange}
      pageSize={pageSize}
      setPageSize={handlePageSizeChange}
      totalRows={data?.data?.count || 0}
    />
  );
}
