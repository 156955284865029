import { createReducer } from '@reduxjs/toolkit';
import { changeDevice } from '../actions/deviceActions';

const state = '';

const deviceReducer = createReducer(state, (builder) =>
{
    builder
        .addCase(changeDevice, (state, action) =>
        {
            state = action.payload.device;
        })
})

export default deviceReducer;

