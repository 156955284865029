import { Button, CircularProgress, Grid, Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import { GetServer } from "../../../../Models/ServerCommuncation/Server";
import { Get_Keys_No_Search } from "../../../../Models/ServerCommuncation/Settings";
import { filterData } from "../../../../Models/ServerCommuncation/Socket";
import { DatedHistoricIotData, DatedIotData } from "../../../../Models/ServerCommuncation/serverInterFace";
import { useWindowDimensions } from "../../../../Models/dashBoardUtilities";
import { MapNameToUnit, ParseTelemtryNames } from "../../../../Views/DashBoard/DashBoardParsing";

export function TelemtricBottomView({ defaultKey }: { defaultKey: string }): JSX.Element | null {
  const Server = GetServer();
  const TelemtryData = Server.getDataForLast10Min();
  const [key, SetKey] = React.useState<[string, string] | null>(null);

  const [Keys, SetKeys] = React.useState<[string, string][]>([]);
  useEffect(() => {
    if (TelemtryData.length !== 0 && (Keys.length === 0 || key == null)) {
      let keys: [string, string][] = Get_Keys_No_Search(TelemtryData[0].data)
        .filter((key) => {
          return ParseTelemtryNames(key) !== undefined;
        })
        .map((key) => {
          return [key, ParseTelemtryNames(key)!];
        });
      SetKeys(keys);
      let idx = keys.findIndex((key) => {
        return key[0] == defaultKey;
      });
      SetKey(keys[idx]);
    }
  });

  const { height, width } = useWindowDimensions();
  if (key === null) {

    return <Grid item xs container justifyContent="center" direction={"column"} className="shadow" bgcolor={"white"} borderRadius={"0.5vw"} height={height / 3.7}>
      <CircularProgress size={"5vmax"}></CircularProgress>
    </Grid>
  }
  return (
    <>
      <Grid item xs container justifyContent="center" direction={"column"} className="shadow" bgcolor={"white"} borderRadius={"0.5vw"}>
        <Grid item xs container justifyContent={"center"} alignContent={"center"}>
          <SelecItem Key={key!} keys={Keys} setKey={SetKey}></SelecItem>
        </Grid>

        <Grid item container bgcolor={"white"} justifyContent={"center"} borderRadius={"0.5vw"}>
          <Grid container item justifyContent={"center"}>
            <TelemetricViewInner telemetryKey={key![0]} telemetryData10Min={TelemtryData} showUnit={true}></TelemetricViewInner>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
function SelecItem({ Key, keys, setKey }: { Key: [string, string]; keys: [string, string][]; setKey: React.Dispatch<React.SetStateAction<[string, string] | null>> }): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose(key: string, displayKey: string) {
    setKey([key, displayKey]);

    setAnchorEl(null);
  }
  return (
    <div>
      <Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        {Key[1]}
      </Button>
      <Popper open={open} anchorEl={anchorEl} role={undefined} placement="bottom-start" transition disablePortal sx={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" sx={{ bgcolor: "white", position: "absolute", overflow: "auto", maxHeight: "30vh" }} className="scroll shadow">
                {keys.map(([key, displaykey]) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClose(key, displaykey);
                      }}
                    >
                      {displaykey}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
export function TelemtricView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedHistoricIotData[] }) {
  const Server = GetServer();
  let TelemtryDataWithLast10Min = [...Server.getDataForLast10Min()];
  return (
    <Grid container direction="column">
      <Grid item container className="shadow" bgcolor={"white"} borderRadius={"0.5vw"} justifyContent={"center"}>
        <Grid container item justifyContent={"center"}>
          <TelemetricViewInner telemetryKey={telemetryKey} telemetryData10Min={TelemtryDataWithLast10Min} showUnit={false}></TelemetricViewInner>
        </Grid>
      </Grid>
    </Grid>
  );
}
type Values = {
  val: number;
  date: number;
  display_key: string;
}[];
function GetValues(telemetryData: DatedHistoricIotData[], telemetryKey: string): Values {
  return telemetryData.map((data) => {
    return { val: data.data[telemetryKey], date: data.date, display_key: telemetryKey };
  });
}
function TelemetricViewInner({ telemetryKey, telemetryData10Min,showUnit }: { telemetryKey: string; telemetryData10Min: DatedHistoricIotData[], showUnit :boolean }) {

  const [telemetryData, setTelemetryData] = React.useState<Values>(GetValues(telemetryData10Min, telemetryKey));
  const Server = GetServer();

  const setData = (data: DatedIotData[]) => {
    let change_data = data.map((data) => {

      return { val: data.data[telemetryKey], date: data.date, display_key: telemetryKey };
    });

    setTelemetryData([...change_data]);
  };
  useEffect(() => {
    setTelemetryData(GetValues(telemetryData10Min, telemetryKey));
  }, [telemetryKey])
  useEffect(() => {
    Server.on_telemtry_last_10_min(setData);
    const interval = window.setInterval(() => {
      setTelemetryData((prev) => {
        let new_data = prev[prev.length - 1];
        new_data.date = Date.now()

        return filterData([...prev, new_data]);
      });
    }, 1000);
    return () => {
      Server.off_telemtry_last_10_min(setData);
      clearInterval(interval);
    };
  });

  let { height, width } = useWindowDimensions();

  if (telemetryData.length === 0) {
    return <></>;
  }
  let ticks = get_tick(telemetryData[telemetryData.length - 1].date - 1000 * 60 * 10, telemetryData[telemetryData.length - 1].date, 10);
  let unit = MapNameToUnit(telemetryKey);
  return (
    <ResponsiveContainer width={"100%"} height={height / 3.7}>
      <LineChart
        data={[...telemetryData]}
        margin={{
          top: 30,
          right: width / 60,
          bottom: 10,
          left: 0,
        }}
      >
        <CartesianGrid vertical={false} color="#000" strokeDasharray={"3 3"}></CartesianGrid>

        <XAxis
          label={{ value: "time", offset: -3, style: { textAnchor: "middle" }, position: "insideBottom" }}
          tickLine={false}
          axisLine={false}
          dataKey="date"
          type="number"
          ticks={ticks}
          interval={0}
          tick={{ fontSize: "0.8em" }}
          domain={[ticks[0], ticks[ticks.length - 1] + 10]}
          tickFormatter={(time, idx) => {
            if (idx !== ticks.length - 1) {
              return (-(ticks.length - idx - 1)).toString();
            }
            return moment(time).format("HH:mm:ss");
          }}
        />
        <Tooltip content={<ShowDataAndValue />}></Tooltip>

        <YAxis label={ showUnit ? { value: unit ? unit : "", position: "insideLeft" } : {}} allowDataOverflow={true} axisLine={false} tickLine={false} />

        <Line isAnimationActive={false} type="monotone" dataKey="val" strokeWidth={3} stroke="#8884d8" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}



function get_tick(start: number, end: number, times: number): number[] {
  let step = (end - start) / (times - 1);

  let base = start;
  let ticks = [];
  for (let i = 0; i < times; i++) {
    ticks.push(base);
    base += step;
  }
  return ticks;
}

function ShowDataAndValue({ active, payload, label }: TooltipProps<number, NameType>): JSX.Element | null {
  if (payload && payload?.length !== 0 && active) {
    let date = moment(payload![0].payload.date).format("HH:mm:ss");
    let data = payload[0].payload.val;
    let display_key = payload[0].payload.display_key;

    return (
      <Grid container direction={"column"} rowGap={3} fontSize={"1vw"} borderRadius={"2vw"} minHeight={"15vh"} minWidth={"20vw"}>
        <Grid item xs={7} bgcolor={"black"} fontSize={"1vw"} borderRadius={"2vw"} sx={{ opacity: "0.6" }} justifyContent={"start"} alignItems={"start"}>
          <Grid item>
            <Typography>{`${display_key}: ${data}`}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={5} bgcolor={"black"} fontSize={"1vw"} borderRadius={"2vw"} sx={{ opacity: "0.6" }} justifyContent={"start"} alignContent={"end"}>
          <Typography>{`time: ${date}`}</Typography>
        </Grid>
      </Grid>
    );
  }
  return null;
}
