
import { createAction } from '@reduxjs/toolkit';

export const setErrorLog = createAction('errorLog/setErrorLog');
export const filterErrorLog = createAction('errorLog/filterErrorLog');
export const resetErrorLog = createAction('errorLog/resetErrorLog');

export const setAllFilter = createAction('errorLog/setAllFilter');
export const countErrorsInFilter = createAction('errorLog/countErrorsInFilter');
export const resetCount = createAction('errorLog/resetCount');
export const selectError = createAction('errorLog/selectError');
export const selectDeselectAll = createAction('errorLog/selectDeselectAll');
export const resetToInitialErrorFilter = createAction('errorLog/resetToInitialErrorFilter');
export const selectAllError = createAction('errorLog/selectAllError');
export const deselectAllError = createAction('errorLog/deselectAllError');
export const selectColor = createAction('errorLog/selectColor');
export const setStartDate = createAction('errorLog/setStartDate');
export const setEndDate = createAction('errorLog/setEndDate');
export const setIsStartDatePicked = createAction('errorLog/setIsStartDatePicked');
export const setMarkedDates = createAction('errorLog/setMarkedDates');
export const resetFilters = createAction('errorLog/resetFilters');
