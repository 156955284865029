
export function HasNoDetailedView(key: string): boolean {
  const HasNoDetailedViewSet: Set<string> = new Set(["HourOffsetFromUTC"]);
  return HasNoDetailedViewSet.has(key);
}

export function ParseTelemtryNames(key: string): null | string {
  const NameMap: Record<string, string> = {
    PressureOUT: "Pressure out Bar",
    PressureIN: "Pressure In Bar",
    Temperature_Water: "Temperature water °C",
    Temperature_igbt: "Temperature Module °C",
    Temperature_ctrl_board: "Temperature ctrl board °C",
    FlowSw: "Flow Switch",
    VibrationX: "Vibration in X",
    VibrationY: "Vibration in Y",
    VibrationZ: "Vibration in Z",
    Inverter_loadPowP: "Power",
    InverterFreqReadout: "Inverter Frequency",
    Temperature_Room: "Temperature room °C",
  };
  return NameMap[key];
}

export function ConvertTelemtryData(key: string, val: number): number {
  const NameMap: Record<string, (val: number) => number> = {
    PressureIN: (val) => val / 10,

    VibrationX: (val) => val / 1000,
    VibrationY: (val) => val / 1000,
    VibrationZ: (val) => val / 1000,
    //  InverterFreqReadout: "Inverter Frequency", dunno
  };
  let parserFunc = NameMap[key];

  if (!parserFunc) {
    return val;
  }
  return NameMap[key](val);
}

export function MapNameToUnit(key: string): null | string {
  const NameMap: Record<string, string> = {
    PressureOUT: "Bar",
    PressureIN: "Bar",
    Temperature_Water: "°C",
    Temperature_igbt: "°C",
    Temperature_ctrl_board: "°C",
    VibrationX: "hz",
    VibrationY: "hz",
    VibrationZ: "hz",
    Inverter_loadPowP: "W",
    InverterFreqReadout: "Hz",
    Temperature_Room: "°C",
  };
  return NameMap[key];
}

export function FactorValue(key: string, val: number): number {
  const NameMap: Record<string, (val: number) => number> = {

    PressureIN: (val) => val > 200 ? 0 : val / 100,
    Temperature_igbt : (val) => val > 200 ? 0 : val,
    Temperature_ctrl_board: (val) => val > 200 ? 0 : val ,
    Temperature_Water: (val) => val > 200 ? 0 : val,
    VibrationX: (val) => val / 1000,
    VibrationY: (val) => val / 1000,
    VibrationZ: (val) => val / 1000,
  };
  let FactorFunc = NameMap[key];
  if (!FactorFunc) {
    return val;
  }
  return FactorFunc(val);
}


export function FactorTelemetryValue(key: string, val: number): number {
  const NameMap: Record<string, (val: number) => number> = {

    PressureIN: (val) => val > 200 ? 0 : val,
    Temperature_igbt : (val) => val > 200 ? 0 : val,
    Temperature_ctrl_board: (val) => val > 200 ? 0 : val ,
    Temperature_Water: (val) => val > 200 ? 0 : val,
    VibrationX: (val) => val / 1000,
    VibrationY: (val) => val / 1000,
    VibrationZ: (val) => val / 1000,
  };
  let FactorFunc = NameMap[key];
  if (!FactorFunc) {
    return val;
  }
  return FactorFunc(val);
}



export function IsValidSetting(key: string): boolean {
  const HasNoDetailedViewSet: Set<string> = new Set(["HourOffsetFromUTC"]);
  return HasNoDetailedViewSet.has(key);
}

export function EePromDisplayName(key: string): string | null {
  const NameMap: Record<string, string> = {
    AppSelectedRunPressure: "Selected Button (0..2)",
    AppStandardPressure: "pressure for the middle button",
    AppHighPressure: "pressure for the right button",
    AppLowPressure: "pressure for the left button",
    AppUserPressure: "Current pressure: ",
    AppDryRunLevel: "Dry run level: ",
    AppAccellerationRamp: "Acceleration Ramp: ",
    AppQuickstartLevel: "Quick start",
    AppQuickstartLevelVal: "Quick start value ",
  };

  return NameMap[key];
}

export function GetDisplayNameForEepromKey(key: string): string | null {
  const NameMap: Record<string, string> = {
    AppAccellerationRamp: "Acceleration Ramp",
    AppQuickstartLevel: "Quick Start",
    AppQuickstartLevelVal: "Quick Start Value",
    AppSetAllowNoInlet: "allow no inlet",
    AppAutoOffDelay: "Auto Off",
    AppAutoOffDelayVal: "Auto Off value ",

    AppLowPressure: "Pressure 1",
    AppStandardPressure: "Pressure 2",
    AppDryRunLevel: "Dry run level",
    AppHighPressure: "Pressure 3",
    AppStartUpMethod: "Startup Method",
    AppStartUpMethodPressVal: "Start Pressure",
  };

  return NameMap[key];
}

export type IntervalAndLimits = { interval: number; low: number; high: number };
export function ParseKeyToInterval(key: string): IntervalAndLimits | null {
  const NameMap: Record<string, IntervalAndLimits> = {
    AppAccellerationRamp: {
      low: 30,
      high: 100,
      interval: 10,
    },

    AppSelectedRunPressure: {
      low: 5,
      high: 60,

      interval: 1,
    },
    AppHighPressure: {
      low: 1,
      high: 25,
      interval: 1,
    },
    AppStandardPressure: {
      low: 1,
      high: 25,
      interval: 1,
    },

    AppLowPressure: {
      low: 1,
      high: 25,
      interval: 1,
    },
    AppDryRunLevel: {
      low: 5,
      high: 40,
      interval: 1,
    },

    AppPostRunTime: {
      low: 50,

      high: 400,
      interval: 10,
    },
    AppAutoOffDelayVal: {
      low: 5,
      high: 60,
      interval: 1,
    },
    AppQuickstartLevelVal: {
      low: 5,
      high: 15,
      interval: 1,
    },
    AppStartUpMethodPressVal: {
      low: 5,
      high: 15,
      interval: 1,
    },
  };

  return NameMap[key];
}

export function ConvertEepromData(key: string, val: number): number {
  const NameMap: Record<string, (val: number) => number> = {
    AppAccellerationRamp: (val) => val / 10,
    AppSelectedRunPressure: (val) => val / 10,
    AppDryRunLevel: (val) => val / 10,
    AppQuickstartLevelVal: (val) => val,
    AppAutoOffDelayVal: (val) => val,
  };
  let parserFunc = NameMap[key];

  if (!parserFunc) {
    return val;
  }
  return NameMap[key](val);
}

export type componentNames = "ValueAdjuster" | "FlagButton" | "Default";
export function GetComponentName(key: string): componentNames {
  const NameMap: Record<string, componentNames> = {
    AppAccellerationRamp: "ValueAdjuster",
    AppLowPressure: "ValueAdjuster",
    AppStandardPressure: "ValueAdjuster",
    AppDryRunLevel: "ValueAdjuster",
    AppHighPressure: "ValueAdjuster",

    AppQuickstartLevelVal: "ValueAdjuster",
    AppStartUpMethodPressVal: "ValueAdjuster",

    AppAutoOffDelayVal: "ValueAdjuster",
    AppQuickstartLevel: "FlagButton",

    AppSetAllowNoInlet: "FlagButton",
    AppAutoOffDelay: "FlagButton",
    AppStartUpMethod: "FlagButton",

    //    AppStartUpMethod: "Startup Method",
  };
  let componetName = NameMap[key];
  if (!componetName) {
    return "Default";
  }
  return componetName;
}

export function GetUnit(key: string): string {
  const NameMap: Record<string, string> = {
    AppAccellerationRamp: "sec",
    AppLowPressure: "bar",
    AppStandardPressure: "bar",
    AppDryRunLevel: "sec",
    AppHighPressure: "bar",

    AppQuickstartLevelVal: "bar/sec",
    AppStartUpMethodPressVal: "bar",

    AppAutoOffDelayVal: "sec",
    AppQuickstartLevel: "FlagButton",

    AppSetAllowNoInlet: "FlagButton",
    AppAutoOffDelay: "FlagButton",
    AppStartUpMethod: "FlagButton",

    //    AppStartUpMethod: "Startup Method",
  };
  let componetName = NameMap[key];
  if (!componetName) {
    return "Default";
  }
  return componetName;
}
