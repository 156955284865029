import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import { updateFactoryById, deleteFactory, getFactoriesByCompanyId } from 'shared/services/factory.service';
import MaterialTable, { MTableAction } from 'material-table';
import
{
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
    Refresh,
    DeviceHub,
    Visibility
} from '@mui/icons-material';
import { Link as RouterLink, Route, MemoryRouter, Navigate , useParams } from 'react-router-dom';


import format from 'date-format';

import { Provider, Translate, Translator } from 'react-translated';
import
{
    CardHeader, Typography, Divider, Button, Grid, CircularProgress,
    LinearProgress, Breadcrumbs, Paper, Card, Link, TextField, Snackbar, Grow
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/styles';
// import MasonryItem from '@mui/lab/MasonryItem';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

import { useSelector } from 'react-redux';

import { createOwnRoom, createRoom, deleteOwnRoom, deleteRoom } from 'shared/services/room.service';
import
{
    getMachineByFactoryId, getOwnMachinesByFactoryId, addMachineToFactory, addMachineToOwnFactory,
    addMachineToRoom, addMachineToOwnRoom, updateMachineRoomAndName, updateOwnMachineRoomAndName,
} from 'shared/services/machine.service';
import { getOwnRoomsByFactoryId, getRoomsByFactoryId } from 'shared/services/room.service';

import { getOwnFactoryNameById, getFactoryNameById } from 'shared/services/factory.service';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Autocomplete from '@mui/material/Autocomplete';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useFormik } from 'formik';
import * as yup from 'yup';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const validationSchema = yup.object({
    serialNumber: yup.string('Enter serial number').required('Serial number is required'),
    name: yup.string('Enter machine name').required('Machine name is required'),
});

const validationSchemaEdit = yup.object({
    name: yup.string('Enter machine name').required('Machine name is required'),
});

const Alert = React.forwardRef(function Alert(props, ref)
{
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MasonryItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    // color: theme.palette.text.secondary,
    // border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));


export default function ViewMachinesManagement(props)
{
    const myAccount = useSelector((state) => state.user.user);
    const classes = useStyles();


    const [openAddMachinePinDialog, setOpenAddMachinePinDialog] = React.useState(false); //TODO finish implementing the add machine with a pin code

    const [factoryName, setFactoryName] = useState(null);
    const [rooms, setRooms] = useState([]);
    const [machines, setMachines] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [roomsLoading, setRoomsLoading] = useState(true);

    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');

    const [valueEdit, setValueEdit] = React.useState(null);
    const [inputValueEdit, setInputValueEdit] = React.useState('');

    const [selected, setSelected] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [redirectToViewMachine, setRedirectToViewMachine] = useState(false);
    const [redirectToViewMachineLink, setRedirectToViewMachineLink] = useState('');
    const [redirectToViewMachineData, setRedirectToViewMachineData] = useState({});

    let params = useParams();

    useEffect(() =>
    {

        if (props.location.state?.factoryName != null)
        {
            setFactoryName(props.location.state?.factoryName)
        }
        if (hasPermission("Machine", "create"))
        {
            getMachineByFactoryId(params.factoryId).then(res =>
            {
                var machinesCopy = res.data.machines;
                let newArray = machinesCopy.map(machine =>
                {
                    if (machine.Room != null)
                        return machine
                    else
                    {
                        return ({
                            ...machine,
                            Room: { name: 'N/A' }
                        })
                    }
                })

                setMachines(newArray);
                setIsLoading(false);
            }).catch(err =>
            {
                setIsLoading(false);
            })
            getRoomsByFactoryId(params.factoryId).then(res =>
            {
                console.log(res)
                setRooms(res.data.rooms);
                setRoomsLoading(false);
            }).catch(err =>
            {
                setRoomsLoading(false);
            })
            getFactoryNameById(params.factoryId).then(res =>
            {
                console.log("factory", res)
                setFactoryName(res.data.factory[0].name)
            })
        }
        else
            if (hasPermission("RoomOwn", "create"))
            {
                getOwnMachinesByFactoryId(params.factoryId).then(res =>
                {
                    var machinesCopy = res.data.machines;
                    let newArray = machinesCopy.map(machine =>
                    {
                        if (machine.Room != null)
                            return machine
                        else
                        {
                            return ({
                                ...machine,
                                Room: { name: 'N/A' }
                            })
                        }
                    })
                    setMachines(newArray);
                    setIsLoading(false);
                }).catch(err =>
                {
                    setIsLoading(false);
                })
                getOwnRoomsByFactoryId(params.factoryId).then(res =>
                {
                    setRooms(res.data.rooms);
                    setRoomsLoading(false);
                }).catch(err =>
                {
                    setRoomsLoading(false);
                })
                getOwnFactoryNameById(params.factoryId).then(res =>
                {
                    console.log("factory", res)
                    setFactoryName(res.data.factory[0].name)
                })
            }
    }, [])



    const formik = useFormik({
        initialValues: {
            name: "",
            serialNumber: "",
            RoomId: null,
            FactoryId: params.factoryId
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setFieldError }) =>
        {

            setIsLoading(true);

            if (value == null)
            {

                setFieldError('RoomId', 'Please select a room');
                setIsLoading(false);
            }
            else
            {
                let copyValues = {
                    ...values,
                    RoomId: value.id
                }
                if (hasPermission("Machine", "create"))
                    addMachineToRoom(copyValues).then(res =>
                    {
                        console.log(res);
                        // setIsLoading(false);
                        getMachineByFactoryId(params.factoryId).then(res =>
                        {
                            var machinesCopy = res.data.machines;
                            let newArray = machinesCopy.map(machine =>
                            {
                                if (machine.Room != null)
                                    return machine
                                else
                                {
                                    return ({
                                        ...machine,
                                        Room: { name: 'N/A' }
                                    })
                                }
                            })

                            setSuccessful(true);
                            setOpen(true);

                            setMachines(newArray);
                            setIsLoading(false);
                        }).catch(err =>
                        {
                            setIsLoading(false);
                        })
                    }).catch(err =>
                    {
                        console.log(err.response)
                        if (err?.response?.data?.msg == "This machine is already owned by someone. If you are the owner of the machine please go to the machine and reset it from the settings menu")
                            formik.setFieldError("serialNumber", err.response.data.msg)
                        setSuccessful(false);
                        setOpen(true);
                        setIsLoading(false);
                    })
                else
                    if (hasPermission("MachineOwn", "create"))
                        addMachineToOwnRoom(values).then(res =>
                        {
                            console.log(res);
                            // setIsLoading(false);
                            getOwnMachinesByFactoryId(params.factoryId).then(res =>
                            {
                                var machinesCopy = res.data.machines;
                                let newArray = machinesCopy.map(machine =>
                                {
                                    if (machine.Room != null)
                                        return machine
                                    else
                                    {
                                        return ({
                                            ...machine,
                                            Room: { name: 'N/A' }
                                        })
                                    }
                                })

                                setSuccessful(true);
                                setOpen(true);

                                setMachines(newArray);
                                setIsLoading(false);
                            }).catch(err =>
                            {
                                setIsLoading(false);
                            })
                        }).catch(err =>
                        {
                            setSuccessful(false);
                            setOpen(true);
                            setIsLoading(false);
                        })
                    else
                        setIsLoading(false);
            }
        },
    });


    const formikEdit = useFormik({
        initialValues: {
            id: null,
            name: "",
            RoomId: null,
        },
        validationSchema: validationSchemaEdit,
        onSubmit: (values, { setFieldError }) =>
        {
            //TODO finish this by adding the posibility for the admin to update the machines
            setIsLoading(true);
            console.log(values)
            let copyValues = {
                ...values,
                RoomId: valueEdit.id
            }
            if (hasPermission("Machine", "update"))
            {
                updateMachineRoomAndName(copyValues.id, copyValues).then(res =>
                {
                    console.log("test", res)


                    getMachineByFactoryId(params.factoryId).then(res =>
                    {
                        var machinesCopy = res.data.machines;
                        let newArray = machinesCopy.map(machine =>
                        {
                            if (machine.Room != null)
                                return machine
                            else
                            {
                                return ({
                                    ...machine,
                                    Room: { name: 'N/A' }
                                })
                            }
                        })
                        setSuccessfulUpdated(true);
                        setOpenUpdate(true);
                        setMachines(newArray);
                        setIsLoading(false);
                    }).catch(err =>
                    {
                        setIsLoading(false);
                    })
                }).catch(err =>
                {

                    setSuccessfulUpdated(false);
                    setOpenUpdate(true);
                    setIsLoading(false);
                })
            }
            else
                if (hasPermission("MachineOwn", "update"))
                    updateOwnMachineRoomAndName(copyValues.id, copyValues).then(res =>
                    {
                        console.log("test", res)


                        getOwnMachinesByFactoryId(params.factoryId).then(res =>
                        {
                            var machinesCopy = res.data.machines;
                            let newArray = machinesCopy.map(machine =>
                            {
                                if (machine.Room != null)
                                    return machine
                                else
                                {
                                    return ({
                                        ...machine,
                                        Room: { name: 'N/A' }
                                    })
                                }
                            })
                            setSuccessfulUpdated(true);
                            setOpenUpdate(true);
                            setMachines(newArray);
                            setIsLoading(false);
                        }).catch(err =>
                        {
                            setIsLoading(false);
                        })
                    }).catch(err =>
                    {

                        setSuccessfulUpdated(false);
                        setOpenUpdate(true);
                        setIsLoading(false);
                    })


        },
    });


    /**
    * @param  {string} resource - Resource name
    * @param  {string} permission - Permission type (create/read/update/delete)
    */
    const hasPermission = (resource, permission) =>
    {
        const result = myAccount.Role.PermissionResources.filter(item => item.Resource.name == resource)
        if (!result.length)
            return false;

        if (result[0].Permission[permission] == true)
            return true
        else
            return false

    }

    // Alert for add machine
    const [open, setOpen] = React.useState(false);
    const [successful, setSuccessful] = React.useState(true);

    const handleClose = (event, reason) =>
    {
        if (reason === 'clickaway')
        {
            return;
        }

        setOpen(false);
    };


    // Alert for edit machine
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [successfulUpdated, setSuccessfulUpdated] = React.useState(true);

    const handleCloseUpdate = (event, reason) =>
    {
        if (reason === 'clickaway')
        {
            return;
        }

        setOpenUpdate(false);
    };

    const onMachineClick = (event, rowData) =>
    {
        setRedirectToViewMachine(true);
        setRedirectToViewMachineLink('/machine/' + rowData.id)
        setRedirectToViewMachineData(rowData);
    }

    const onRowClick = (event, rowData) =>
    {

        console.log("rowData", rowData)
        if (rowData.id === selectedRow?.id)
        {
            setSelectedRow(null);
            setSelected(false);
            setValueEdit(null)
            const values = {
                id: null,
                name: "",
                RoomId: null
            }
            formikEdit.setValues(values);
        } else
        {
            const values = {
                id: rowData.id,
                name: rowData.name,
                RoomId: rowData.Room.id
            }
            formikEdit.setValues(values);
            let index = rooms.findIndex(room => room.id == rowData.Room.id)
            setValueEdit(rooms[index])
            setSelectedRow(rowData);
            setSelected(true);
        }
    }
    const handleClickOpenDialog = () =>
    {
        setOpenAddMachinePinDialog(true);
    };

    const handleCloseDialog = () =>
    {
        setOpenAddMachinePinDialog(false);
    };

    if (redirectToViewMachine == true)
        return (<Navigate  push to={{
            pathname: redirectToViewMachineLink,
            state: {
                machineData: redirectToViewMachineData,
                factoryId: params.factoryId
            }
        }} />)
    return (
        <Translator>
            {({ translate }) => (
                <div className={classes.root}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5">Machines</Typography>

                            <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 20 }}>
                                <LinkRouter underline="hover" color="inherit" to="/Home">
                                    Home
                		        </LinkRouter>
                                <LinkRouter underline="hover" color="inherit" to="/factories">
                                    Factories
                		        </LinkRouter>
                                <LinkRouter underline="hover" color="inherit" to={"/factory/view/" + params.factoryId + "/machines"}>
                                    {factoryName ?? "Factory"}{/* Factory */}
                                </LinkRouter>
                                <Typography color="text.primary">Machines</Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Masonry columns={{ xs: 1, sm: 1, md: 2 }} spacing={2}>
                        <Card variant="outlined" style={{ borderRadius: 20 }}>
                            <CardHeader title={"Add Machine"} />
                            <Divider />


                            <form onSubmit={formik.handleSubmit} style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                                <TextField
                                    name="serialNumber"
                                    type="serialNumber"
                                    label="Serial Number"
                                    variant="outlined"
                                    margin="normal"
                                    value={formik.values.serialNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
                                    helperText={formik.touched.serialNumber && formik.errors.serialNumber}
                                    disabled={isLoading}
                                    fullWidth
                                />
                                <TextField
                                    name="name"
                                    type="name"
                                    label="Machine Name"
                                    variant="outlined"
                                    margin="normal"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    disabled={isLoading}
                                    fullWidth
                                />
                                <Autocomplete
                                    name="RoomId"
                                    value={value}
                                    onChange={(event, newValue) =>
                                    {
                                        console.log("newValue", newValue)
                                        setValue(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) =>
                                    {
                                        console.log("newInputValue", newInputValue)
                                        setInputValue(newInputValue);
                                    }}
                                    id="controllable-states-demo"
                                    getOptionLabel={option => option.name}
                                    options={rooms}
                                    // sx={{ width: 300 }}
                                    // fullWidth
                                    loading={roomsLoading}
                                    renderInput={(params) => <TextField {...params}
                                        error={formik.touched.RoomId && Boolean(formik.errors.RoomId)}
                                        helperText={formik.touched.RoomId && formik.errors.RoomId}
                                        margin="normal" label="Room"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {roomsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}

                                    />}
                                />

                                <Button type="submit" style={{ marginBottom: 15, marginTop: 10 }} variant="contained"
                                    disabled={isLoading} // startIcon={<SaveIcon />}
                                >
                                    Add Machine
           					        </Button>
                            </form>
                        </Card>
                        <Card variant="outlined" style={{ borderRadius: 20 }}>
                            <CardHeader title={"Machines"} />
                            <Divider />

                            <MaterialTable
                                icons={tableIcons}
                                columns={[
                                    // { title: 'Status', field: 'status' },
                                    // {
                                    //     title: <Translate text='Duration of last cleaning' />,
                                    //     field: 'durationOfLastCleaning',
                                    // },
                                    // {
                                    //     title: <Translate text='Production Start' />,
                                    //     field: 'productionStart'
                                    // },
                                    { title: 'Room Name', field: 'Room.name', defaultGroupOrder: 0 },
                                    { title: 'Name', field: 'name' },
                                    { title: 'Type', field: 'type' },
                                    { title: 'Serial Number', field: 'serialNumber' },
                                    // { title: <Translate text='Address' />, field: 'address' },
                                    // { title: <Translate text='City' />, field: 'city' },
                                    // { title: <Translate text='Country' />, field: 'country' }
                                ]}
                                data={machines}
                                actions={[
                                    {
                                        icon: tableIcons.Visibility,
                                        tooltip: 'View Machine',
                                        onClick: onMachineClick
                                    }
                                ]}
                                options={{
                                    showTitle: false,
                                    search: false,
                                    toolbar: false,
                                    actionsColumnIndex: -1,
                                    // headerStyle: {padding: 50}
                                    debounceInterval: 500,
                                    pageSize: 10,
                                    pageSizeOptions: [5, 10, 20, 50],
                                    grouping: true,
                                    defaultExpanded: true,
                                    rowStyle: rowData => ({
                                        backgroundColor:
                                            selected &&
                                                rowData.id === selectedRow?.id
                                                ? "#3576D2"
                                                : "#fff",
                                        color: selected &&
                                            rowData.id === selectedRow?.id
                                            ? "#fff"
                                            : "black"
                                    })
                                }}
                                onRowClick={onRowClick}
                                isLoading={isLoading}
                            />
                        </Card>
                        <Card variant="outlined" style={{ borderRadius: 20 }}>
                            <CardHeader title={"Edit Machine"} />
                            <Divider />


                            <form onSubmit={formikEdit.handleSubmit} style={{ paddingLeft: 20, paddingRight: 20 }}
                            >

                                <TextField
                                    name="name"
                                    type="name"
                                    label="Machine Name"
                                    variant="outlined"
                                    margin="normal"
                                    value={formikEdit.values.name}
                                    onChange={formikEdit.handleChange}
                                    error={formikEdit.touched.name && Boolean(formikEdit.errors.name)}
                                    helperText={formikEdit.touched.name && formikEdit.errors.name}
                                    disabled={selectedRow == null || isLoading}
                                    fullWidth
                                />
                                <Autocomplete
                                    name="RoomId"
                                    value={valueEdit}
                                    onChange={(event, newValue) =>
                                    {
                                        console.log("newValue", newValue)
                                        setValueEdit(newValue);
                                    }}
                                    inputValue={inputValueEdit}
                                    onInputChange={(event, newInputValue) =>
                                    {
                                        console.log("newInputValue", newInputValue)
                                        setInputValueEdit(newInputValue);
                                    }}
                                    id="controllable-states-demo"
                                    getOptionLabel={option => option.name}
                                    options={rooms}
                                    // sx={{ width: 300 }}
                                    // fullWidth
                                    disabled={selectedRow == null || isLoading}
                                    loading={roomsLoading}
                                    renderInput={(params) => <TextField {...params}
                                        error={formikEdit.touched.RoomId && Boolean(formikEdit.errors.RoomId)}
                                        helperText={formikEdit.touched.RoomId && formikEdit.errors.RoomId}
                                        margin="normal" label="Room"
                                        disabled={selectedRow == null || isLoading}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {roomsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}

                                    />}
                                />

                                <Button type="submit" style={{ marginBottom: 15, marginTop: 10 }} variant="contained"
                                    disabled={selectedRow == null || isLoading} // startIcon={<SaveIcon />}
                                >
                                    Save Machine
           					        </Button>
                                {selectedRow == null ?
                                    <Typography style={{ marginBottom: 10 }}>Select a machine from the table to start editing.</Typography> : null}
                            </form>
                        </Card>
                    </Masonry>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        TransitionComponent={(props) => <Grow {...props} />}
                    >
                        <Alert onClose={handleClose} severity={successful ? "success" : "error"} sx={{ width: '100%' }}>
                            {successful ? "The machine was added successfully." :
                                "There was an error while adding the machine."
                            }
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openUpdate} autoHideDuration={6000} onClose={handleCloseUpdate}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        TransitionComponent={(props) => <Grow {...props} />}
                    >
                        <Alert onClose={handleCloseUpdate} severity={successfulUpdated ? "success" : "error"} sx={{ width: '100%' }}>
                            {successfulUpdated ? "The machine was successfully updated." :
                                "There was an error while updating the machine."
                            }
                        </Alert>
                    </Snackbar>

                    <Dialog open={openAddMachinePinDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Machine Pin Code</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                To add this machine to your factory, you must type in the pin code shown on the machine's display.
                            </DialogContentText>
                            
                            <TextField
                            // alignItems='center'
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Pin"
                                type="text"
                                // fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Cancel</Button>
                            <Button onClick={handleCloseDialog}>Add</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
            }
        </Translator >
    );
}


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    table: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    textField: {
        width: 300,
        margin: 10
    },
    datePicker: {
        width: 100,
        marginLeft: 10
    },
    button: {
        margin: theme.spacing(1)
    },
}));