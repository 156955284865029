import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UserRaw({ user, userIdx }) {
  const navigate = useNavigate();
  return (
    <tr
      className={classNames(
        userIdx === 0 ? "border-gray-300" : "border-gray-200",
        "border-t cursor-pointer hover:bg-gray-50"
      )}
      onClick={() => {
        console.log("clicked");
        navigate("/user/" + user.uuid);
      }}
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
        {user.firstName} {user.lastName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.username}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.email}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.phone || "-"}</td>
    </tr>
  );
}
