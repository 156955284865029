import React, { useEffect } from "react";
// import { userService } from "shared/services/authentication.service";
import { useLocation, useNavigate } from "react-router-dom";
// @ts-ignore
import ErrorAlert from "components/ErrorAlert";
import { SendCommand } from "./NavigatorApp/src/Models/ServerCommuncation/Server";

import { LiaQuestionCircleSolid } from "react-icons/lia";



export default function SNConnectedPage() {
  const state: { errorMessage?: string, serviceCode?: string} = useLocation().state;
  const navigate = useNavigate();

  const [Loading, setLoading] = React.useState(false);
  const [ErrorMessage, setErrorMessage] = React.useState<string | null>(null);
  

  useEffect(() => {
  
    if (state?.errorMessage) {
      setErrorMessage(state.errorMessage);
    }
  }, [state])
  function IsValidControllerVersion(controller_version: string) {

    let [version, rest] = controller_version.slice(1).split("b");


    let [major, minor, patch] = version.split(".");
    if (parseInt(major) >= 2) {
      return true;
    }
    return false;
  }
  async function CheckMachine(machineId: string,serviceCode : string, connectHandler: (machineId: string,serviceCode : string) => void) {
    console.log(serviceCode);
    try {
      SendCommand("GetSwVersionsJSON", machineId, serviceCode, "", async (data, err) => {
        if (!err) {
          if (!IsValidControllerVersion(data.Controller)) {
            setErrorMessage("Machine not supported. Please upgrade machine to version 2.0.0 or later.");
            return;
          }
          connectHandler(machineId,serviceCode);
          return;
        }
        setErrorMessage(err.err);
        setLoading(false);

      });

    } catch (err: any) {
      setErrorMessage("server is offline");
      setLoading(false);
    }
  }

  function getMachineCode(formData: FormData): string | null{
    const codeNumber1 = formData.get("code-number-1");
    const codeNumber2 = formData.get("code-number-2");
    const codeNumber3 = formData.get("code-number-3");
    const codeNumber4 = formData.get("code-number-4");
    if (codeNumber1 &&  codeNumber2 && codeNumber3 && codeNumber4) {
      return codeNumber1.toString() + codeNumber2.toString() + codeNumber3.toString() + codeNumber4.toString();

    }
    return null;

  }

  function getServiceCode(formData: FormData): string | null{
    const codeNumber1 = formData.get("service-code-number-1");
    const codeNumber2 = formData.get("service-code-number-2");
    const codeNumber3 = formData.get("service-code-number-3");
    const codeNumber4 = formData.get("service-code-number-4");
    if (codeNumber1 &&  codeNumber2 && codeNumber3 && codeNumber4) {
      return codeNumber1.toString() + codeNumber2.toString() + codeNumber3.toString() + codeNumber4.toString();

    }
    return null;

  }

  const onSubmit = (e: any) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const serialNumber = formData.get("serial-number");
    const serviceCode = getServiceCode(formData);

    const authCode = getMachineCode(formData);
    if (authCode === null) {
      setErrorMessage("Please enter a 4 digit authentication code.");
      return;
    }
    if (serviceCode === null) {
      setErrorMessage("Please enter a 4 digit service code.");
      return;
    }
    setLoading(true);
    setErrorMessage(null);
    CheckMachine(serialNumber!.toString(),serviceCode!.toString(), (machineId,serviceCode) => {
      SendCommand("SetNavigatorMode", machineId,serviceCode,authCode, (ok: any, err: any) => {
        setLoading(false);
        if (!err) {
          navigate("/navigator/control-panel", { state: { serialNumber: machineId, serviceCode : serviceCode }  });

          return;
        }
        if (err === "Wrong service Code") {
          setErrorMessage("Service code is wrong");
          return;
        }

        setErrorMessage("Authentication code is wrong");

      })

    });



    /*TODO for Daniel
    1. use the serial number and the code to connect to the display remotely, use loading state to activate the loading spinner
    2. if the connection is successful, navigate to "/navigator/control-penel"
    3. if the connection is unsuccessful, show an error message

      to navigate to the navigator page, use the following code:
      remember to add your navigator page to the routes in ./App.js

      I don't know what errors you can get from your connection, so I can't write the error message for you here
      we need to talk about this, how to show the errors, before further implementation
    */

    // navigate("/navigator");
  };



  return (
    <div className="flex min-h-full flex-1 flex-col justify-center sm:py-12 sm:px-6 lg:px-8">
      <div className="sm:mt-10 sm:mx-auto sm:w-full sm:max-w-[450px]">
        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl " onSubmit={onSubmit}>
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Navigator Tool</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              Enter the machine's serial number and the authentication code shown on the display, to connect and
              navigate remotely.
            </p>
          </div>
          <div className="px-4 py-6 sm:p-8 border-t border-gray-100">
            {/* <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"> */}
            <div className="">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Serial Number
              </label>
              <div className="mt-2">
                <input
                  id="serial-number"
                  name="serial-number"
                  type="number"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mt-6 ">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                Authentication code
                {/* <!-- Popover --> */}
                <div className="hs-tooltip inline-block [--trigger:hover] align-middle">
                  <LiaQuestionCircleSolid className={" text-blue-500 ml-2"} size={19} />

                  <span
                    //@ts-ignore
                    class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-gray-900 dark:border-gray-700 dark:text-gray-400"
                    role="tooltip"
                  >
                    Explanation
                  </span>
                </div>
                {/* <!-- End Popover --> */}
              </label>
              <div className="mt-2 ">
                <div className="flex space-x-3 justify-center" data-hs-pin-input='{"availableCharsRE": "^[0-9]+$"}'>
                  <input
                    id="code-number-1"
                    name="code-number-1"
                    type="number"
                    className="block w-[38px] text-center border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                    placeholder="⚬"
                    data-hs-pin-input-item
                  />
                  <input
                    name="code-number-2"
                    id="code-number-2"
                    type="number"
                    className="block w-[38px] text-center border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                  />
                  <input
                    name="code-number-3"
                    id="code-number-3"
                    type="number"
                    className="block w-[38px] text-center border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                  />
                  <input
                    name="code-number-4"
                    id="code-number-4"
                    type="number"
                    className="block w-[38px] text-center border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 ">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                Service code
                {/* <!-- Popover --> */}
                <div className="hs-tooltip inline-block [--trigger:hover] align-middle">
                  <LiaQuestionCircleSolid className={" text-blue-500 ml-2"} size={19} />

                  <span
                                      //@ts-ignore

                    class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-gray-900 dark:border-gray-700 dark:text-gray-400"
                    role="tooltip"
                  >
                    Explanation
                  </span>
                </div>
                {/* <!-- End Popover --> */}
              </label>
              <div className="mt-2 ">
                <div className="flex space-x-3 justify-center" data-hs-pin-input='{"availableCharsRE": "^[0-9]+$"}'>
                  <input
                    id="service-code-number-1"
                    name="service-code-number-1"
                    type="number"
                    className="block w-[38px] text-center border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                    placeholder="⚬"
                    data-hs-pin-input-item
                  />
                  <input
                    name="service-code-number-2"
                    id="service-code-number-2"
                    type="number"
                    className="block w-[38px] text-center border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                  />
                  <input
                    name="service-code-number-3"
                    id="service-code-number-3"
                    type="number"
                    className="block w-[38px] text-center border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                  />
                  <input
                    name="service-code-number-4"
                    id="service-code-number-4"
                    type="number"
                    className="block w-[38px] text-center border-gray-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                  />
                </div>
              </div>
            </div>
            <div className="pt-6">{ErrorMessage ? <ErrorAlert text={ErrorMessage} /> : null}</div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            {Loading ? (
              <div
                className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            <button
              type="submit"
              disabled={Loading}
              className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:pointer-events-none disabled:opacity-50"
            >
              Connect
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

