import React, { useEffect, useState } from 'react';
import { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Provider, Translate, Translator } from 'react-translated'
import {
    CardHeader, Typography, Divider, Button, Grid, CircularProgress,
    LinearProgress, Breadcrumbs, Paper, Card, TextField, Snackbar, Autocomplete, Box
} from '@mui/material';
import MaterialTable from '@material-table/core';
import MuiAlert from '@mui/material/Alert';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import PropTypes from "prop-types"

/**
 * Component for showing details of the user.
 *
 * @component
 * @example
 * const successfulMessage = "The user was created successfully"
 * const errorMessage = "There was an error while creating the user"
 * const isSuccessful = true
 * return (
 *   <AdvanceSnackbar successfulMessage={successfulMessage} errorMessage={errorMessage} isSuccessful={isSuccessful} />
 * )
 */
const AdvanceSnackbar = (props) => {
    const { successfulMessage, errorMessage, isSuccessful, open } = props;

    const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
        props.onClose()
	  };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            style={{ marginBottom: 10 }}
            TransitionComponent={(props) => <Slide {...props} />}
        >
            <Alert onClose={handleClose} severity={isSuccessful ? "success" : "error"} sx={{ width: '100%' }}>

                {isSuccessful ? successfulMessage : errorMessage}
            </Alert>
        </Snackbar>
    );
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

AdvanceSnackbar.propTypes = {
    /**
     * Successful Message
     */
    successfulMessage: PropTypes.string.isRequired,
    /**
     * Error Message
     */
    errorMessage: PropTypes.string.isRequired,
    /**
    * Is Alert Successful?
    */
    isSuccessful: PropTypes.bool.isRequired
}

export default React.memo(AdvanceSnackbar)


// const [successfulMessage, setSuccessfulMessage] = useState(props.successfulMessage);
// const [errorMessage, setErrorMessage] = useState(props.errorMessage);
// const [isSuccessful, setIsSuccessful] = useState(props.isSuccessful);
// const [open, setOpen] = useState(props.open);

// useEffect(() => {
//     const { successfulMessage, errorMessage, isSuccessful, } = props;
//     setSuccessfulMessage(successfulMessage)
//     setErrorMessage(errorMessage)
//     setIsSuccessful(isSuccessful)
//     setOpen(open)
// }, [props.open])