import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CircularProgress, Grid, IconButton, List, ListItem, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { Get_Keys_Display_Name } from "../../Models/ServerCommuncation/Settings";
import { DatedIotData } from "../../Models/ServerCommuncation/serverInterFace";
import { TelemtricView as TelemetricViewer } from "../uiElements/noninteractive/TelemtryView.tsx/telemtricview";
import { HasNoDetailedView, MapNameToUnit, ParseTelemtryNames } from "./DashBoardParsing";
import "./dashboard.css";

type TelemetricViewType = "detailed" | "normal";

type TelemetricViews = ({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }) => JSX.Element;
const TelemetricViewsMap: Record<TelemetricViewType, TelemetricViews> = {
  detailed: DetailedView,
  normal: NormalView,
};

export default function TelemtryDataView() {
  const Server = GetServer();

  const [telemetryData, setTelemetryData] = React.useState<DatedIotData[]>([]);
  const [currentTelemetryData, SetCurrentTelemtryData] = React.useState<DatedIotData | null>(null);

  const [searchText, SetSearchText] = React.useState<string>();
  const [keys, SetKeys] = React.useState<string[]>([]);

  const setData = (data: DatedIotData[]) => {
    setTelemetryData((prevState) => {
      SetCurrentTelemtryData(data[data.length - 1]);

      return data;
    });
  };
  useEffect(() => {
    Server.on_telemtry_last_10_min(setData);

    return () => {
      Server.off_telemtry_last_10_min(setData);
    };
  });
  useEffect(() => {
    if (telemetryData.length != 0) {
      let keys = Get_Keys_Display_Name(telemetryData[0].data, searchText);
      SetKeys(keys);
    }
  }, [telemetryData, searchText]);
  if (currentTelemetryData === null) {
    return (
      <Grid item container direction={"column"} justifyContent={"center"} alignContent={"center"} height={"100%"} >

        <CircularProgress size={"5vmax"} />
      </Grid>
    );
  }
  return (
    <>
      <Grid item xs={1} container justifyContent="center" paddingTop={"1vh"}>
        <TextField
          sx={{ bgcolor: "white" }}
          label="Search"
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            SetSearchText(event.target.value);
          }}
        ></TextField>
      </Grid>

      <Grid item xs container>
        <List sx={{ overflow: "auto", minWidth: "100%", height: "68vh" }} className="scroll">
          <ListItem> <GridNames></GridNames></ListItem>
          {keys.map((key) => {
            return <TelemetricView telemetryKey={key} telemetryData={telemetryData} backgroundColor="white"></TelemetricView>;
          })}
        </List>
      </Grid>
    </>
  );
}
function IsFlag(name: string): boolean {
  return name === "FlowSw"
}
function FlagView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }) {
  let data = telemetryData[telemetryData.length - 1].data[telemetryKey];
  const displayName = ParseTelemtryNames(telemetryKey);

  const HasFlow = data >= 300;
  const unit = MapNameToUnit(telemetryKey);
  return (
    <Grid container>
      <Grid item columns={15} container xs={8} borderRight={1} sx={{ paddingLeft: "0.5vw" }} alignContent={"center"}>
        <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
          {displayName}
        </Typography>
      </Grid>
      <Grid item columns={17} xs={4} bgcolor={HasFlow ? "green" : "red"} container justifyContent="center" alignItems={"center"} borderRight={1}>

      </Grid>
      <Grid item xs={2} container justifyContent="center" alignItems={"center"}>
        <Grid item container xs={10} justifyContent="center" alignItems={"center"}>
          <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
            {unit}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function GridNames(): JSX.Element {
  const [ShowInfo, SetShowInfo] = React.useState(false);

  return (
    <Grid container direction={"column"} rowGap={2}>
      <Grid item xs={12} container bgcolor={"white"} borderRadius={"10px"}>
        <Grid item xs={11} container bgcolor={"white"} borderRadius={"10px"} >
          <Grid item xs={7} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
            <Typography variant={"button"} color="black">
              Name
            </Typography>
          </Grid>
          <Grid item xs={3} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
            <Typography variant={"button"} color="black">
              Val
            </Typography>
          </Grid>
          <Grid item xs={2} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
            <Typography variant={"button"} color="black">
              Unit
            </Typography>
          </Grid>

        </Grid>
        <Grid item  xs={1} container alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              SetShowInfo((prev) => !prev);
            }}
          >
            <HelpOutlineIcon></HelpOutlineIcon>
          </IconButton>
        </Grid>
      </Grid>

      {ShowInfo ? (
        <Grid item container bgcolor={"white"} borderRadius={"10px"}>
          <InfoMenu></InfoMenu>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}

function InfoMenu(): JSX.Element {
  return (
    <Grid container bgcolor={"white"} borderRadius={"10px"} direction={"column"}>
      <Grid item container justifyContent={"start"}>
        <Grid></Grid>

        <Typography variant={"button"} color="black">
          Name: The Name of the field.
        </Typography>
      </Grid>
      <Grid item container justifyContent={"start"}>
        <Typography variant={"button"} color="black">
          Val: The value for the given telemetry data field.
        </Typography>
      </Grid>
      <Grid item container justifyContent={"start"}>
        <Typography variant={"button"} color="black">
          Unit_: The unit for a given telemetry field.
        </Typography>
      </Grid>
 
    </Grid>
  );
}

function TelemetricView({ telemetryKey, telemetryData, backgroundColor }: { telemetryKey: string; telemetryData: DatedIotData[]; backgroundColor: string }) {
  const [ShowDetailed, SetShowDetailed] = React.useState<boolean>(false);
  const displayName = ParseTelemtryNames(telemetryKey);
  const HasNoDetailed = HasNoDetailedView(telemetryKey);

  const flag = IsFlag(telemetryKey);
  if (displayName === undefined) {
    return null;
  }
  if (flag) {
    return (
      <ListItem>
        <Grid className="shadow" bgcolor={backgroundColor} container justifyContent="start" minHeight={"1.8vmax"} borderRadius={"0.5vw"}>
          <Grid item xs container justifyContent="center">
            <FlagView telemetryKey={telemetryKey} telemetryData={telemetryData}></FlagView>
          </Grid>
        </Grid>
      </ListItem>
    );
  }

  if (HasNoDetailed) {
    return (
      <ListItem>
        <Grid className="shadow" bgcolor={backgroundColor} container justifyContent="start" minHeight={"1.8vmax"} borderRadius={"0.5vw"}>
          <Grid item xs container justifyContent="center">
            <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>
          </Grid>
        </Grid>
      </ListItem>
    );
  }
  if (!ShowDetailed) {
    return (
      <ListItem>
        <Grid className="shadow" bgcolor={backgroundColor} container justifyContent="start" minHeight={"1.8vmax"} borderRadius={"0.5vw"}>
          <Grid item xs container justifyContent="center">
            <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>
          </Grid>
          <Grid container item justifyContent="end" xs={1}>
            <IconButton
              onClick={() => {
                SetShowDetailed((prev: boolean) => !prev);
              }}
            >
              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
    );
  }

  return (
    <ListItem>
      <Grid container direction={"column"} justifyContent="center" rowGap={1}>
        <Grid item container xs={1} className={"shadow"} bgcolor={"white"} borderRadius={"0.5vw"}>
          <Grid item xs container justifyContent="center">
            <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>
          </Grid>
          <Grid container item xs={1} justifyContent="end">
            <IconButton
              onClick={() => {
                SetShowDetailed((prev: boolean) => !prev);
              }}
            >
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DetailedView telemetryKey={telemetryKey} telemetryData={telemetryData}></DetailedView>
        </Grid>
      </Grid>
    </ListItem>
  );
}

function NormalView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }) {
  let data = telemetryData[telemetryData.length - 1].data[telemetryKey];
  const displayName = ParseTelemtryNames(telemetryKey);

  const parsedValue = data;
  const unit = MapNameToUnit(telemetryKey);
  return (
    <Grid container>
      <Grid item container xs={7} borderRight={1} sx={{ paddingLeft: "0.5vw" }} alignContent={"center"}>
        <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
          {displayName}
        </Typography>
      </Grid>
      <Grid item xs={3} container justifyContent="center" alignItems={"center"} borderRight={1}>
        <Grid item container xs={10} justifyContent="center" alignItems={"center"}>
          <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
            {parsedValue}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2} container justifyContent="center" alignItems={"center"}>
        <Grid item container xs={10} justifyContent="center" alignItems={"center"}>
          <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
            {unit}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function DetailedView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }): JSX.Element {
  return <TelemetricViewer telemetryKey={telemetryKey} telemetryData={telemetryData}></TelemetricViewer>;
}
