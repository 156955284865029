import apiClient from "./apiClient";

export const pingMachine = async (serialNumber) => {
  return apiClient.get(`ctd/Ping/${serialNumber}`);
};

export const checkServiceCode = async (serviceCode) => {
  return apiClient.get(`check-service-code/${serviceCode}`);
};

export const getMachineBySN = async (serialNumber) => {
  return apiClient.get(`machines/own/serial-number/${serialNumber}`);
};

export const getLastMachineTelemetry = async (serialNumber) => {
  return apiClient.get(`device/${serialNumber}`);
};
