import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { IsAdminRoute, IsAuthenticatedRoute } from "./routes/PrivateRoute";
import { userService } from "shared/services/authentication.service";
import { SnackbarProvider, useSnackbar } from "notistack";

import { getMyAccount } from "shared/services/user.service";

import Layout from "./components/Layout";

// ---------- Pages ---------- //
import Login from "./pages/Login/Login";
import ConfirmingAccount from "./pages/Login/ConfirmingAccount";

import Register from "./pages/Register/Register";
import RegisterSuccess from "pages/Register/RegisterSuccess";

import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ForgotPassword/ResetPassword";
import ResetPasswordSuccessfully from "./pages/ForgotPassword/ResetPasswordSuccessfully";

import NotFoundPage from "components/NoFoundPage";
import UnauthorizedAccess from "components/UnauthorizedAccess";
import InternalServerErrorPage from "components/InternalServerErrorPage";

import Home from "./pages/Home/Home";

import User from "./pages/User/View/ViewUser";

// ######### Factories ######### //
import FactoryEdit from "./pages/Factory/Edit/FactoryEdit";
import Factories from "./pages/Factory/View/ViewFactories";
import Factory from "./pages/Factory/View/ViewFactory";
import Rooms from "./pages/Factory/Room/View/ViewRooms";
import ViewFactoryDetails from "pages/Factory/View/ViewFactoryDetails";
import ViewFactoryUsers from "pages/Factory/View/ViewFactoryUsers";
import ViewFactoryMachines from "pages/Factory/View/ViewFactoryMachines";

import MachinesManagement from "./pages/Device/View/ViewMachinesManagement";
// ######### Machines ######### //
import ViewMachine from "./pages/Device/Machine/ViewMachine";
import ViewMachineDetails from "pages/Device/Machine/ViewMachineDetails";


// Navigator tool
import SNConnectPage from "pages/Navigator/SNConnectPage";
import NavigatorApp from "pages/Navigator/NavigatorApp/src/Views/NavigatorApp";

import NavigatorLayout from "pages/Navigator/NavigatorLayout/NavigatorLayout";

import "preline";
// ######### Settings ######### //
import Settings from "./pages/User/Settings/Settings";
import General from "pages/User/Settings/General";
import Password from "pages/User/Settings/Password";
import Notifications from "pages/User/Settings/Notifications";



// ---------- Translation ---------- //
import { Provider } from "react-translated";
import translation from "./shared/utils/translation";

// ---------- Redux ---------- //
import { useDispatch } from "react-redux";
import { setUser } from "shared/store/user/actions/userActions";
import { useSelector } from "react-redux";

import "preline";
import ViewMachineTelemetry from "pages/Device/Machine/ViewMachineTelemetry";
import ViewMachineAlerts from "pages/Device/Machine/ViewMachineAlerts";
import ViewMachineErrors from "pages/Device/Machine/ViewMachineErrors";
import ViewMachineSettings from "pages/Device/Machine/ViewMachineSettings";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

window.HSStaticMethods = window.HSStaticMethods || {};

const queryClient = new QueryClient();

//TODO implement driverjs for tutorials
function App() {
  const location = useLocation();
  const [showDevtools, setShowDevtools] = React.useState(false);
  const dispatch = useDispatch();
  const myAccount = useSelector((state) => state.user.user);

  const [language, setLanguage] = useState("en");
  useEffect(() => {
    if (window.HSStaticMethods.autoInit) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      if (userService.loggedIn() === true) {
        getMyAccount().then((res) => {
          console.log("res", res);
          const acc = res.data.user;
          console.log(myAccount);
          dispatch(setUser({ user: acc }));
          setLanguage(acc.language);
        });
      }
    }
  }, []);

  const LoginRoute = () => {
    const myAccount = useSelector((state) => state.user.user);
    const isAuthenticated = !!myAccount;
    const isAllowed = userService.loggedIn();

    // Redirect to home if user is already authenticated
    return isAuthenticated && isAllowed ? (
      <Navigate to="/home" replace />
    ) : (
      <Login />
    );
  };

  //TODO Implement lazy loading for the routes that are not used that often so the initial loading is faster
  return (
    <QueryClientProvider client={queryClient}>
      <Provider language={language} translation={translation}>
        <SnackbarProvider
          maxSnack={4}
          autoHideDuration={30000}
          
          Components={{
            success: SuccessCustom,
          }}
        >
          <Routes>
            <Route path="/login" element={<LoginRoute />} />
            <Route
              path="/confirm-account/:token"
              element={<ConfirmingAccount />}
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/register-successfully/:email"
              element={<RegisterSuccess />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/reset_password/:token"
              element={<ResetPassword />}
            />{" "}
            {/* //TODO deprecate this one */}
            <Route
              path="/reset-password-successfully"
              element={<ResetPasswordSuccessfully />}
            />
            <Route element={<IsAuthenticatedRoute />}>
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/unauthorized" element={<UnauthorizedAccess />} />
                <Route path="/settings" element={<Settings />} />
                <Route element={<IsAdminRoute />}>{/* Admin routes */}</Route>

                {/* Factories routes */}
                <Route path="factories" element={<Factories />} />
                <Route path="factories/:factoryId/view" element={<Factory />}>
                  <Route path="details" element={<ViewFactoryDetails />} />
                  <Route path="users" element={<ViewFactoryUsers />} />
                  <Route path="machines" element={<ViewFactoryMachines />} />
                </Route>

                <Route
                  path="factories/:factoryId/edit"
                  element={<FactoryEdit />}
                />
                <Route
                  path="factories/:factoryId/view/room/management"
                  element={<Rooms />}
                />

                <Route
                  path="factories/:factoryId/machine/:serialNumber"
                  element={<ViewMachine />}
                >
                  <Route path="details" element={<ViewMachineDetails />} />
                  <Route path="telemetry" element={<ViewMachineTelemetry />} />
                  <Route path="alerts" element={<ViewMachineAlerts />} />
                  <Route path="error-log" element={<ViewMachineErrors />} />
                  <Route path="settings" element={<ViewMachineSettings />} />
                </Route>
                <Route
                  path="factories/:factoryId/user/:userId"
                  element={<User />}
                />

                {/* Machines routes */}
                <Route path="machine/:serialNumber" element={<ViewMachine />} />

                {/* Settings routes */}
                <Route path="settings" element={<Settings />}>
                  <Route path="general" element={<General />} />
                  <Route path="password" element={<Password />} />
                  <Route path="notifications" element={<Notifications />} />
                </Route>
                {/* --------------- */}
                <Route path="/navigator/connect" element={<SNConnectPage />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route
                  path="/internal-server-error"
                  element={<InternalServerErrorPage />}
                />
              </Route>
                <Route element={<Layout />}>

                  <Route path="/navigator/connect" element={<SNConnectPage />} />
                </Route>
                <Route element={<NavigatorLayout/>}>

                  <Route path="/navigator/control-panel" element={<NavigatorApp/>} />
                </Route>
            </Route>
          </Routes>
        </SnackbarProvider>
      </Provider>

      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider >
  );
}

export default App;

const SuccessCustom = React.forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <div ref={ref} {...props} >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    Successfully saved!
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Anyone with a link can now view this file.
                  </p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      closeSnackbar(props.id);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
});


const ErrorCustom = React.forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <div ref={ref} {...props} >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    Successfully saved!
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Anyone with a link can now view this file.
                  </p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      closeSnackbar(props.id);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
});
