import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { GetPopUpBarListener, PopUpBarType } from "../Models/HistoricViewlistener";
import { Menu as MenuModel, Menus, SubScreen as SubScreenModel, UiElement } from "../Models/Menus";
import { GetServer } from "../Models/ServerCommuncation/Server";
import { DatedIotData } from "../Models/ServerCommuncation/serverInterFace";
import DashBoard from "./DashBoard/Dashboard";
import DiodeBox from "./DashBoard/diodebox";
import InfoBox from "./DashBoard/infobox";
import ShowTimeErrorBarView from "./HistoricView/ErrorHistoryViewAlternative";
import HistoricUpTimeView from "./HistoricView/UpTimeHistoryView";
import MenuView from "./Menu";
import { TelemtricBottomView } from "./uiElements/noninteractive/TelemtryView.tsx/telemtricview";
//@ts-ignore
import displayImage from "shared/utils/images/DisplayBorder.png";
import { ErrorLogEntry } from "../Models/Errorlog/ErrorLogEntry";
import { DisplayInverters, DisplayTotalCounters } from "./DashBoard/TripCounterBox";
export default function Screen({ machineConfig }: { machineConfig: Record<string, any> }) {
  const Server = GetServer();

  const [expanded, setExpanded] = React.useState(true);
  const [menuData, setMenuData] = React.useState(new Menus(machineConfig));
  const [errorMessage, SetErrorMessage] = React.useState(null);
  const [Menu, SetMenu] = React.useState<null | MenuModel>(null);
  const [subScreen, SetSubScreen] = React.useState<null | SubScreenModel>(null);
  const [IsUpdating, SetIsUpdating] = React.useState<boolean>(false);
  let prevTelemtry = React.useRef<null | DatedIotData>(null);
  let currTelemetry = React.useRef<null | DatedIotData>(null);
  let isUpdatingRef = React.useRef<boolean>(IsUpdating);

  function HandleDropDown() {
    setExpanded((current) => !current);
  }

  function GetUiElements(): UiElement[] {
    return machineConfig.globalUiElements as UiElement[];
  }
  function CheckTelemtryData(curr: DatedIotData): boolean {
    if (prevTelemtry.current == null) {
      return false;
    }
    const keys = ["CurrentSubMenuNo", "CurrentMenuNo", "PressureAtButton", "MachineStandby", "BarButtonSelected"];
    for (const key of keys) {

      if (curr.data[key] !== prevTelemtry.current!.data[key]) {

        return true;
      }
    }
    return false;
  }
  function TelemtryCallBack(telemtryData: DatedIotData) {

    currTelemetry.current = telemtryData;

    if (subScreen?.SubScreenName !== telemtryData.data.CurrentSubMenuNo || Menu?.menuNo !== telemtryData.data.CurrentMenuNo || !subScreen) {
      let menu = menuData?.GetMenu(telemtryData.data.CurrentMenuNo.toString());
      if (!menu) {
        SetMenu(null);
        return;
      }
      let subscreen = menu.GetSubScreen(telemtryData.data.CurrentSubMenuNo.toString());
      SetMenu(menu);

      SetSubScreen(subscreen);
    }
    if (IsUpdating && CheckTelemtryData(telemtryData)) {
      SetIsUpdating(false);
      isUpdatingRef.current = false;
    }
  }

  useEffect(() => {
    Server.on_telemtry(TelemtryCallBack);

    return () => {
      Server.off_telemtry(TelemtryCallBack);
    };
  });

  function SetScreenUpdating() {
    SetIsUpdating(true);
    prevTelemtry.current = currTelemetry.current;
    isUpdatingRef.current = true;

    setTimeout(()=> {
      if(isUpdatingRef.current === true) {
        
        GetServer().SendCommand("SetNavigatorButton", "GoBack", () => { });

      }
    },30000)
  }
  return (
    <Grid container direction="column" wrap="nowrap" overflow={"hidden"}>
      {/* <Grid item container height={"5vh"} bgcolor={"#001b54"} alignContent={"center"} columnGap={1}>
        <Grid item xs sx={{ paddingLeft: "1vw" }}>
          <Button onClick={disconnectHandler}>
            <Typography color={"white"} variant={"button"}>
              Disconnect
            </Typography>{" "}
          </Button>
        </Grid>
        <Grid item xs sx={{ paddingLeft: "1vw" }}>
          <img src={"/Images/" + "logo.svg"} alt={"NilFisk Food"}></img>{" "}
        </Grid>
      </Grid> */}

      <Grid item container columns={22} columnGap={1} height={"81vh"} sx={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
        <Grid item xs>
          <Grid item container xs direction="column" justifyContent={"center"} alignContent={"center"}>
            <Grid item container direction={"row"} sx={{ paddingTop: "1vh" }} height={"23vmax"}>
              <Grid item xs container direction="column" wrap="nowrap" maxHeight={"23vmax"} spacing={1}>
                <Grid item xs={3} container justifyContent={"start"} alignContent={"start"}>
                  <Grid item xs={10}>
                    <InfoBox></InfoBox>
                  </Grid>
                </Grid>

                <Grid item xs={3} container justifyContent={"start"} alignContent={"start"}>
                  <Grid item xs={10}>
                    <DisplayInverters></DisplayInverters>
                  </Grid>
                </Grid>


              </Grid>
              <Grid item container xs={6} direction="column" alignItems={"center"} >
                <img style={{ paddingRight: "1.2vw", display: "block", border: "none", position: "absolute", width: "40vmax", height: "23vmax" }} src={displayImage} alt="NilfiskFood" />
                <Box style={{ paddingTop: "1.5vmax", paddingRight: "1vw", position: "absolute", zIndex: 10 }}>
                  <MenuView menu={Menu} subScreen={subScreen} screenUpdating={IsUpdating} globalUiElements={GetUiElements()} ScreenUpdate={SetScreenUpdating}></MenuView>
                </Box>
              </Grid>
              <Grid item xs container direction="column" wrap="nowrap" spacing={1} sx={{ paddingLeft: "1vmax" }}>

                <Grid item xs={3} container justifyContent={"center"} alignContent={"start"} >
                  <Grid item xs={10}>
                    <DiodeBox></DiodeBox>
                  </Grid>
                </Grid>
                <Grid item xs={3} container justifyContent={"center"} alignContent={"start"} >
                  <Grid item xs={10}>
                    <DisplayTotalCounters></DisplayTotalCounters>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid item height={"2vmax"}></Grid>

            <Grid item container xs={6} justifyContent={"center"} alignItems={"center"} columnSpacing={1}  >
              <Grid item xs={6} height={"100%"}>
                <TelemtricBottomView defaultKey={"PressureOUT"}></TelemtricBottomView>
              </Grid>
              <Grid item xs={6} height={"100%"}>
                <TelemtricBottomView defaultKey={"PressureIN"}></TelemtricBottomView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DashBoardView expanded={expanded} ShowTelemetry={true} HandleDropDown={HandleDropDown}></DashBoardView>
      </Grid>
      <Grid item width={"100vw"} height={"14vh"} bgcolor={"#001b54"}>
        <BarTimeView></BarTimeView>
      </Grid>
    </Grid>
  );
}
type BarUpView = (arg?: string[], errologEntries?: ErrorLogEntry[]) => JSX.Element;
function BarTimeView(): JSX.Element {
  const BarMap: Record<PopUpBarType, BarUpView> = {
    ErrorTime: (arg?: string[], errologEntries?: ErrorLogEntry[]) => {
      return <ShowTimeErrorBarView displayname={arg![0]} errorName={arg![1]} ErrorLogsList={errologEntries!}></ShowTimeErrorBarView>;
    },
    UpTime: (arg?: string[], errologEntries?: ErrorLogEntry[]) => {
      return <HistoricUpTimeView></HistoricUpTimeView>;
    }
  };
  const [BarView, SetBarView] = React.useState<JSX.Element>(<HistoricUpTimeView></HistoricUpTimeView>);
  function ChangeBarUpView(type: PopUpBarType, val?: string[], errologEntries?: ErrorLogEntry[]) {
    const Component = BarMap[type](val, errologEntries);
    SetBarView(Component);
  }
  useEffect(() => {
    let listener = GetPopUpBarListener();
    listener.addListener(ChangeBarUpView);
    return () => {
      listener.removeListener(ChangeBarUpView);
    };
  }, []);
  return BarView;
}

function DashBoardView({ expanded, ShowTelemetry, HandleDropDown }: { expanded: boolean; ShowTelemetry: boolean; HandleDropDown: () => void }) {
  if (!ShowTelemetry) {
    return null;
  }

  return (
    <Grid item xs={expanded ? 6 : 0} height={"76vh"}>

      <DashBoard></DashBoard>
    </Grid >
  );
}
