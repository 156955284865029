import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const createRoom = (newRoom) => {
  return axios.post("https://api.nilfiskfood.com/api/room/create", newRoom, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const createOwnRoom = (newRoom) => {
  return axios.post("https://api.nilfiskfood.com/api/room/own/create", newRoom, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const useGetOwnRoomsByFactoryId = (FactoryId) => {
  return useQuery({
    queryKey: ["rooms", FactoryId],
    queryFn: async () => {
      const token = JSON.parse(localStorage.getItem("token")).token;
      const response = await axios.get(`https://api.nilfiskfood.com/api/rooms/own/factory/${FactoryId}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    },
  });
};

export const getOwnRoomsByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/rooms/own/factory/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const getOwnOnlyRoomNamesByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/rooms/only/own/factory/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const getOnlyRoomNamesByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/rooms/only/factory/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getRoomById = (RoomId) => {
  return axios.get("https://api.nilfiskfood.com/api/room/" + RoomId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getRoomsByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/rooms/factory/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const updateRoom = (RoomId) => {
  return axios.put("https://api.nilfiskfood.com/api/room/" + RoomId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const updateOwnRoom = (RoomId, room) => {
  return axios.put("https://api.nilfiskfood.com/api/room/own/" + RoomId, room, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const deleteRoom = (RoomId) => {
  return axios.delete("https://api.nilfiskfood.com/api/rooms/" + RoomId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const deleteOwnRoom = (RoomId) => {
  return axios.delete("https://api.nilfiskfood.com/api/rooms/own/" + RoomId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
