import { Fragment } from "react";
import MachineRow from "./MachineRow";

export default function RoomRow({ room, machines }) {
  return (
    <Fragment key={room.name}>
      <tr key={room.name} className="border-t border-gray-200">
        <th
          colSpan={5}
          scope="colgroup"
          className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
        >
          {room.name} Room
        </th>
      </tr>
      {machines.map((machine, machineIdx) => (
        <MachineRow key={machineIdx} machine={machine} machineIdx={machineIdx} />
      ))}
    </Fragment>
  );
}
