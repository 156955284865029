import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BoosterType, ParseBoosterType } from "../../Models/ParsingOfBoosterType";
import { GetServer } from "../../Models/ServerCommuncation/Server";


type InfoElement = { name: string; value: string | number };
export default function InfoBox(): JSX.Element {
  return (
    <>
      <Grid item container xs={11} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" minHeight={"20vh"}>
        <InfoElements></InfoElements>
      </Grid>
    </>
  );
}

function CreateInfoElements(data: Record<string, string>): InfoElement[] {
  return Object.keys(data)
    .filter((key) => {
      return data[key] !== "NA";
    })
    .map((key) => {
      return { name: key, value: data[key] as string };
    });
}

function parseSwVersions(str: String): Record<string, string> {
  let [_, data] = str.split("'Response'           :");
  let data_obj: Record<string, string> = {};
  for (const ele of data.split("#")) {
    let [key, val] = ele.split(":");
    if (val) {
      data_obj[key] = val;
    }
  }
  return data_obj;
}
function InfoElements(): JSX.Element {
  const Server = GetServer();
  const [infoElements, SetinfoElements] = React.useState<InfoElement[]>([]);
  const [BoosterType, SetBoosterType] = React.useState<BoosterType | null>(null);
  const server = GetServer();
  useEffect(() => {
    server.SendCommand("GetSwVersionsJSON", null, (data, err) => {
      if (!err) {
        let infoElements = CreateInfoElements(data!);
        SetinfoElements(infoElements);
      }
    });
    server.GetEepromSettings((mabye_data, err) => {
      
      if (!err) {
        const data = mabye_data!;
        SetBoosterType(ParseBoosterType(data));
      }
    });
  }, []);
  if (infoElements.length === 0) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"} height={"10vmax"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  if (BoosterType) {
    return (
      <>
        <InfoElement name="Serial Number" value={Server.getmahineId()}></InfoElement>
        <InfoElement name="Booster Type" value={BoosterType.boosterType}></InfoElement>
        <InfoElement name="Machine Code" value={BoosterType.boosterCode}></InfoElement>

        {infoElements.map((infoElement) => {
          return <InfoElement name={infoElement.name} value={infoElement.value.toString()}></InfoElement>;
        })}
      </>
    );
  }
  return (
    <>
      <InfoElement name="DeviceId" value={Server.getmahineId()}></InfoElement>
      {infoElements.map((infoElement) => {
        return <InfoElement name={infoElement.name} value={infoElement.value.toString()}></InfoElement>;
      })}
    </>
  );
}

function InfoElement({ name, value }: { name: string; value: string }): JSX.Element {
  return (
    <Grid item container columns={13}>
      <Grid item container xs={7} borderRight={1} justifyContent={"start"} alignItems={"center"} sx={{ paddingLeft: "0.5vw" }}>
        <Typography variant="button" color={"black"} fontSize={"0.8vmax"}>
          {name}
        </Typography>
      </Grid>

      <Grid item container xs={5} justifyContent={"start"} paddingLeft={"0.5vw"} alignItems={"center"} sx={{ paddingLeft: "0.5vw" }}>
        <Typography variant="button" color={"black"} fontSize={"0.7vmax"}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}
