import { useQuery } from "@tanstack/react-query";
import { getOwnFactoryById } from "api/factory.service";

export const useOwnFactoryByIdQuery = (id) => {
  return useQuery({
    queryKey: ["factory", id],
    queryFn: () => getOwnFactoryById(id),
    enabled: !!id, // This prevents the query from automatically running if the userId is not provided
  });
};
