import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const forgotPassword = (user) => {
  return axios
    .post("https://api.nilfiskfood.com/api/users/forgot_password", {
      email: user.email,
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resetPassword = (user) => {
  return axios.post("https://api.nilfiskfood.com/api/users/reset_password", user);
};

export const confirmAccount = (user) => {
  return axios.post("https://api.nilfiskfood.com/api/users/confirm_account", user);
};

export const login = (user) => {
  return axios
    .post("https://api.nilfiskfood.com/api/users/login", {
      email: user.email,
      password: user.password,
    })
    .then((response) => {
      localStorage.setItem("token", JSON.stringify(response.data)); //respnse.data.token to store only the token, probably will be better?
      return response.data;
    });
};
export const registerUser = (newUser) => {
  return axios.post("https://api.nilfiskfood.com/api/users/register", newUser);
};
export const getUsers = () => {
  return axios.get("https://api.nilfiskfood.com/api/users", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const createUser = (newUser) => {
  return axios.post("https://api.nilfiskfood.com/api/users", newUser, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const createUserInAFactoryAdmin = (newUser) => {
  return axios.post("https://api.nilfiskfood.com/api/factory/users/admin", newUser, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const createUserInMyFactory = (newUser) => {
  return axios.post("https://api.nilfiskfood.com/api/factory/users", newUser, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const getMyAccount = () => {
  return axios.get("https://api.nilfiskfood.com/api/users/my-account", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getUserById = (UserId) => {
  return axios.get("https://api.nilfiskfood.com/api/users/" + UserId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getOwnUserById = (UserId) => {
  console.log("ttteeeest", UserId);
  return axios.get("https://api.nilfiskfood.com/api/user/" + UserId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getUsersByCompanyId = (CompanyId) => {
  return axios.get("https://api.nilfiskfood.com/api/users/company/" + CompanyId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getOwnEmployeeByUserId = (UserId, FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/users/own/factory/" + FactoryId + "/" + UserId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getOwnUsersByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/users/own/factory/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const useGetUsersByFactoryId = (FactoryId) => {
  return useQuery({
    queryKey: ["factory", FactoryId, "users"],
    queryFn: async () => {
      const token = JSON.parse(localStorage.getItem("token")).token;
      const response = await axios.get(`https://api.nilfiskfood.com/api/users/factory/${FactoryId}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    },
  });
};

export const getUsersByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/users/factory/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const updateMyAccount = (user) => {
  return axios.put("https://api.nilfiskfood.com/api/users/my-account", user, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const updateOwnUser = (UserId, user) => {
  return axios.put("https://api.nilfiskfood.com/api/user/" + UserId, user, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const updateUser = (UserId, user) => {
  return axios.put("https://api.nilfiskfood.com/api/users/" + UserId, user, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const updateUserLanguage = (user) => {
  return axios.put("https://api.nilfiskfood.com/api/users/language", user, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const updateUserPassword = (user) => {
  return axios.put("https://api.nilfiskfood.com/api/users/own/change-my-password", user, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getUserLanguage = () => {
  return axios.get("https://api.nilfiskfood.com/api/users/language", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const deletePermanentlyMyAccount = () => {
  return axios.delete("https://api.nilfiskfood.com/api/user/delete-my-account", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const CloseMyAccount = () => {
  return axios.delete("https://api.nilfiskfood.com/api/user/close-my-account", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const searchAndFilterUsers = (filters) => {
  return axios.post("https://api.nilfiskfood.com/api/users/search/order", filters, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const useGetOwnEmployeesFromFactory = (FactoryId) => {
  return useQuery({
    queryKey: ["factory", FactoryId, "users"],
    queryFn: async () => {
      const token = JSON.parse(localStorage.getItem("token")).token;
      const response = await axios.get(`https://api.nilfiskfood.com/api/users/factory/own/employees/${FactoryId}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    },
  });
};

export const getOwnEmployeesFromFactory = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/users/factory/own/employees/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const searchUsers = (search) => {
  return axios.post("https://api.nilfiskfood.com/api/users/search/advance", search, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const searchOwnUsers = (search) => {
  return axios.post("https://api.nilfiskfood.com/api/users/own/search/advance", search, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const deleteUser = (UserId) => {
  return axios.delete("https://api.nilfiskfood.com/api/users/" + UserId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const deleteUserAccountOwn = (UserId) => {
  return axios.delete("https://api.nilfiskfood.com/api/users/" + UserId + "/delete-account/own", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const deactivateUserAccountOwn = (UserId) => {
  return axios.put(
    "https://api.nilfiskfood.com/api/users/" + UserId + "/deactivate-account/own",
    {},
    {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")).token,
      },
    }
  );
};
export const activateUserAccountOwn = (UserId) => {
  return axios.put(
    "https://api.nilfiskfood.com/api/users/" + UserId + "/activate-account/own",
    {},
    {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")).token,
      },
    }
  );
};

export const deactivateUserAccount = (UserId) => {
  return axios.put(
    "https://api.nilfiskfood.com/api/users/" + UserId + "/deactivate-account",
    {},
    {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")).token,
      },
    }
  );
};

export const activateUserAccount = (UserId) => {
  return axios.put(
    "https://api.nilfiskfood.com/api/users/" + UserId + "/activate-account",
    {},
    {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")).token,
      },
    }
  );
};

//ban user
export const banUser = (UserId, data) => {
  return axios.put("https://api.nilfiskfood.com/api/users/" + UserId + "/ban", data, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ban user own
export const banUserOwn = (UserId, data) => {
  return axios.put("https://api.nilfiskfood.com/api/users/" + UserId + "/ban/own", data, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//unban user
export const unbanUser = (UserId) => {
  return axios.put(
    "https://api.nilfiskfood.com/api/users/" + UserId + "/unban",
    {},
    {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")).token,
      },
    }
  );
};

//unban user own
export const unbanUserOwn = (UserId) => {
  return axios.put(
    "https://api.nilfiskfood.com/api/users/" + UserId + "/unban/own",
    {},
    {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")).token,
      },
    }
  );
};
