//this is the next task.

import React from "react";

import { Button, Grid, Typography } from "@mui/material";

import { GetPopUpBarListener } from "../../Models/HistoricViewlistener";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import ErrorLogView from "./ErrorLogView";
import SettingsView from "./Settings";
import TelemtryDataView from "./TelemetryView";
type dashBoardMenuType = "telemetry" | "errorlog" | "settings";

type dashBoardMenuView = () => JSX.Element | null;

const DashBoardMenuViews: Record<dashBoardMenuType, dashBoardMenuView> = {
  telemetry: TelemtryDataView,
  errorlog: ErrorLogView,
  settings: SettingsView,
};

export default function DashBoard() {
  const [dashBoardMenu, setDashBoardMenu] = React.useState<dashBoardMenuType>("telemetry");
  let DashBoardMenuView = DashBoardMenuViews[dashBoardMenu];
  const server = GetServer();
  const listener = GetPopUpBarListener();
  return (
    <Grid item container direction="column" width={"100%"} wrap="nowrap"  >
      <Grid item container height={"10%"}>
        <DashBoardMenuButton
          dashBoardMenu={dashBoardMenu}
          name="telemetry"

          onClick={() => {
            if (!server.machineIsRebooting()) {
              listener.callListeners("UpTime");
              setDashBoardMenu("telemetry");
            }

          }}
        ></DashBoardMenuButton>

        <DashBoardMenuButton
          dashBoardMenu={dashBoardMenu}
          name="errorlog"
          onClick={() => {
            if (!server.machineIsRebooting()) {
              setDashBoardMenu("errorlog");

            }

          }}
        ></DashBoardMenuButton>
        <DashBoardMenuButton
          dashBoardMenu={dashBoardMenu}
          name="settings"
          onClick={() => {
            if (!server.machineIsRebooting()) {
              listener.callListeners("UpTime");
              setDashBoardMenu("settings");

            }
          }}
        ></DashBoardMenuButton>
      </Grid>
      <Grid item xs={12} direction={"column"} height={"80%"} wrap="nowrap" container>
        <DashBoardMenuView></DashBoardMenuView>
      </Grid>
    </Grid>
  );
}

function DashBoardMenuButton({ name, dashBoardMenu, onClick }: { name: dashBoardMenuType; dashBoardMenu: dashBoardMenuType; onClick: () => void }): JSX.Element {
  if (dashBoardMenu === name) {
    return (
      <Grid item xs bgcolor={"#001b54"}>
        <Button fullWidth onClick={onClick}>
          <Typography color={"white"} variant="button">
            {name}
          </Typography>
        </Button>
      </Grid>
    );
  }
  return (
    <Grid item xs>
      <Button fullWidth onClick={onClick}>
        {name}{" "}
      </Button>
    </Grid>
  );
}
