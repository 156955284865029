import { useQuery } from "@tanstack/react-query";
import { getMachineBySN } from "api/machine.service";

export const useMachineBySNQuery = (serialNumber) => {
  return useQuery({
    queryKey: ["machine", serialNumber, "details"],
    queryFn: () => getMachineBySN(serialNumber),
    enabled: !!serialNumber,
  });
};
