import { Button, CircularProgress, Grid, Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";

type TotalTripCountersData = {
  "Install Time": string;
  "Last Reset Trip Time": string & {
    [key: string]: InverterData;
  };
};
type TotalTripCounter = {
  "Install Time": string;
  "Last Reset Trip Time": string;
};
type InverterData = { "Total Run Hours": number; "Total On Hours": number; "Total kWh": number };

function GetTotalTripCounter(TotalTripCounters: TotalTripCountersData): TotalTripCounter {
  const { "Install Time": omit1, "Last Reset Trip Time": omit2 } = TotalTripCounters;

  return { "Install Time": omit1, "Last Reset Trip Time": omit2 };
}

function GetInverters(TotalTripCounters: TotalTripCountersData): Record<string, InverterData> {

  const { "Install Time": omit1, "Last Reset Trip Time": omit2, ...rest } = TotalTripCounters;

  return rest;
}
//GetTotalCountersJSON
export function DisplayTotalCounters(): JSX.Element {
  const Server = GetServer();
  const [totalTripCounters, setTotalTripCounters] = React.useState<TotalTripCounter | null>(null);

  useEffect(() => {
    Server.SendCommand("GetTotalCountersJSON", null, (data,err) => {
      if (!err) {
        let totalTripCounters = data as any as TotalTripCountersData;
        setTotalTripCounters(GetTotalTripCounter(totalTripCounters));
      }

    });
  }, []);

  if (!totalTripCounters) {
    return <Grid item container xs={12} bgcolor={"white"} borderRadius={"0.5vw"} justifyContent={"center"} alignContent={"center"} className="shadow" height={"6vmax"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }


  return (
    <Grid item container xs={12} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" height={"6vmax"}>
      <InfoElement name="Install Time:" value={totalTripCounters["Install Time"]}></InfoElement>
      <InfoElement name="Last Reset Trip Time" value={totalTripCounters["Last Reset Trip Time"]}></InfoElement>
    </Grid>
  );
}

export function DisplayInverters(): JSX.Element {
  const Server = GetServer();
  const [Inverters, SetInverters] = React.useState<Record<string, InverterData> | null>(null);
  const [Inverter, SetInverter] = React.useState<string>("Inverter1");

  useEffect(() => {
    Server.SendCommand("GetTotalCountersJSON", null, (data,err) => {
      if (!err) {

      let totalTripCounters = data as any as TotalTripCountersData;

      SetInverters(GetInverters(totalTripCounters));
      }
    });
  }, []);
  if (!Inverters) {
    return <Grid item container xs={11} bgcolor={"white"} justifyContent={"center"} alignContent={"center"} borderRadius={"0.5vw"} className="shadow" height={"6vmax"} width={"100%"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  return (
    <Grid item container xs={11} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow">
      <SelectInverters Inverters={Inverters} inverter={Inverter} SetInverter={SetInverter}></SelectInverters>
      <DisplayInverter Inverter={Inverters[Inverter]}></DisplayInverter>
    </Grid>
  );
}

function DisplayInverter({ Inverter }: { Inverter: InverterData }) {
  return (
    <Grid container direction={"column"}>
      {Object.entries(Inverter).map(([key, val]) => {
        return <InfoElement name={key} value={val.toString()}></InfoElement>;
      })}
    </Grid>
  );
}
function SelectInverters({ Inverters, inverter, SetInverter }: { Inverters: Record<string, InverterData>; inverter: String; SetInverter: React.Dispatch<React.SetStateAction<string>> }): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose(key: string) {
    SetInverter(key);

    setAnchorEl(null);
  }
  return (
    <Grid item container justifyContent={"center"} alignItems={"center"}>
      <Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        {inverter}
      </Button>
      <Popper open={open} anchorEl={anchorEl} role={undefined} placement="bottom-start" transition disablePortal sx={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" sx={{ bgcolor: "white", position: "absolute", overflow: "auto", }} className="scroll shadow">
                {Object.keys(Inverters).map((inverterkey) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClose(inverterkey);
                      }}
                    >
                      {inverterkey}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}
function InfoElement({ name, value }: { name: string; value: string }): JSX.Element {
  return (
    <Grid item container columns={13}>
      <Grid item container xs={7} borderRight={1} justifyContent={"start"} alignItems={"center"} sx={{ paddingLeft: "0.5vw" }}>
        <Typography variant="button" color={"black"} fontSize={"0.8vmax"}>
          {name}
        </Typography>
      </Grid>

      <Grid item container xs={5} justifyContent={"start"} paddingLeft={"0.5vw"} alignItems={"center"} sx={{ paddingLeft: "0.5vw" }}>
        <Typography variant="button" color={"black"} fontSize={"0.7vmax"}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}
