import React from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useUserOwnedFactoryById } from "shared/services/factory.service";
import { CircularProgress, Backdrop } from "@mui/material";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { TiInfoLarge } from "react-icons/ti";
import { VscDashboard } from "react-icons/vsc";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { LuFileWarning } from "react-icons/lu";
import { MdOutlineSettings } from "react-icons/md";

import Breadcrumbs from "components/Breadcrumbs";
import { useMachineBySNQuery } from "hooks/machine/useMachineBySNQuery";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewFactory(props) {
  let { factoryId, serialNumber } = useParams();
  const location = useLocation();
  let navigate = useNavigate();

  const tabs = [
    { name: "Details", url: "details", icon: TiInfoLarge, current: true },
    { name: "Telemetry", url: "telemetry", icon: VscDashboard, current: false },
    { name: "Alerts", url: "alerts", icon: HiOutlineBellAlert, current: false },
    { name: "Error log", url: "error-log", icon: LuFileWarning, current: false },
    { name: "Settings", url: "settings", icon: MdOutlineSettings, current: false },
    //   { name: "Rooms", href: "#", icon: CreditCardIcon, current: false },
  ].map((item) => ({
    ...item,
    current: location.pathname.includes(item.url),
  }));

  const { data: factoryData, isLoading, error } = useUserOwnedFactoryById(factoryId);
  if (error) {
    navigate("/internal-server-error", { replace: true });
  }

  const { data: machineData, isLoading: machineLoading, error: machineError } = useMachineBySNQuery(serialNumber);
  console.log("machineData", machineData);
  const pages = [
    { name: "Factories", url: "/factories", current: false },
    // { name: "Factory", url: "factory", current: true },
    { name: factoryData?.factory?.name, url: `/factories/${factoryId}/view/details`, current: false },
    { name: machineData?.data?.machine[0]?.name, url: `/Factories/${factoryId}/machine/${serialNumber}/details`, current: true },
  ];
  return (
    <>
      <Breadcrumbs pages={pages} />
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight ">
            {machineData?.data?.machine[0]?.name}
          </h2>
        </div>
       
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mb-4">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-blue-400 focus:ring-blue-400"
            defaultValue={tabs.find((tab) => tab.current)?.name || tabs[0].name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.url}
                  className={classNames(
                    tab.current
                      ? "border-blue-400 text-blue-500"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <tab.icon
                    className={classNames(
                      tab.current ? "text-blue-400" : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <Outlet context={{ factoryDetails: factoryData?.factory, loading: isLoading, machineData: machineData?.data?.machine[0] }} />
    </>
  );
}
