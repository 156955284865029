import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Divider, Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeIntializeServer, HttpServer, InitializeServer, SendCommand } from "../Models/ServerCommuncation/Server";
import { DisconnectErrorType, IoSocket } from "../Models/ServerCommuncation/Socket";
import { DatedIotData } from "../Models/ServerCommuncation/serverInterFace";
import ClientMenuPage from "./ClientMenuPage";
import "./DashBoard/dashboard.css";
import "./NavigatorApp.css";



export default function NavigatorApp() {
  const navigate = useNavigate();
  const state: { serialNumber?: string, serviceCode ?: string, DeinitServer?: boolean } = useLocation().state;
  window.history.replaceState({ state: {} }, "");
  const [deviceConnected, setDeviceConnected] = React.useState({ state : false, has_been_true : false});
  const [server, setServer] = React.useState<HttpServer | null>(null);
  



  function LookForNavigatorMode(data: DatedIotData) {
    console.log(data);
    if (data.data!.NavigatorEnabled == 1) {
      setDeviceConnected({state : true, has_been_true : true});
      
      return;
    }
    
    setDeviceConnected((prev) => { return {state : true, has_been_true : prev.has_been_true} });
  }

  useEffect(() => {
    if (deviceConnected.state === false && deviceConnected.has_been_true === true &&  server !== null) {
      DeIntializeServer();

      setServer(null);
    }
  },[server,deviceConnected])
  useEffect(() => {
    const IntializeAndSetServer = async () => {

      setServer(await InitializeServer(new IoSocket(state.serialNumber!), state.serialNumber!,state.serviceCode!, DisconnectFromDeviceWithError));

    }
    if (!state?.serialNumber) {
      navigate("/navigator/connect", { state: { errorMessage: "Machine disconnected" } })
      return;

    }

    IntializeAndSetServer();


  }, [state]);

  useEffect(() => {
    if (!server) {
      return;
    }
    console.log("called");

    server.on_telemtry(LookForNavigatorMode);

    return () => {
      server.off_telemtry(LookForNavigatorMode);
    };
  }, [server]);

  function NotValidTry(e: any) {
    return e?.key != "Enter" && e?.button != 0;
  }


  function DisconnectFromDeviceWithError(err: DisconnectErrorType) {
    console.log(err);
    switch (err) {
      case "Machine": {


        navigate("/navigator/connect", { state: { errorMessage: "Machine disconnected" } })
        break;
      }
      case "Server": {

        navigate("/navigator/connect", { state: { errorMessage: "Server disconnected. Machine did not disconnect navigator mode. Please ask user to disconnect navigator mode" } })
        break;
      }
      case "NoError": {
        SendCommand("SetNavigatorDisconnect",state.serviceCode!, state.serialNumber!, "", () => {

        });

        navigate("/navigator/connect");
        break;
      }
    }

    setDeviceConnected({state : false, has_been_true : false});
    if (server !== null) {
      DeIntializeServer();

      setServer(null);
    }



  }



  if (!deviceConnected.state || !state?.serialNumber) {
    return <Grid item container xs height={"81vh"} direction={"column"} justifyContent={"center"} alignContent={"center"}>
      <Grid item xs container justifyContent={"center"}><Typography variant="button" color={"black"}>succesfully established connection to machine.. Loading settings</Typography></Grid>
      <Grid item xs container justifyContent={"center"}> <CircularProgress size={"5vmax"}></CircularProgress></Grid>
    </Grid>;
  }
  return <ClientMenuPage machineId={state.serialNumber}></ClientMenuPage>;


}
function Help({ showHelp, setShowHelp }: { showHelp: boolean; setShowHelp: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element | null {
  if (!showHelp) {
    return null;
  }
  return (
    <Grid item container sx={{ zIndex: "1", top: "10%", position: "absolute", backgroundColor: "white", borderRadius: "0.5vmax" }}>
      <IconButton
        onClick={() => {
          setShowHelp(false);
        }}
        sx={{
          position: "absolute",
          top: -7,
          right: 0,
          zIndex: 2,
        }}
      >
        <CloseIcon></CloseIcon>
      </IconButton>

      <Grid item className="shadow" container direction={"column"} alignContent={"center"} justifyContent={"center"} position={"absolute"} style={{ zIndex: "1", backgroundColor: "white", borderRadius: 20 }}>
        <Grid item>
          {" "}
          <Typography color={"black"}>Please follow these instructions to connect </Typography>
        </Grid>
        <Grid item>
          {" "}
          <Divider variant="middle"></Divider>
        </Grid>

        <List sx={{ listStyleType: "disc" }} style={{ paddingLeft: "2vmax" }}>
          <ListItem sx={{ display: "list-item" }}>
            {" "}
            <Grid item xs container justifyContent={"start"}>
              {" "}
              <Typography alignItems={"start"} textAlign={"start"} color={"black"}>
                Ask User at machine to go to service menu, then network menu{" "}
              </Typography>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            {" "}
            <Grid item xs container justifyContent={"start"}>
              <Typography color={"black"}>Ask User to go the second subscreen and click on "Connect to Navigator" </Typography>
            </Grid>{" "}
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            {" "}
            <Grid item xs container justifyContent={"start"}>
              {" "}
              <Typography color={"black"}>Ask user for device ID and Authentication Code</Typography>{" "}
            </Grid>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Grid item xs container justifyContent={"start"}>
              <Typography color={"black"}>Insert these on the connect page and click the connect button</Typography>{" "}
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}
