
import { Grid } from "@mui/material";
import { Image as ImageModel, UiElement } from "../../../Models/Menus"
import React from "react";


export default function Image({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
    return (<InnerImage image={element as ImageModel}></InnerImage>)
}

function InnerImage({ image }: { image: ImageModel }) {
    return (<Grid container justifyContent="center"><img src={"/Images/" + image.imageFile} alt={image.display_name}></img></Grid>)
}