import { useWindowDimensions } from "../../Models/dashBoardUtilities";
import moment from "moment";
import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { Bar, BarChart, Customized, Label, Legend, Line, LineChart, Rectangle, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { DatedIotData, IotData, UpTimeSpan } from "../../Models/ServerCommuncation/serverInterFace";

type ShowTime = {
  centerId: number;
  startDate: number;
  endDate: number;
};
const HoursInSeconds = 60 * 60 * 1000 * 24;
const Days = 14;

function ConvertDateToXpos(date: number, totalWidth: number): number {
  const [step, startDate, endDate] = getFullDayRange();


  let ratio = (date - startDate.valueOf()) / (endDate.valueOf() - startDate.valueOf());

  return ratio * totalWidth;
}

function ConvertDateToWidth(date: number, totalWidth: number): number {
  const [step, startDate, endDate] = getFullDayRange();

  let ratio = date / (endDate.valueOf() - startDate.valueOf());

  return totalWidth * ratio;
}


function get_tick(start: number, end: number, times: number): number[] {
  let step = (end - start) / (times - 1);
  let base = start;
  let ticks = [];
  for (let i = 0; i < times; i++) {
    ticks.push(base);
    base += step;
  }
  return ticks;
}

export default function HistoricUpTimeView(): JSX.Element {
  const Server = GetServer();

  const [UpTimeData, setUpTimeData] = React.useState<UpTimeSpan[]>([]);
  const UpTimeDataRef = React.useRef(UpTimeData);


  useEffect(() => {
    Server.GetUpTimeData((upTimeSpan) => {
      if (upTimeSpan) {
        setUpTimeData(upTimeSpan);

      }
    });
  }, []);

  return <HistoricUpTimeViewInner upTimeSpan={UpTimeData}></HistoricUpTimeViewInner>;
}


function getFullDayRange(): [number, Date, Date] {
  let current_date = new Date(Date.now() + 60 * 60 * 1000);
  current_date.setMinutes(0);
  current_date.setSeconds(0);
  let prev_date = new Date();
  prev_date.setDate(prev_date.getDate() - 1);
  prev_date.setMinutes(0);
  prev_date.setSeconds(0);
  let step = 26;

  return [step, prev_date, current_date];
}

function HistoricUpTimeViewInner({ upTimeSpan }: { upTimeSpan: UpTimeSpan[] }): JSX.Element {
  let values: ShowTime[] = upTimeSpan.map((data) => {
    return { centerId: 4, startDate: data.startDate, endDate: data.endDate };
  });


  values.sort((a, b) => a.startDate - b.startDate);

  let date = new Date().valueOf();
  let [step, start_date, end_date] = getFullDayRange();
  let ticks = get_tick(start_date.valueOf(), end_date.valueOf(), step);

  const [activeData, setActiveData] = React.useState<{ data: ShowTime; x: number; y: number; width: number } | null>(null);

  return (
    <>
      <Grid item xs container direction="column" bgcolor={"#001b54"}>
        <Grid item xs container height={"1vmax"} justifyContent={"center"}>
          <Typography>Up Time Last 24 hours</Typography>
        </Grid>

        <Grid item xs container className="shadow" justifyContent={"center"} alignContent={"center"}>
          <Grid container item xs justifyContent={"start"} alignContent={"center"} height={"11vh"}>
            <ResponsiveContainer width="100%" height={"100%"}>
              <LineChart
                data={values}
                layout="vertical"
                margin={{
                  top: 10,
                  right: 20,
                  bottom: -20,
                  left: -20,
                }}
              >
                <XAxis
                  tickLine={false}
                  axisLine={false}
                  ticks={ticks}
                  type="number"
                  dy={-40}
                  interval={0}
                  tick={{ width: "30px", height: "2vh", fontSize: "0.8em", fill: "white", dy: -30 }}
                  domain={[ticks[0], ticks[ticks.length - 1]]}
                  tickFormatter={(time, idx) => {
                    let date = new Date(time);
                    date.setMinutes(0);

                    return moment(date).format("HH:mm");
                  }}
                />
                <YAxis axisLine={false} tickLine={false} type="number" dataKey="centerId" ticks={[0, 2, 4, 6, 8]} tickFormatter={() => ""} />
                {ticks.map((tick, idx) => {
                  let now = moment(tick);
                  if (idx !== 0) {
                    let lastMoment = moment(ticks[idx - 1]);

                    if (lastMoment.days() !== now.days()) {
                      return (
                        <ReferenceLine
                          label={(props) => <DayLabel props={props} day={now.format("DD")}></DayLabel>}
                          strokeWidth={3}
                          dy={20}
                          stroke="white"
                          segment={[
                            { x: tick, y: 0 },
                            { x: tick, y: 4 },
                          ]}
                        />
                      );
                    }
                  }
                  return (
                    <ReferenceLine
                      stroke="white"
                      segment={[
                        { x: tick, y: 0 },
                        { x: tick, y: 4 },
                      ]}
                    />
                  );
                })}

                <ReferenceLine stroke="white" y={4} />

                <Line></Line>
                <Customized component={(props) => <ErrorBlocks props={props} setActiveData={setActiveData}></ErrorBlocks>} />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Grid>
      {activeData !== null ? <GenerateToolTipBox showTimeData={activeData}></GenerateToolTipBox> : null}
    </>
  );
}

// using Customized gives you access to all relevant chart props
function ErrorBlocks(props: any, setActiveData: (data: { data: ShowTime; x: number; y: number; width: number } | null) => void) {
  // get first and second series in chart
  // render custom content using points from the graph

  return <GenerateRecTangles otherprops={props}></GenerateRecTangles>;
}

function DayLabel({ props, day }: { props: any; day: string }) {
  const { x, y, stroke, viewBox } = props;

  return (
    <text x={viewBox.x - 7.5} y={viewBox.height + 55} fill={"white"}  fontSize={15}>
      {day}
    </text>
  );
}

function GenerateToolTipBox({ showTimeData }: { showTimeData: { data: ShowTime; x: number; y: number; width: number } }): JSX.Element {
  const { data, x, y, width } = showTimeData;
  const dim = useWindowDimensions();
  return (
    <Grid container direction={"column"} rowGap={1} sx={{ position: "fixed", zIndex: 200, top: dim.height - dim.height * 0.1 - y - y * (11 / 12), right: dim.width - dim.width * 0.2 - x + 300 }} maxWidth={"20%"}>
      <Grid item container>
        <Grid item xs container bgcolor={"black"} sx={{ opacity: 0.8 }} borderRadius={"0.5vw"}>
          <Grid item xs={6}>
            <Typography color={"white"}> {"start date: "} </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color={"white"}>{moment(data.startDate).format("HH:mm")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs container bgcolor={"black"} sx={{ opacity: 0.8 }} borderRadius={"0.5vw"}>
        <Grid item xs={6}>
          <Typography color={"white"}> {"end date: "} </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography color={"white"}>{moment(data.endDate).format("HH:mm")}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function GenerateRecTangles({ otherprops }: { otherprops: any }): JSX.Element {
  const { props, setActiveData } = otherprops;

  const { data, tooltipTicks, xAxisMap, offset } = props;
  console.log(data);

  return data.map((data: ShowTime, index: number) => {
    const { startDate, endDate } = data;
    const { width, right } = offset;
    let rectangleWidth = ConvertDateToWidth(endDate - startDate, xAxisMap["0"].width);
    let xPos = ConvertDateToXpos(startDate, xAxisMap["0"].width);

    let [x, y] = [xPos + xAxisMap["0"].x, tooltipTicks["0"].coordinate - 20 / 2];
    return (
      <Rectangle
        width={rectangleWidth}
        height={20}
        x={x}
        style={{
          opacity: 0.8,
        }}
        onClick={() => {
          setActiveData((prev: { data: ShowTime; x: number; y: number; width: number } | null) => {
            if (prev === null) {
              return { data: data, width: rectangleWidth, x: x, y: y };
            }
            return null;
          });
        }}
        y={y}
        fill={"lightblue"}
      />
    );
  });
}
