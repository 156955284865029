import { createReducer, current } from '@reduxjs/toolkit';
import
{
  setErrorLog,
  filterErrorLog,
  resetErrorLog,
  setAllFilter,
  countErrorsInFilter,
  resetCount,
  selectError,
  selectDeselectAll,
  resetToInitialErrorFilter,
  selectAllError,
  deselectAllError,
  selectColor,
  setStartDate,
  setEndDate,
  setIsStartDatePicked,
  setMarkedDates,
  resetFilters,
} from '../actions/errorLogActions';
import
{
  decryptErrorCodes,
  decryptWarningCodes,
} from '../../../utils/MachineErrorsConverter';
import moment from 'moment';
const initialState = {
  chartErrors: [],
  //--- Calendar Filter ----//
  startDate: '',
  endDate: '',
  errorsMark: {},
  isStartDatePicked: false,
  markedDates: {},
  //--initial calendar
  initialStartDate: '',
  initialEndDate: '',
  initialIsStartDatePicked: false,
  initialMarkedDates: {},
  //--------//
  isLoading: true,
  allChecked: 'checked',
  errorLog: [],
  errors: [],
  filteredErrors: [],
  initialErrorLog: [],
  initialErrorFilter: [],
  initialAllErrors: [],
  filterUsed: { calendarUsed: false, errorsTypeUsed: false },
  errorFilter: [
    {
      count: 0,
      color: 'black',
      errorname: 'Motor temperature high',
      errorCodeName: 'Pt1HighTempBit',
      description: 'Motor temperature high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Water temperature high',
      errorCodeName: 'Pt2HighTempBit',
      description: 'Water temperature high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Pt3HighTempBit',
      description: 'Default not used',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Pt1LowTempBit',
      description: 'Default not used',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Water temperature low',
      errorCodeName: 'Pt2LowTempBit',
      description: 'Water temperature low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Pt3LowTempBit',
      description: 'Default not used',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Tank empty (Inverter)',
      errorCodeName: 'TankControlLow',
      description:
        'Low water level in tank, used when function tank control is enabled. And in this case buffertank is below critical level',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Suction Lance 1, empty (Inverter)',
      errorCodeName: 'SuctionLance1Empty',
      description:
        'Detection of chemical level in can 1, triggered when level is critical low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Suction Lance 2, empty (Inverter)',
      errorCodeName: 'SuctionLance2Empty',
      description:
        'Detection of chemical level in can 2, triggered when level is critical low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'No Communication',
      errorCodeName: 'MbAliveError',
      description:
        'No communication from Modbus, when not receiving signals from controller for a period of time',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Low Supply pressure',
      errorCodeName: 'P1_PresssureLowBit',
      description: 'Inlet pressure critical low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Low Sensor Signal inlet sensor',
      errorCodeName: 'P1_SignalLowBit',
      description: 'No or low signal from inlet sensor',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Sensor Load high Inlet Sensor',
      errorCodeName: 'P1_HighLoad',
      description: 'Inlet Sensor Load high, Current to sensor high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Inlet sensor load low',
      errorCodeName: 'P1_LowLoad',
      description: 'Inlet Sensor Load low, Current to sensor low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'P2_PresssureLowBit',
      description: 'Outlet pressure critical low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Low Sensor Signal outlet sensor',
      errorCodeName: 'P2_SignalLowBit',
      description: 'No or low signal from Outlet sensor',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Sensor Load high Outlet Sensor',
      errorCodeName: 'P2_HighLoad',
      description: 'Outlet Sensor Load high, Current to sensor high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Outlet sensor load low',
      errorCodeName: 'P2_LowLoad',
      description: 'Outlet Sensor Load low, Current to sensor low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'P3_PresssureLowBit',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'P3_SignalLowBit',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'P3_HighLoad',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'P3_LowLoad',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Flowswitch using too much current',
      errorCodeName: 'Flow_HighLoad',
      description: 'Flow switch Load high, Current to flow switch high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Flowswitch Load low',
      errorCodeName: 'Flow_LowLoad',
      description: 'Flow switch Load low, Current to flow switch low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Current phase 1',
      errorCodeName: 'currErrPhase1',
      description: 'Instantaneous current on motor phase 1 has been too high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Current phase 2',
      errorCodeName: 'currErrPhase2',
      description: 'Instantaneous current on motor phase 2 has been too high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Current phase 3',
      errorCodeName: 'currErrPhase3',
      description: 'Instantaneous current on motor phase 3 has been too high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Instantaneous motor sum current has been too high',
      errorCodeName: 'sumCurrErr',
      description: 'Instantaneous motor sum current has been too high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Supply low. Weak supply',
      errorCodeName: 'dcLowVoltErr',
      description: 'DC Link voltage has been too low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Supply too high',
      errorCodeName: 'HighVoltErr',
      description: 'DC Link voltage has been too high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Current too high',
      errorCodeName: 'hwOverCurrErr',
      description:
        'Inverter hardware has detected over-current one of the phases',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Safe Torque off',
      errorCodeName: 'safeTorqueOff',
      description: 'Safe torque off digital input has be activated',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Inverter Module Temperature too high',
      errorCodeName: 'moduleTempToHigh',
      description: 'IGBT temperature has been too high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'moduleTempHigh (not shown)',
      description: 'not used',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Powerlimit (not shown)',
      description: 'not used',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      errorname: 'Serial no. or Rev. no. not correct',
      errorCodeName: 'hwRevError',
      description: 'not used',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Inlet Pressure Low',
      warningCodeName: 'P1_LowWarningBit',
      description: 'Low inlet pressure',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'P1_HighLimitUpBit',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'P1_HighLimitLowBit',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Not used',
      description: 'Not used',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'P2_HighLimitUpBit',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'P2_HighLimitLowBit',
      description: 'Outlet pressure low',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'P3_LowWarningBit',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'P3_HighLimitUpBit',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'P3_HighLimitLowBit',
      description: '-',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'High Tank Level (Inverter)',
      warningCodeName: 'TankControlHigh',
      description:
        'High water level in tank, used when function tank control is enabled. And in this case buffertank is above critical level',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Suction Lance 1, Low (Inverter)',
      warningCodeName: 'SuctionLance1Low',
      description:
        'Detection of chemical level in can 1, triggered when level is low. Two levels this is first level, second level is error 8. correspoding to "almost" empty and "complete" empty',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Suction Lance 2, low (Inverter)',
      warningCodeName: 'SuctionLance2Low',
      description:
        'Detection of chemical level in can 2, triggered when level is low. Two levels this is first level, second level is error 9. correspoding to "almost" empty and "complete" empty',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Inverter Module Temperature High',
      warningCodeName: 'ModuleHighTempBit',
      description: 'Inverter IGBT module temperature high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Inverter Board Temperature High',
      warningCodeName: 'BoardHighTempBit',
      description: 'Inverter controller temperature high',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Powerlimit',
      description: 'Max power for inverter, power limit activated',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Leakage start',
      warningCodeName: 'Leakage start',
      description: 'Disables pressure start, until flow has been present',
      selected: true,
    },
    {
      count: 0,
      color: 'black',
      warningname: 'Critical inlet pressure',
      warningCodeName: 'Dry_run_reset',
      description: '-',
      selected: true,
    },
  ],
};

const errorLogReducer = createReducer(initialState, (builder) =>
{
  builder
    .addCase(resetErrorLog, (state, action) =>
    {
      return initialState;
    })
    .addCase(setErrorLog, (state, action) =>
    {
      state.initialErrorFilter = state.errorFilter;
      var month = new Array();
      month[0] = 'January';
      month[1] = 'February';
      month[2] = 'March';
      month[3] = 'April';
      month[4] = 'May';
      month[5] = 'June';
      month[6] = 'July';
      month[7] = 'August';
      month[8] = 'September';
      month[9] = 'October';
      month[10] = 'November';
      month[11] = 'December';

      var datesFiltered = [];
      var array = action.payload.errorLog;
      
      for (var i = 1; i < array.length; i++)
      {
        var date = array[i].date;
        var ErrorCode1 = array[i].ErrorCode1;
        var ErrorCode2 = array[i].ErrorCode2;
        var ErrorCode3 = array[i].ErrorCode3;
        var WarningCode1 = array[i].WarningCode1;
        var WarningCode2 = array[i].WarningCode2;
        var errorsList = decryptErrorCodes(ErrorCode1, ErrorCode2, ErrorCode3);
        var warningsList = decryptWarningCodes(WarningCode1, WarningCode2);

        var indexOfExistingDate = datesFiltered.findIndex((elem) =>
        {
          return elem.date == date;
        });

        if (indexOfExistingDate == -1)
        {
          var newError = {
            date: date,
            errors: errorsList.concat(warningsList)
          }
          datesFiltered.push(newError)
        } else
        {
          datesFiltered[indexOfExistingDate].errors.push(errorsList.concat(warningsList))
        }

      }
      
      state.errorLog = datesFiltered;
      state.isLoading = false;
    })
});

export default errorLogReducer;
