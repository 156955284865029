import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MdCastConnected, MdOutlineCast } from "react-icons/md";
import { LuFactory } from "react-icons/lu";
import { LuHome } from "react-icons/lu";

import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogo.png";
import logo2 from "shared/utils/images/logo2.png";
import { Link } from "react-router-dom";
import { } from "react-router-dom";
import { useSelector } from "react-redux";
import { userService } from "shared/services/authentication.service";
import { HiOutlineHome } from "react-icons/hi";
import { useLocation } from "react-router-dom";

// const teams = [
//   { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
//   { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
//   { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
// ];

// const navigation = [
// { name: "Dashboard", href: "#", icon: HomeIcon, current: true },
// { name: "Home", url: "/home", icon: HomeIcon },
// { name: "Factories", url: "/factories", icon: HomeIcon },
// { name: "Machines", url: "/machines", icon: UsersIcon },
// { name: "Projects", href: "#", icon: FolderIcon, current: false },
// { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
// { name: "Documents", href: "#", icon: DocumentDuplicateIcon, current: false },
// { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
// ]

const authorizedNavigation = [
  { name: "Home", url: "/home", icon: HomeIcon },
  // { name: "Factories", url: "/factories", icon: LuFactory },
  { name: "Navigator Tool", url: "/navigator/connect", icon: MdOutlineCast },
];

const unauthorizedNavigation = [
  { name: "Home", url: "/home", icon: HomeIcon },
  { name: "Factories", url: "/factories", icon: LuFactory },
  { name: "Navigator Tool", url: "/navigator/connect", icon: MdOutlineCast },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SidebarNavBranded({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const myAccount = useSelector((state) => state.user.user);

  const isAdminOrTechnician =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin" || myAccount.Role.name === "Technician");

  const navigation = (isAdminOrTechnician ? authorizedNavigation : unauthorizedNavigation).map((item) => ({
    ...item,
    current: location.pathname.includes(item.url),
  }));

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="h-8 w-auto" src={nilfiskFoodLogo} alt="Your Company" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.url}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-50 text-blue-500"
                                    : "text-gray-700 hover:text-blue-500 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current ? "text-blue-500" : "text-gray-200 group-hover:text-blue-500",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        {/* <div className="text-xs font-semibold leading-6 text-gray-200">Your teams</div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {teams.map((team) => (
                            <li key={team.name}>
                              <a
                                href={team.href}
                                className={classNames(
                                  team.current
                                    ? "bg-gray-50 text-blue-500"
                                    : "text-gray-700 hover:text-blue-500 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <span
                                  className={classNames(
                                    team.current
                                      ? "text-blue-500 border-blue-500"
                                      : "text-gray-200 border-gray-200 group-hover:border-blue-500 group-hover:text-blue-500",
                                    "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                  )}
                                >
                                  {team.initial}
                                </span>
                                <span className="truncate">{team.name}</span>
                              </a>
                            </li>
                          ))}
                        </ul> */}
                      </li>
                      <li className="mt-auto">
                        <Link
                          to={"/settings"}
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-blue-500"
                        >
                          <Cog6ToothIcon
                            className="h-6 w-6 shrink-0 text-gray-200 group-hover:text-blue-500"
                            aria-hidden="true"
                          />
                          Settings
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-nilfisk px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img className="h-8 w-auto filter grayscale brightness-100" src={logo2} alt="Your Company" />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.url}
                        className={classNames(
                          item.current ? "bg-blue-500 text-white" : "text-gray-200 hover:text-white hover:bg-blue-400",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                {/* <div className="text-xs font-semibold leading-6 text-gray-200">Your teams</div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {teams.map((team) => (
                    <li key={team.name}>
                      <a
                        href={team.href}
                        className={classNames(
                          team.current ? "bg-gray-800 text-white" : "text-gray-200 hover:text-white hover:bg-gray-800",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-200 group-hover:text-white">
                          {team.initial}
                        </span>
                        <span className="truncate">{team.name}</span>
                      </a>
                    </li>
                  ))}
                </ul> */}
              </li>
              <li className="mt-auto">
                <Link
                  to={"/settings/general"}
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-200 hover:bg-blue-400 hover:text-white"
                >
                  <Cog6ToothIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                  Settings
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
