import React, { useEffect, useState } from "react";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Provider, Translate, Translator } from "react-translated";
import { getOwnFactoryById, getFactoryById } from "shared/services/factory.service";

import { styled } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  CardHeader,
  Typography,
  Divider,
  Button,
  Grid,
  CircularProgress,
  LinearProgress,
  Breadcrumbs,
  Paper,
  Card,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MaterialTable from "@material-table/core";
import Link from "@mui/material/Link";
import { getAllEmployeesOfAFactory, getAllUsersOfAFactory } from "shared/services/factoryUser.service";
// import { Link as RouterLink, Route, MemoryRounter } from 'react-router-dom';
// import Paper from '@mui/material/Paper';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Refresh,
  DeviceHub,
  Visibility,
} from "@mui/icons-material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";

import { useParams, Navigate } from "react-router-dom";
import { Link as RouterLink, Route, MemoryRouter } from "react-router-dom";

import { Select, MenuItem } from "@mui/material";
//Tabs
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";

import {
  getUserById,
  getOwnEmployeeByUserId,
  deleteUserAccount,
  deleteUser,
  deactivateUserAccount,
  deactivateUserAccountOwn,
  deleteUserAccountOwn,
  activateUserAccountOwn,
  activateUserAccount,
  banUserOwn,
  banUser,
  unbanUserOwn,
  unbanUser,
} from "shared/services/user.service";

import SendIcon from "@mui/icons-material/Send";
import EmailIcon from "@mui/icons-material/Email";
import BlockIcon from "@mui/icons-material/Block";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function ViewUser(props) {
  let navigate = useNavigate();
  const myAccount = useSelector((state) => state.user.user);

  let params = useParams();
  const [comesFromFactoryScreen, setComesFromFactoryScreen] = useState(false);
  const [factoryId, setFactoryId] = useState(null);
  const [factoryName, setFactoryName] = useState(null);

  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("");

  const [language, setLanguage] = useState("");
  const [account_confirmed, setAccount_confirmed] = useState("");
  const [tutorialFinished, setTutorialFinished] = useState("");
  const [allowDataProcessing, setAllowDataProcessing] = useState("");

  const [bannedAt, setBannedAt] = useState("");
  const [isBanned, setIsBanned] = useState(false);
  const [banReason, setBanReason] = useState("");

  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [deletedAt, setDeletedAt] = useState("");
  const [accountActive, setAccountActive] = useState(true);

  const [factories, setFactories] = useState([]);
  const [factoryLink, setFactoryLink] = useState("");
  const [factoryData, setFactoryData] = useState({});
  const [redirectFactory, setRedirectFactory] = useState(false);

  const [selectedSendEmail, setSelectedSendEmail] = useState(1);

  const [loading, setLoading] = useState(true);

  const [redirectEditUser, setRedirectEditUser] = useState(false);

  const [tabNo, setTabNo] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setTabNo(newValue);
  };

  useEffect(() => {
    getUserById(params.userId)
      .then((res) => {
        console.log("user", res);
        const user = res.data.user[0];
        setId(user.id);
        setStatus(user.status);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setUserName(user.username);
        setEmail(user.email);
        setPhone(user.phone);
        setAddress(user.address);
        setPosition(user.position);
        setRole(user.Role.name);

        setLanguage(user.language);
        setAccount_confirmed(user.account_confirmed);
        setTutorialFinished(user.tutorialFinished);
        setAllowDataProcessing(user.allowDataProcessing);

        setBannedAt(user.bannedAt ? moment(user.bannedAt).format("YYYY-MM-DD HH:mm:ss") : "-");
        setBanReason(user.banReason);
        setIsBanned(user.bannedAt ? true : false);

        setCreatedAt(moment(user.createdAt).format("YYYY-MM-DD HH:mm:ss"));
        setUpdatedAt(moment(user.updatedAt).format("YYYY-MM-DD HH:mm:ss"));
        setDeletedAt(user.deletedAt ? moment(user.deletedAt).format("YYYY-MM-DD HH:mm:ss") : "-");
        setAccountActive(user.deletedAt ? false : true);

        console.log("user", user);
        setFactories(user.Factories);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        navigate(-1);
      });
  }, []);

  const onFactoryRowClickEdit = (event, rowData) => {
    console.log(rowData);
    setFactoryLink("/factories/" + rowData.id + "/edit");
    setFactoryData(rowData);
    setRedirectFactory(true);
  };
  const onFactoryRowClick = (event, rowData) => {
    console.log(rowData);
    setFactoryLink("/factory/view/" + rowData.id + "/details");
    setFactoryData(rowData);
    setRedirectFactory(true);
  };

  if (redirectFactory == true)
    return (
      <Navigate
        push
        to={{
          pathname: factoryLink,
          state: { factoryData: { factoryName: factoryName, factoryId: factoryId } },
        }}
      />
    );
  if (redirectEditUser == true)
    return (
      <Navigate
        push
        to={{
          pathname: "/Factory/" + factoryId + "/User/" + params.userId + "/edit",
          state: { factoryData: { factoryName: factoryName, factoryId: factoryId } },
        }}
      />
    );

  return (
    <TabContext value={tabNo}>
      <div className={"flex-grow"}>
        {console.log("factoryname", factoryName)}

        <Grid container justifyContent="space-between">
          <Grid item>
            {loading ? (
              <Typography variant="h5">Fetching data...</Typography>
            ) : (
              <Typography variant="h5">
                {firstName} {lastName}
              </Typography>
            )}
            <Breadcrumbs aria-label="breadcrumb">
              <LinkRouter underline="hover" color="inherit" to="/Home">
                Home
              </LinkRouter>

              {comesFromFactoryScreen ? (
                <LinkRouter underline="hover" color="inherit" to={"/factories"}>
                  Factories
                </LinkRouter>
              ) : null}

              {comesFromFactoryScreen ? (
                <LinkRouter underline="hover" color="inherit" to={"/factory/view/" + factoryId + "/users"}>
                  {factoryName}
                </LinkRouter>
              ) : null}

              {!comesFromFactoryScreen ? (
                <LinkRouter underline="hover" color="inherit" to="/Users">
                  Users
                </LinkRouter>
              ) : null}

              <Typography color="text.primary">User</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ marginTop: 10, marginBottom: 20 }}
              startIcon={<EditIcon />}
              onClick={() => setRedirectEditUser(true)}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Details" value="1" />
            <Tab label="Factories" value="2" />
            {/* <Tab label="Machines" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={12} md={6} style={{}}>
              <Card variant="outlined" style={{ borderRadius: 20, margin: 5 }}>
                <CardHeader title={"Details"} />
                <Divider />
                <TableContainer>
                  <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography>Email</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <LinearProgress />
                          ) : (
                            <Stack>
                              <Typography color={"#6b778c"}>{email}</Typography>
                              {account_confirmed ? (
                                <Chip size="small" label="Email verified" color="success" style={{ width: 150 }} />
                              ) : (
                                <Chip
                                  size="small"
                                  label="Email not verified"
                                  color="warning"
                                  style={{
                                    width: 150,
                                  }}
                                />
                              )}
                            </Stack>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>First Name</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <LinearProgress style={{ minWidth: 70 }} />
                          ) : (
                            <Typography color={"#6b778c"}>{firstName}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Last Name</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{lastName} </Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Username</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{username}</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Phone</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{phone}</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Address</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{address}</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Position</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{position}</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Role</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{role}</Typography>}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{}}>
              <Card variant="outlined" style={{ borderRadius: 20, margin: 5 }}>
                <CardHeader title={"User Status"} />
                <Divider />
                <TableContainer>
                  <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography>Language </Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <LinearProgress style={{ minWidth: 70 }} />
                          ) : (
                            <Typography color={"#6b778c"}>{language}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Account Confirmed</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <LinearProgress />
                          ) : (
                            <Typography color={"#6b778c"}>{account_confirmed ? "Yes" : "No"} </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Tutorial Finished</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <LinearProgress />
                          ) : (
                            <Typography color={"#6b778c"}>{tutorialFinished ? "Yes" : "No"}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Allow Data Processing</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <LinearProgress />
                          ) : (
                            <Typography color={"#6b778c"}>{allowDataProcessing ? "Yes" : "No"}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Ban Date</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{bannedAt}</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Ban Reason</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{banReason}</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Created At</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{createdAt}</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Updated At</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? <LinearProgress /> : <Typography color={"#6b778c"}>{updatedAt}</Typography>}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>Status</Typography>
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <LinearProgress />
                          ) : accountActive ? (
                            <Chip size="small" label="Active" color="success" />
                          ) : (
                            <Chip size="small" label="Inactive" color="warning" />
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={12} md={6} style={{}}>
              {/* <Card variant="outlined" style={{ borderRadius: 20, margin: 5 }}>
										<CardHeader title={"Emails"} />
										<Divider />
										<Grid container style={{ padding: 10 }}>
											<Select
												id="send-email"
												value={selectedSendEmail}
												onChange={(event) => setSelectedSendEmail(event.target.value)}
												style={{ width: '100%' }}
											>
												<MenuItem value={1}>Send verification</MenuItem>
												<MenuItem value={2}>Send password reset</MenuItem>
											</Select>
											<Button variant="contained" style={{ marginTop: 10, marginBottom: 20 }} startIcon={<EmailIcon />} onClick={() => alert("Send Email")}>
												Send email
       			 							</Button>
										</Grid>


										<TableContainer >
											<Table sx={{ minWidth: 300 }} aria-label="simple table">

												<TableBody>
													<TableRow>
														<TableCell><Typography > Send password reset</Typography></TableCell>
														<TableCell><Typography > 01/15/2021 | 12:15 </Typography></TableCell>
													</TableRow>
													<TableRow>
														<TableCell><Typography > Send Verification</Typography></TableCell>
														<TableCell><Typography > 01/12/2021 | 14:15 </Typography></TableCell>
													</TableRow>
													<TableRow>
														<TableCell><Typography > Send Verification</Typography></TableCell>
														<TableCell><Typography > 01/11/2021 | 02:15 </Typography></TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</Card> */}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="text"
                style={{ marginBottom: 10, marginLeft: 5, textTransform: "none" }}
                startIcon={<AddIcon />}
              >
                Add Factory
              </Button>
              <Button
                variant="text"
                style={{ marginBottom: 10, marginLeft: 10, textTransform: "none" }}
                startIcon={<RemoveIcon />}
              >
                Remove Factory
              </Button>
              <Card variant="outlined" style={{ borderRadius: 20, margin: 5 }}>
                {/* <CardHeader title={"Factories"} /> */}
                {/* <Divider /> */}
                <MaterialTable
                  // style={{margin: 10}}
                  icons={tableIcons}
                  title=""
                  onRowClick={onFactoryRowClick}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  options={{
                    search: false,
                    toolbar: false,
                    actionsColumnIndex: -1,
                  }}
                  columns={[
                    { title: "Name", field: "name" },
                    { title: "CVR", field: "CVR" },
                    { title: "Address", field: "address" },
                    { title: "City", field: "city" },
                    { title: "Country", field: "country" },
                    {
                      title: "Notifications",
                      field: "FactoryUser.receiveNotification",
                      lookup: { true: "Yes", false: "No" },
                    },
                    {
                      title: "Owner",
                      field: "FactoryUser.owner",
                      lookup: { true: "Yes", false: "No" },
                    },
                    {
                      title: "Employee",
                      field: "FactoryUser.employee",
                      lookup: { true: "Yes", false: "No" },
                    },
                  ]}
                  data={factories}
                  actions={[
                    {
                      icon: tableIcons.Visibility,
                      tooltip: "View Factory",
                      onClick: onFactoryRowClick,
                    },
                    {
                      icon: tableIcons.Edit,
                      tooltip: "Edit Factory",
                      onClick: onFactoryRowClickEdit,
                    },
                  ]}
                />
              </Card>
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </TabContext>
  );
}

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
};
