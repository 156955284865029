import React, { useEffect, PropTypes } from "react";

import { Card, Switch, Typography, Grid, Tooltip as MuiTooltip } from "@mui/material";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { getLast10MinutesOfDataByDeviceId } from "shared/services/device.service";
import { ping } from "shared/services/cloudToDevice.service";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from "recharts";
import _ from "lodash";
import { green, red } from "@mui/material/colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Skeleton from "@mui/lab/Skeleton";
import {
  initiateSocket,
  disconnectSocket,
  getDeviceData,
  getSocket,
  joinRoom,
  leaveRoom,
} from "shared/services/socket.service";
import IconButton from "@mui/material/IconButton";
import { useTelemetryDataQuery } from "hooks/machine";
import Chart from "react-apexcharts";
var format = require("date-format");

export default function LineChartNew(props) {
  const { machineData } = props;
  const [usedWidget, setUsedWidget] = React.useState(props.machineData);

  const [data, setData] = React.useState([]);
  const [startIndex, setStartIndex] = React.useState(0);
  const [endIndex, setEndIndex] = React.useState(1000);
  const [MachineStandby, setMachineStandby] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const [isOnline, setIsOnline] = React.useState(null);
  const [creating, setCreating] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  const [noDataOrFound, setNoDataOrFound] = React.useState(false);

  const [stopTelemetry, setStopTelemetry] = React.useState(false);
  const [stoppedData, setStoppedData] = React.useState([]);
  const [pressureinData, setPressureinData] = React.useState([]);
  const [pressureoutData, setPressureoutData] = React.useState([]);

  const [state, setState] = React.useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        max: 30,
        forceNiceScale: true,
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
  });
  const {
    data: telemetryData,
    isLoading: machineLoading,
    error: machineError,
  } = useTelemetryDataQuery(machineData?.serialNumber);

  console.log("telemetryData", telemetryData);

  useEffect(() => {
    if (telemetryData) {
      const data = telemetryData?.data?.device;

      setData(data ?? []);

      const pressureinData = data.map((message) => {
        return {
          x: message.EventEnqueuedUtcTime,
          y: message.PressureIN,
        };
      });
      console.log("pressureinData", pressureinData);
      setPressureinData([
        {
          name: "PressureIN",
          data: pressureinData,
        },
      ]);

      const pressureoutData = data.map((message) => {
        return {
          x: message.EventEnqueuedUtcTime,
          y: message.PressureOUT,
        };
      });
      console.log("pressureoutData", pressureoutData);
      setPressureoutData([
        {
          name: "PressureOUT",
          data: pressureoutData,
        },
      ]);

      setLoading(false);
    }
  }, [telemetryData]);

  useEffect(() => {
    initiateSocket(usedWidget?.serialNumber);

    let socket = getSocket();

    socket.on("telemetry-" + usedWidget?.serialNumber, (data) => {
      const messageData = JSON.parse(data);
      let device = messageData.IotData;
      var flowSwitch = 0;
      if (device.FlowSw > 1000) {
        flowSwitch = 10;
      }

      device = {
        ...device,
        EventEnqueuedUtcTime: messageData.MessageDate,
        Inverter_loadPowP: device.Inverter_loadPowP / 1000,
        FlowSw: flowSwitch,
      };
      console.log("device", device);
    });
  }, []);

  // useEffect(() => {
  //   const { widget, create } = props;
  //   var initData = [];

  //   if (create == true) setCreating(true);
  //   console.log("widget", machineData);
  //   if (machineData.Machine != null)
  //     getLast10MinutesOfDataByDeviceId(widget.Machine.deviceId)
  //       .then(
  //         (
  //           res //returning last 10 minutes of data
  //         ) => {
  //           console.log("res telemetry data", res);

  //           if (typeof res.data.device == "undefined") setNoDataOrFound(true);
  //           else {
  //             setUsedWidget(widget);
  //             var data = res?.data?.device;
  //             if (data != null)
  //               data.forEach((message) => {
  //                 var pressureIn = (message.PressureIN * 1.0) / 100;
  //                 message.PressureIN = pressureIn;
  //               });
  //             setData(data ?? []);
  //             // setData(res?.data?.device ?? []);
  //             ping(widget.Machine.ConnectionDeviceId).then((res) => {
  //               console.log("Connection", res);
  //               setIsOnline(res.data.connection);
  //             });
  //           }

  //           setLoading(false);
  //           // console.log("returned data", res.data)
  //           return res.data.device;
  //         }
  //       )
  //       .then((newData) => {
  //         initData = newData ?? [];
  //         if (initData.length > 0) {
  //           setLoaded(true);
  //         }
  //         setEndIndex(initData.length ?? 0);

  //         initiateSocket(widget.Machine.deviceId);
  //         // joinRoom("3000000009")

  //         let socket = getSocket();
  //         socket.on("connect_error", () => {
  //           console.log("Connection Error socket");
  //         });
  //         socket.on("disconnect", () => {
  //           console.log("Disconnected socket");
  //           setTimeout(() => {
  //             socket.connect();
  //           }, 2000);
  //         });

  //         socket.on("connect", function () {
  //           // joinRoom(widget.Machine.deviceId)
  //         });
  //         socket.on("reconnect", () => {
  //           console.log("Trying to reconnect socket.io");
  //           // joinRoom(widget.MainStation.deviceId)
  //         });
  //         socket.on("telemetry-" + widget.Machine.deviceId, (data) => {
  //           // tempConnected = true;
  //           const messageData = JSON.parse(data);
  //           let device = messageData.IotData;

  //           var flowSwitch = 0;
  //           if (device.FlowSw > 1000) {
  //             flowSwitch = 10;
  //           }

  //           //the last 2 digits from pressure in and out are the decimal point so we need to add it back to the number
  //           var pressureIn = device.PressureIn + 0.15;
  //           var pressureOut = device.PressureOut + 0.25;
  //           // var pressureInDecimal = pressureIn % 100;
  //           // var pressureOutDecimal = pressureOut % 100;
  //           // pressureIn = (pressureIn - pressureInDecimal) / 100 + pressureInDecimal / 100;
  //           // pressureOut = (pressureOut - pressureOutDecimal) / 100 + pressureOutDecimal / 100;
  //           device.PressureIn = pressureIn;
  //           device.PressureOut = pressureOut;

  //           device = {
  //             ...device,
  //             EventEnqueuedUtcTime: messageData.MessageDate,
  //             Inverter_loadPowP: device.Inverter_loadPowP / 1000,
  //             FlowSw: flowSwitch,
  //           };
  //           console.log("device", device);
  //           if (data.length >= 270) setData((prevState) => [...prevState.slice(1), device]);
  //           //removing old telemetry data once a new one is added
  //           else setData((prevState) => [...prevState, device]); //removing old telemetry data once a new one is added

  //           setMachineStandby(device.MachineStandby);

  //           // setLoaded(true)
  //           // setConnected(true)
  //           // setCouldNotLoad(false);
  //         });
  //       });

  //   return () => {
  //     setNoDataOrFound(false);
  //     if (widget.Machine != null) {
  //       setData([]);
  //       leaveRoom(widget.Machine.deviceId);
  //       setLoading(true);
  //     }
  //   };
  // }, [props.widget.Machine]);

  // useEffect(() => {
  //   const { widget } = props;
  //   setUsedWidget(widget);
  // }, [props.widget]);

  // useEffect(() => {
  //   if (props.stopTelemetry == false) {
  //     setStoppedData(data);
  //   }
  // }, [props.stopTelemetry, data]);

  // layout is an array of objects, see the demo for more complete usage
  const COLORS = [
    "#1abc9c",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#f1c40f",
    "#e74c3c",
    "#7f8c8d",
    "#107C10",
    "#E81123",
    "#FF8C00",
    "#0099BC",
  ];
  const tickFormatter = (tick) => format("hh:mm:ss", new Date(tick));

  return (
    <div style={{}}>
      {loading ? (
        <Skeleton variant="rect" animation="wave" />
      ) : noDataOrFound ? (
        <div>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Typography>
              We could not receive data from the machines <br />
              The machine might be turned off or the internet connection is lost.
            </Typography>
          </Grid>
        </div>
      ) : (
        <div>
          <div id="chart">
            <Chart options={state.options} series={pressureinData.concat(pressureoutData)} type="line" height={350} />
          </div>
          <div id="html-dist"></div>
        </div>
      )}
    </div>
  );
}

function CustomLabel({ x, y, stroke, value, width }) {
  if (value) {
    // No label if there is a value. Let the cell handle it.
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      // Move slightly above axis
      dy={-10}
      // Center text
      dx={width / 2}
      fill={stroke}
      fontSize={15}
      textAnchor="middle"
    >
      N/A
    </text>
  );
}
