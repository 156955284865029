// implement the go back button

import React from "react";
import { GetServer } from "../../../Models/ServerCommuncation/Server";



export default function GoBackButton({ menuNo, sendUpdate, disabled }: { menuNo: number, sendUpdate?: () => void, disabled: boolean }): JSX.Element {
    const Server = GetServer();
    if (menuNo === 3) {
        return <></>
    }
    function goBack() {
        if (disabled) {
            return;
        }
        Server.SendCommand("SetNavigatorButton", "GoBack", () => { });
        sendUpdate?.();
    }
    return <img onClick={goBack} src={"/Images/GoBackButton.png"} style={{ cursor: disabled ? "default" : "pointer", right: 0, maxHeight: "2.5vmax", maxWidth: "2.5vmax" }} alt="go back">

    </img>
}