import axios from "axios";
import { useQuery } from "@tanstack/react-query";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

//ANCHOR Create Machine
export const createMachine = (newMachine) => {
  return axios.post("https://api.nilfiskfood.com/api/machines/create", newMachine, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR Get all machines
export const getAllMachines = () => {
  return axios.get("https://api.nilfiskfood.com/api/machines", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR Get performance data
export const getPerformanceDataOfOwnMachine = (MachineId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/performance/own/" + MachineId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR Get performance data
export const getSessionDataOfOwnMachine = (MachineId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/session/own/" + MachineId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR Get machine by company id
export const getMachinesByCompanyId = (CompanyId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/company/" + CompanyId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const useGetOwnMachinesByFactoryId = (FactoryId) => {
  return useQuery({
    queryKey: ["machines", FactoryId],
    queryFn: async () => {
      const token = JSON.parse(localStorage.getItem("token")).token;
      const response = await axios.get(`https://api.nilfiskfood.com/api/machines/factory/own/${FactoryId}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    },
  });
};

//ANCHOR Get own machines by factory id
export const getOwnMachinesByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/factory/own/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR Get own machine by CompanyId
export const getOwnMachinesByCompanyId = (CompanyId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/company/own/" + CompanyId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR Get the number of machines by company id
export const getNoOfMachinesByCompanyId = (CompanyId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/number/company/" + CompanyId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR Get the number of machines by company id
export const getNoOfMachinesByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/number/factory/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR Get Machine by device id
export const getMachineByDeviceId = (DeviceId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/deviceId/" + DeviceId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR Get machine by id
export const getMachineById = (MachineId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/" + MachineId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR get Own Machine By Id
export const getOwnMachineById = (MachineId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/own/" + MachineId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR get Machine By Room Id
export const getMachineByRoomId = (RoomId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/room/" + RoomId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR get Own Machine By RoomId
export const getOwnMachineByRoomId = (RoomId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/room/own/" + RoomId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR get Machine By FactoryId
export const getMachineByFactoryId = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/machines/factory/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR get Machines From Own Factories
export const getMachinesFromOwnFactories = () => {
  return axios.get("https://api.nilfiskfood.com/api/machines/own/from/factories", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR update Machine By Id
export const updateMachineById = (MachineId) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/" + MachineId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR add Machine To  Factory
export const addMachineToFactory = (machine) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/admin/add-to-factory", machine, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR add Machine To Own Factory
export const addMachineToOwnFactory = (machine) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/own/add-to-factory", machine, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR add Machine To Own Room
export const addMachineToOwnRoom = (machine) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/own/add-to-room", machine, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR add Machine To Room
export const addMachineToRoom = (machine) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/admin/add-to-room", machine, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR remove Machine From Room
export const removeMachineFromRoom = (MachineId) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/room/" + MachineId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR update Own Machine Room And Name
export const updateOwnMachineRoomAndName = (MachineId, machine) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/own/update-room-and-name/" + MachineId, machine, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR update Machine Room And Name
export const updateMachineRoomAndName = (MachineId, machine) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/update-room-and-name/" + MachineId, machine, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR delete Machine
export const deleteMachine = (MachineId) => {
  return axios.put("https://api.nilfiskfood.com/api/machines/" + MachineId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
