import RoomRow from "./RoomRow";
import { IoMdRefresh } from "react-icons/io";
import { LuRefreshCcw } from "react-icons/lu";
import { useQueryClient } from "@tanstack/react-query";

//TOOD there is a problem with span on line 44, if the screen is too small there will be a horizontal scrollbar
export default function MachinesTable({ data, isLoading }) {
  const queryClient = useQueryClient();
  var rooms = [];
  if (data && data.length > 0) {
    rooms = data.reduce((acc, machine) => {
      const roomName = machine.Room.name;
      if (!acc[roomName]) {
        acc[roomName] = {
          name: roomName,
          machines: [],
        };
      }

      acc[roomName].machines.push(machine);

      return acc;
    }, {});
  }

  const roomsArray = Object.values(rooms);
  return (
    <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
      <div className="px-4  sm:px-0">
        <div className="px-0">
          <div className=" flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      >
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Serial Number
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Connection Status
                      </th>
                      <th scope="col" className="px-2 sm:px-4 py-3.5 text-right text-sm font-semibold text-gray-900">
                        <div className="hs-tooltip inline-block [--trigger:hover] align-middle">
                          <button
                            type="button"
                            className="hs-tooltip-toggle  text-blue-500 hover:text-blue-300 "
                            onClick={(event) => {
                              queryClient.invalidateQueries(["machine", "status"]);
                            }}
                          >
                            <LuRefreshCcw size={25} />
                          </button>
                          <span
                            class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-gray-900 border text-sm text-white rounded-lg shadow-lg border-gray-700 "
                            role="tooltip"
                          >
                            Refresh the connection status
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {isLoading ? (
                      <tr key="loading row" className="border-t border-gray-200">
                        <td key="loading row" colSpan={5}>
                          <div className="flex justify-center">
                            <div className="my-4 animate-spin rounded-full w-8 h-8 border-t-4 border-r-4 border-b-4 border-l-4 border-blue-300 border-t-transparent"></div>
                          </div>
                        </td>
                      </tr>
                    ) : roomsArray.length > 0 ? (
                      roomsArray.map((room) => <RoomRow key={room.name} room={room} machines={room.machines} />)
                    ) : (
                      <tr className="border-t border-gray-200">
                        <td
                          colSpan={5}
                          className=" py-2 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          No machines found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
