import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import userReducer from './user/reducers/userReducer';
import deviceReducer from './device/reducers/deviceReducer';
import widgetsReducer from './widgets/reducers/widgetsReducer';
import dashboardReducer from './dashboard/reducers/dashboardReducer';
import errorLogReducer from './errorLog/reducers/errorLogReducer';
// import userReducer from './slices/userReducer';

const reducers = combineReducers({
  user: userReducer,
  device: deviceReducer,
  widgets: widgetsReducer,
  dashboard: dashboardReducer,
  errorLog: errorLogReducer,
});

const persistConfig = {
  key: 'root',
  storage
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  // middleware: [thunk]
});
// const store = configureStore({
//   reducer: {
//     user: userReducer,
//     device: deviceReducer,
//     widgets: widgetsReducer,
//     dashboard: dashboardReducer,
//   },
// });

export default store;