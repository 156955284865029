import io from 'socket.io-client';
let socket;
export const initiateSocket = (room) =>
{
    socket = io('https://api.nilfiskfood.com', { transport : ['websocket'] });
    console.log(`Connecting socket...`);
    if (socket && room) socket.emit('subscribe', room);
}
export const disconnectSocket = () =>
{
    console.log('Disconnecting socket...');
    if (socket) socket.disconnect();
}

export const joinRoom = (room) =>
{
    console.log('joining room...'+room);
    if (socket) socket.emit('subscribe', room)
}

export const leaveRoom = (room) =>
{
    console.log('Leaving room...'+room);
    if (socket) socket.emit('unsubscribe', room)
}

export const getDeviceData = (deviceId) =>
{
    console.log('Getting device data...');
    if (socket) socket.emit('leave', deviceId)
}
export const getSocket = () =>
{
    if (socket) return socket;
}