import React from "react";
import { useParams } from "react-router-dom";
import MachinesTable from "../Components/TableMachines/MachinesTable";
import { useGetOwnMachinesByFactoryId } from "shared/services/machine.service";

export default function ViewFactoryMachines(props) {
  let { factoryId } = useParams();
  const { data, isLoading } = useGetOwnMachinesByFactoryId(factoryId);

  return <MachinesTable data={data?.machines} isLoading={isLoading} />;
}
