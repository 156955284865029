import { useQuery } from "@tanstack/react-query";
import axios from "axios";

//ANCHOR select factories for which you want to receive notifications
export const selectFactory = async (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/factory-user/select/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const getFactoriesNotificationsFilter = async (body) => {
  return axios.post("https://api.nilfiskfood.com/api/factories/notifications/filter", body, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
export const getFactoriesNotifications = async (body) => {
  return axios.post("https://api.nilfiskfood.com/api/factories/search/notification", body, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR  find all own factories pagination and order
export const searchAndfilterOwnFactories = (filters) => {
  return axios.post("https://api.nilfiskfood.com/api/factories/own/search/order", filters, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR  find all factories pagination and order
export const searchAndfilterFactories = (filters) => {
  return axios.post("https://api.nilfiskfood.com/api/factories/search/order", filters, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR create own factory
export const createOwnFactory = (newFactory) => {
  return axios.post("https://api.nilfiskfood.com/api/factories/own", newFactory, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR create factory
export const createFactory = (newFactory) => {
  return axios.post("https://api.nilfiskfood.com/api/factories", newFactory, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR searchfActory
export const searchFactory = (searchText) => {
  return axios.get("https://api.nilfiskfood.com/api/factories/search/" + searchText, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR get All Factories With Filter
export const getAllFactoriesWithFilter = (searchData) => {
  return axios.post("https://api.nilfiskfood.com/api/factories/filter", searchData, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR get Factories For Map
export const getFactoriesForMap = () => {
  return axios.get("https://api.nilfiskfood.com/api/factories/map/own", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR getAllOwnFactoriesWithFilter
export const getAllOwnFactoriesWithFilter = (searchData) => {
  return axios.post("https://api.nilfiskfood.com/api/factories/own/filter", searchData, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR getFactoryById
export const getFactoryById = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/factories/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

export const useUserOwnedFactoryById = (FactoryId) => {
  return useQuery({
    queryKey: ["factory", FactoryId],
    queryFn: async () => {
      const token = JSON.parse(localStorage.getItem("token")).token;
      const response = await axios.get(`https://api.nilfiskfood.com/api/factories/own/${FactoryId}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    },
  });

};

//ANCHOR getOwnFactoryById
export const getOwnFactoryById = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/factories/own/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR getFactoriesByCompanyId
export const getFactoriesByCompanyId = (CompanyId) => {
  return axios.get("https://api.nilfiskfood.com/api/factories/company/" + CompanyId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR updateFactoryStatusById
export const updateFactoryStatusById = (FactoryId) => {
  return axios.put("https://api.nilfiskfood.com/api/factories/status/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR updateFactoryById
export const updateFactoryById = (FactoryId, factory) => {
  return axios.put("https://api.nilfiskfood.com/api/factories/" + FactoryId, factory, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR updateOwnFactoryById
export const updateOwnFactoryById = (FactoryId, factory) => {
  return axios.put("https://api.nilfiskfood.com/api/factories/own/" + FactoryId, factory, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR deleteFactory
export const deleteFactory = (FactoryId) => {
  return axios.delete("https://api.nilfiskfood.com/api/factories/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR deleteOwnFactory
export const deleteOwnFactory = (FactoryId) => {
  return axios.delete("https://api.nilfiskfood.com/api/factories/own/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR getOwnFactories
export const getOwnFactories = () => {
  return axios.get("https://api.nilfiskfood.com/api/factories/names/navigation", {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
//ANCHOR get factory name by id
export const getOwnFactoryNameById = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/factories/name/own/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};

//ANCHOR get factory name by id
export const getFactoryNameById = (FactoryId) => {
  return axios.get("https://api.nilfiskfood.com/api/factories/name/" + FactoryId, {
    headers: {
      Authorization: JSON.parse(localStorage.getItem("token")).token,
    },
  });
};
