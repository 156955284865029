import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { ErrorLogEntry } from "../../Models/Errorlog/ErrorLogEntry";
import { ErrorPopUps } from "../../Models/Errorlog/ErrorPopUps";
import LeftButton from "../uiElements/interactive/LeftButton";
import RightButton from "../uiElements/interactive/RightButton";

function getErrorEntryColor(type: string): string {
  if (type.startsWith("W")) {
    return "#f2a835";
  }
  return "#ea3323"
}
export default function ErrorPopUp({ errorPopUpModel, disabled }: { errorPopUpModel: ErrorPopUps, disabled: boolean }) {
  const [ErrorEntry, SetErrorEntry] = React.useState(errorPopUpModel.GetCurrentError());
  if (!ErrorEntry) {
    return <></>
  }
  return (

    <Grid container bgcolor={getErrorEntryColor(ErrorEntry.ErrorType)} height={"18vmax"}>
      <Grid item xs={1}>

        {errorPopUpModel.CanGoLeft() ? <LeftButton disabled={disabled} onclickHandler={() => SetErrorEntry(errorPopUpModel.GetPrevErrorLog())}></LeftButton> : null}
      </Grid>
      <Grid container direction={"column"} justifyContent="center" maxWidth={"%"}>
        <Grid item container borderRadius={"0.5vw"}>
          <ShowError error={ErrorEntry}></ShowError>

        </Grid>

        <ShowCauses error={ErrorEntry}></ShowCauses>
        <ShowRemedies error={ErrorEntry}></ShowRemedies>
      </Grid>
      <Grid item container justifyContent="center" alignItems="center"  >
        <Button onClick={() => errorPopUpModel.PopErrors()}><Typography color={"white"}>OK</Typography></Button>
      </Grid>
      <Grid item xs={1}>

        {errorPopUpModel.CanGoRight() ? <RightButton disabled={disabled} onclickHandler={() => SetErrorEntry(errorPopUpModel.GetNextErrorLog())}></RightButton> : null}
      </Grid>
    </Grid >
  );
}
function ShowError({ error }: { error: ErrorLogEntry }): JSX.Element {
  let date = error.date as Date;
  return (
    <>
      <Grid container item alignContent={"center"} justifyContent={"start"} xs={5} sx={{ paddingLeft: "0.5vw" }}>
        <Typography variant="button" alignItems={"center"} color="white" overflow="hidden">
          {`${moment(date).format("hh:mm:ss")}`}
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="center" alignContent={"center"}>
        <Typography variant="button" alignItems={"center"} textAlign={"end"} color="white" overflow="hidden">
          {`  ${error.errorMessage} `}
        </Typography>
      </Grid>

    </>
  );
}


function ShowCauses({ error }: { error: ErrorLogEntry }): JSX.Element | null {
  if (error.causes.length === 0) {
    return null;
  }
  return (
    <Grid item container direction={"column"} justifyContent="center" borderRadius={"0.5vw"} minWidth={"100%"}>
      <Grid item container justifyContent={"center"}>
        <Typography variant="button" color={"white"} fontSize={"1vmax"}>
          Causes
        </Typography>
      </Grid>

      {error.causes.map((cause, index) => {

        if (cause === "") {
          return <></>;
        }
        return (
          <Grid item container borderTop={1} borderColor={"grey"} sx={{ borderWidth: "0.15vh", paddingLeft: "0.5vw" }}>
            {parseMessage(cause)}
          </Grid>
        );
      })}
    </Grid>
  );
}

function ShowRemedies({ error }: { error: ErrorLogEntry }): JSX.Element | null {
  if (error.remedies.length === 0) {
    return null;
  }
  return (
    <Grid item container direction={"column"} justifyContent="center" borderRadius={"0.5vw"} minWidth={"100%"}>
      <Grid item container justifyContent={"center"}>
        <Typography fontSize={"1vmax"} variant="button" color={"white"}>
          Remedies
        </Typography>
      </Grid>

      {error.remedies.map((remedy, index) => {
        if (remedy === "") {
          return <></>;
        }
        return (
          <Grid item container borderTop={1} borderColor={"grey"} sx={{ borderWidth: "0.15vh", paddingLeft: "0.5vw" }}>
            {parseMessage(remedy)}
          </Grid>
        );
      })}
    </Grid>
  );
}

function parseMessage(val: string): JSX.Element {
  let values = val.split(/(?=\()/g);

  if (values.length === 0) {
    return (
      <Typography fontSize={"1vmax"} variant="button" alignItems={"center"} color="white" overflow="hidden">
        {val}
      </Typography>
    );
  }
  return (
    <>
      {values.map((val, idx) => {
        if (idx === values.length - 1) {
          return (
            <Typography fontSize={"1vmax"} variant="button" alignItems={"center"} color="white" overflow="hidden">
              {val}
            </Typography>
          );
        }
        return (
          <>
            <Typography fontSize={"1vmax"} variant="button" alignItems={"center"} color="white" overflow="hidden">
              {val}
            </Typography>
            <br></br>
          </>
        );
      })}
    </>
  );
}