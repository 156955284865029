import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, CircularProgress, Grid, IconButton, List, ListItem, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { ConvertEepromData, GetComponentName, GetDisplayNameForEepromKey, GetUnit, ParseKeyToInterval, componentNames } from "./DashBoardParsing";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
type MachineResponseType = "OK" | "Error";

type componentView = ({ setting, value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) => JSX.Element;
const SettingValueViews: Record<componentNames, componentView> = {
  Default: ValueSettingView,
  FlagButton: FlagButton,
  ValueAdjuster: ValueAdjusterSettingsView,
};
export default function SettingsView() {
  const server = GetServer();

  const [Settings, SetSettings] = React.useState<Map<string, string>>(new Map());
  const [CurrentSettings, SetCurrentSettings] = React.useState<Map<string, string>>(new Map());
  const [DefualtSettings, SetDefaultSetings] = React.useState<Map<string, string>>(new Map());
  const [ChangeStatus, SetChangeStatus] = React.useState<boolean>(false);
  const [MachineResponse, SetMachineResponse] = React.useState<MachineResponseType | null>(null);
  const [Err, SetErr] = React.useState(false);
  const [Sending, SetSending] = React.useState<{ sending: boolean, status: string }>({ sending: false, status: "" });
  useEffect(() => {

    server.GetEepromSettings((mabye_data, err) => {

      if (!err) {
        if (Err) {
          SetErr(false);
        }
        const data = mabye_data!;

        let settings = Object.keys(data)
          .sort((a, b) => {
            const nameA = GetUnit(a);
            const nameB = GetUnit(b);
            const typeA = GetComponentName(a);
            const typeB = GetComponentName(b);
            let typeSort = (typeB as string).localeCompare(typeA as string);
            if (typeSort == 0) {
              return nameB.localeCompare(nameA);
            }
            return typeSort;
          })
          .reduce((obj: Map<string, string>, key) => {
            obj.set(key, data[key]);
            return obj;
          }, new Map());


          server.GetMachineDefaultSettingsMemJSON((mabye_data, err) => {
            if (!err) {
              SetDefaultSetings(new Map(Object.entries(mabye_data!)));
              SetSettings(new Map(settings));
              SetCurrentSettings(new Map(settings));
              return;
            }
            SetErr(true);
          })
        
    
        return;
      }
      
      SetErr(true);

    });
  },[]);




  if (Err) {
    return <Grid item xs container justifyContent={"center"} direction={"column"} >
      <Typography color={"black"} variant="button">Couldnt fetch eeprom settings.</Typography>
    </Grid>
  }

  if (Sending.sending) {
    return (<Grid item xs container justifyContent={"center"} direction={"column"} >

      <Grid item xs><Grid item xs container justifyContent={"center"}><Typography color={"black"} variant="button">{Sending.status}</Typography></Grid></Grid>
      <Grid item xs> <Grid item xs container justifyContent={"center"} alignContent={"center"} height={"100%"}><CircularProgress size={"5vmax"} ></CircularProgress></Grid></Grid>
    </Grid>);
  }

  if (MachineResponse !== null) {
    return (

      <Grid item xs container direction={"column"} justifyContent={"center"} justifyItems={"center"} height={"100%"}>
        <ListItem><MachineResponseView MachineResponse={MachineResponse} SetMachineResponse={SetMachineResponse}></MachineResponseView></ListItem>
      </Grid>
    );
  }
  console.log(Settings);
  if (Settings.size === 0 ) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} borderRadius={"0.5vw"} height={"70vmax"}>
      <Grid item> <CircularProgress size={"5vmax"}></CircularProgress></Grid>
    </Grid>;
  }

  if (ChangeStatus) {


    return (

      <List sx={{ overflow: "auto", minWidth: "100%", maxHeight: "71vh" }} className="scroll">
        <ListItem>
          <Grid item xs={12} justifyContent={"center"} alignContent={"center"} maxHeight={"10vh"} borderRadius={"0.5vw"}>
            <Button
              fullWidth={true}
              onClick={() => {
                server.SetEepromSettings(Object.fromEntries(Settings), (ok, err) => {
                  SetSending({ sending: true, status: "fetching new settings from machine" });

                  server.GetEepromSettings((mabye_data, err) => {
                    console.log(err);
                    console.log(mabye_data);

                    if (!err) {
                      const data = mabye_data!;

                      let settings = Object.keys(mabye_data!)
                        .sort((a, b) => {
                          const nameA = GetUnit(a);
                          const nameB = GetUnit(b);
                          const typeA = GetComponentName(a);
                          const typeB = GetComponentName(b);
                          let typeSort = (typeB as string).localeCompare(typeA as string);
                          if (typeSort === 0) {
                            return nameB.localeCompare(nameA);
                          }
                          return typeSort;
                        })

                        .reduce((obj: Map<string, string>, key) => {
                          obj.set(key, data[key]);
                          return obj;
                        }, new Map());
                      SetSettings(new Map(settings));
                      SetCurrentSettings(new Map(settings));
                      if (ok) {

      
                        SetMachineResponse("OK");
                        SetSending({ sending: false, status: "" });
                        return;


                    }

                    SetMachineResponse("Error");
                    SetSending({ sending: false, status: "" });

                  }
                });


                });
                SetSending({ sending: true, status: "setting new settings on machine" });


                SetChangeStatus(false);


              }}
            >
              <Typography variant="button" >Send Settings to  machine </Typography>
            </Button>
          </Grid>
        </ListItem>

        <EepromSettingsView Settings={Settings} changeStatus={ChangeStatus} DefaultSettings={DefualtSettings} CurrentSettings={CurrentSettings} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></EepromSettingsView>
      </List>

    );
  }

  return (
    <>
      <Grid item xs container>
        <List sx={{ overflow: "auto", minWidth: "100%", maxHeight: "71vh" }} className="scroll">

          <EepromSettingsView Settings={Settings} changeStatus={ChangeStatus} DefaultSettings={DefualtSettings} CurrentSettings={CurrentSettings} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></EepromSettingsView>
        </List>
      </Grid>
    </>
  );
}

function MachineResponseView({ MachineResponse, SetMachineResponse }: { MachineResponse: MachineResponseType; SetMachineResponse: React.Dispatch<React.SetStateAction<MachineResponseType | null>> }): JSX.Element {
  const server = GetServer();
  switch (MachineResponse) {
    case "Error":
      return (
        <Grid item xs direction={"column"}>

          <Grid item xs={12} justifyContent={"center"} border={1} borderColor={"darkblue"} borderRadius={"0.5vw"}>
            <Typography variant="button" color={"black"}>Machine couldnt update status</Typography>
          </Grid>
          <Grid item xs={12} justifyContent={"center"} border={1} borderColor={"darkblue"} borderRadius={"0.5vw"}>
            <Button onClick={() => {
              SetMachineResponse(null);
            }}>
              <Typography variant="button" color={"black"} >Go back to navigator mode.</Typography>
            </Button>
          </Grid>
        </Grid>

      );
    case "OK": {
      return (
        <Grid item container  height={"70vh"} direction={"column"} rowGap={3} >
          <Grid item container xs={1} justifyContent={"center"} alignContent={"center"} borderRadius={"0.5vw"}>
            <Grid item xs={6}><Typography  variant="button" color={"black"} >Machine Updated succesfully</Typography></Grid>
          </Grid>
          <Grid item xs={2} container direction={"column"} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"}>
            <Button  sx={{height: "100%"}} fullWidth={true} onClick={() => {
              server.SendCommand("RebootSystem", null, () => { });
              server.disconnectNoError();
            }}>
              <Grid item container xs={12} direction={"column"} >
              <Grid item xs><Typography variant="button" color={"black"}  >Reboot the machine to update settings</Typography></Grid>
              <Grid item xs><Typography variant="button" color={"black"}  > Machine will leave navigator mode.</Typography></Grid>

              </Grid>
            </Button>
          </Grid>
          <Grid item xs={2} justifyContent={"center"} alignContent={"center"}  bgcolor={"white"} borderRadius={"0.5vw"}>
            <Button  sx={{height: "100%"}} fullWidth={true} onClick={() => {
              SetMachineResponse(null);
            }}>
              <Grid container xs direction={"column"} >

              <Grid item xs><Typography variant="button" color={"black"}  >Go Back To Navigator Mode</Typography></Grid>
              <Grid item xs ><Typography variant="button" color={"black"}  > Machine will need to be rebooted to update all settings.</Typography></Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      );
    }
  }
}

function EepromSettingsView({
  Settings,
  CurrentSettings,
  SetSettings,
  DefaultSettings,
  changeStatus,
  SetChangeStatus,
}: {
  Settings: Map<string, string>;
  CurrentSettings: Map<string, string>;
  DefaultSettings: Map<string, string>;
  changeStatus: boolean;
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      <ListItem>
        <GridNames></GridNames>
      </ListItem>
      {Array.from(Settings.keys())
        .filter((key) => {
          return GetDisplayNameForEepromKey(key) !== undefined;
        })
        .map((key) => {
          let setting = Settings.get(key)!;
          let currSetting = CurrentSettings.get(key)!;
          let defaultSetting = DefaultSettings.get(key)!;
          return (
            <ListItem>
              <SettingValueView changeStatus={changeStatus} setting={[key, setting]} DefaultSetting={[key, defaultSetting]} CurrentSettings={[key, currSetting]} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></SettingValueView>
            </ListItem>
          );
        })}
    </>
  );
}
function GridNames(): JSX.Element {
  const [ShowInfo, SetShowInfo] = React.useState(false);

  return (
    <Grid container direction={"column"} rowGap={2}>
      <Grid item container bgcolor={"white"} borderRadius={"10px"}>

        <Grid item columns={18} xs={6} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
          <Typography variant={"button"} color="black">
            Name
          </Typography>
        </Grid>
        <Grid item columns={18} xs={2} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
          <Typography variant={"button"} color="black">
            F
          </Typography>
        </Grid>
        <Grid item columns={18} xs={2} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
          <Typography variant={"button"} color="black">
            C
          </Typography>
        </Grid>
        <Grid item columns={18} xs={2} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
          <Typography variant={"button"} color="black">
            N
          </Typography>
        </Grid>

        <Grid item columns={18} xs={2} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
          <Typography variant={"button"} color="black">
            U
          </Typography>
        </Grid>

        <Grid item columns={18} xs={4} container alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              SetShowInfo((prev) => !prev);
            }}
          >
            <HelpOutlineIcon></HelpOutlineIcon>
          </IconButton>
        </Grid>
      </Grid>

      {ShowInfo ? (
        <Grid item container bgcolor={"white"} borderRadius={"10px"}>
          <InfoMenu></InfoMenu>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}

function InfoMenu(): JSX.Element {
  return (
    <Grid container bgcolor={"white"} borderRadius={"10px"} direction={"column"}>
      <Grid item container justifyContent={"start"}>
        <Grid></Grid>

        <Typography variant={"button"} color="black">
          Name: The Name of the field.
        </Typography>
      </Grid>
      <Grid item container justifyContent={"start"}>
        <Typography variant={"button"} color="black">
          F: The factory value of the machine
        </Typography>
      </Grid>
      <Grid item container justifyContent={"start"}>
        <Typography variant={"button"} color="black">
          C: The current value on the machine.
        </Typography>
      </Grid>
      <Grid item container justifyContent={"start"}>
        <Typography variant={"button"} color="black">
          N: The new value to be set.
        </Typography>
      </Grid>

      <Grid item container justifyContent={"start"}>
        <Typography variant={"button"} color="black">
          U: Unit of the value
        </Typography>
      </Grid>
    </Grid>
  );
}
function SettingValueView({
  setting,
  SetSettings,
  CurrentSettings,
  DefaultSetting,
  changeStatus,
  SetChangeStatus,
}: {
  setting: [string, string];
  CurrentSettings: [string, string];
  DefaultSetting: [string, string];
  changeStatus: boolean;
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let [value, SetValue] = React.useState<string | null>(null);
  let [newValue, SetNewValue] = React.useState<string | null>(null);
  let [name, ePromVal] = setting;

  const displayName = GetDisplayNameForEepromKey(name);
  const componentName = GetComponentName(name);
  const Controller = SettingValueViews[componentName];
  useEffect(() => {
    SetValue(ePromVal);
  }, [setting]);

  return (
    <Grid container bgcolor={"white"} borderRadius={"10px"} rowGap={2} height={"6vh"}>
      <Grid item xs={4} container alignItems={"center"} alignContent={"center"} justifyContent={"start"} sx={{ paddingLeft: "0.5vw" }} borderRight={1}>
        <Typography variant={"button"} color="black">
          {displayName}
        </Typography>
      </Grid>

      <Grid item xs={8} container>
        <Controller setting={setting} defaultSetting={DefaultSetting} CurrentSettings={CurrentSettings} SetNewValue={SetNewValue} value={newValue}></Controller>
        <Grid item xs={1} container justifyContent={"center"}>
          <PresOkButton name={name} value={newValue} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus} SetNewValue={SetNewValue}></PresOkButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ValueSettingView({ setting, CurrentSettings, defaultSetting, value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  return (
    <>
      <Grid item container xs={4} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
        <Typography variant={"button"} color={"black"}>
          {value?.toString()}
        </Typography>
      </Grid>
      <Grid item container xs={5} justifyContent={"center"} alignContent={"center"}>
        <Grid item xs={9} bgcolor={"white"}>
          <TextField
            inputProps={{ style: { textAlign: "center" } }}
            size={"small"}
            defaultValue={value}
            onChange={(event) => {
              let new_value = event.target.value;

              SetNewValue(new_value);
            }}
          ></TextField>
        </Grid>
      </Grid>
    </>
  );
}

function FlagButton({ setting, CurrentSettings, defaultSetting, value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  const value_int = parseInt(value!);
  const [name, ePromVal] = setting;
  const [current_name, current_val] = CurrentSettings;
  const [default_name, default_val] = defaultSetting;

  const checked = value_int === 0 ? false : !isNaN(value_int) ? true : parseInt(ePromVal) === 0 ? false : true;

  const checked_currently = parseInt(current_val) === 0 ? false : true;
  const check_default = parseInt(default_val) === 0 ? false : true;
  return (
    <>
      <Grid item container xs={3} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
        <Switch checked={check_default} disabled={true}></Switch>
      </Grid>
      <Grid item container xs={3} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
        <Switch checked={checked_currently} disabled={true}></Switch>
      </Grid>
      <Grid item container xs={3} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
        <Switch
          checked={checked}
          onChange={(event) => {
            SetNewValue((prev) => {
              return prev == "0" ? "1" : "0";
            });
          }}
        ></Switch>
      </Grid>
    </>
  );
}

function ValueAdjusterSettingsView({ setting, CurrentSettings, defaultSetting, value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  const [name, ePromVal] = setting;
  const Interval = ParseKeyToInterval(name);
  const adjusted_val = ConvertEepromData(name, parseInt(value!));
  const [current_name, current_val] = CurrentSettings;
  const [default_name, default_val] = defaultSetting;
  const adjuststed_default_val = ConvertEepromData(name, parseInt(default_val!));
  const adjusted_current_val = ConvertEepromData(name, parseInt(current_val!));

  const unit = GetUnit(current_name);
  if (!Interval) return <></>;

  return (
    <>
      <Grid item container xs={2} justifyContent={"center"} alignContent={"center"} alignItems={"center"} borderRight={1}>
        <Typography variant={"button"} color={"black"}>
          {adjuststed_default_val.toString()}
        </Typography>
      </Grid>
      <Grid item container xs={2} justifyContent={"center"} alignContent={"center"} alignItems={"center"} borderRight={1}>
        <Typography variant={"button"} color={"black"}>
          {adjusted_current_val.toString()}
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent={"center"} alignContent={"center"} borderRight={1}>
        <Typography variant="button">{isNaN(adjusted_val) ? ConvertEepromData(name, parseInt(ePromVal!)) : adjusted_val}</Typography>
      </Grid>

      <Grid item xs={2} container justifyContent={"center"} alignContent={"center"} borderRight={1}>
        <CreateUnitTextElement unit={unit} ></CreateUnitTextElement>
      </Grid>
      <Grid item container xs={3} alignContent={"center"} justifyContent={"center"}>
        <Grid container item xs={4} justifyContent="center">
          <IconButton
            disabled={value ? parseInt(value) <= Interval!.low : parseInt(ePromVal) <= Interval!.low}
            onClick={() => {
              SetNewValue((prev) => {
                if (prev != null) {
                  let prev_int = parseInt(prev);
                  return (prev_int - Interval.interval).toString();
                }
                return (parseInt(ePromVal) - Interval.interval).toString();
              });
            }}
          >
            <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
          </IconButton>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <IconButton
            disabled={value ? parseInt(value) >= Interval!.high : parseInt(ePromVal) >= Interval!.high}
            onClick={() => {
              SetNewValue((prev) => {
                if (prev != null) {
                  let prev_int = parseInt(prev);
                  return (prev_int + Interval.interval).toString();
                }
                return (parseInt(ePromVal) + Interval.interval).toString();
              });
            }}
          >
            <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

function CreateUnitTextElement({unit} : { unit : string}) : JSX.Element {
  if (unit.includes("/")) {
    let [first,second] = unit.split("/");
    
    return <>        
      <Typography variant="button">{first+"/"}</Typography>
      <br/>
      <Typography variant="button">{second}</Typography>

    </>
  }
  return <Typography variant="button">{unit}</Typography>

}

function PresOkButton({
  name,
  value,
  SetSettings,
  SetChangeStatus,
  SetNewValue,
}: {
  name: string;
  value: string | null;
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
  SetNewValue: React.Dispatch<React.SetStateAction<string | null>>;
}): JSX.Element | null {
  if (!value) {
    return null;
  }
  return (
    <Grid item xs={4} container bgcolor={"white"} justifyContent={"end"} alignContent={"center"} alignItems={"center"} borderRadius={"0.5vw"} >

      <Typography style={{ cursor: "pointer" }}
        onClick={() => {
          SetSettings((prev) => {
            return new Map(prev.set(name, value));
          });
          SetNewValue(null);
          SetChangeStatus(true);

        }} variant="button" color={"black"}>Ok</Typography>
    </Grid>
  );
}
