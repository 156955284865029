
function dec2bin(dec)
{
    return (dec >>> 0).toString(2);
}
/**
 * Converts the error code into a readeable error
 * 
 * @param {number} errorCode1 
 * @param {number} errorCode2 
 * @param {number} errorCode3 
 * @returns {Array.<Object>} 
 * 
 * @example
 * [{errorname: 'test-errorname', description: 'test-description'}]
 */
const decryptErrorCodes = (errorCode1, errorCode2, errorCode3) =>
{
    var arrayOfErrors = [];
    if (errorCode1 != 0)
    {
        const ErrorCode1 = dec2bin(errorCode1);
        if (ErrorCode1 % 10 == 1)
        {
            var error = { errorname: 'Pt1HighTempBit', description: 'Motor temperature high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 10) % 10 == 1)
        {
            var error = { errorname: 'Pt2HighTempBit', description: 'Water temperature high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 100) % 10 == 1)
        {
            var error = { errorname: 'Pt3HighTempBit', description: 'Default not used' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 1000) % 10 == 1)
        {
            var error = { errorname: 'Pt1LowTempBit', description: 'Default not used' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 10000) % 10 == 1)
        {
            var error = { errorname: 'Pt2LowTempBit', description: 'Water temperature low' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 100000) % 10 == 1)
        {
            var error = { errorname: 'Pt3LowTempBit', description: 'Default not used' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 1000000) % 10 == 1)
        {
            var error = { errorname: 'TankControlLow', description: 'Low water level in tank, used when function tank control is enabled. And in this case buffertank is below critical level' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 10000000) % 10 == 1)
        {
            var error = { errorname: 'SuctionLance1Empty', description: 'Detection of chemical level in can 1, triggered when level is critical low' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 100000000) % 10 == 1)
        {
            var error = { errorname: 'SuctionLance2Empty', description: 'Detection of chemical level in can 2, triggered when level is critical low' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode1 / 1000000000) % 10 == 1)
        {
            var error = { errorname: 'MbAliveError', description: 'No communication from Modbus, when not receiving signals from controller for a period of time' }
            arrayOfErrors.push(error)
        }
    }
    if (errorCode2 != 0)
    {
        const ErrorCode2 = dec2bin(errorCode2);
        if (ErrorCode2 % 10 == 1)
        {
            var error = { errorname: 'P1_PresssureLowBit', description: 'Inlet pressure critical low' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 10) % 10 == 1)
        {
            var error = { errorname: 'P1_SignalLowBit', description: 'No or low signal from inlet sensor' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 100) % 10 == 1)
        {
            var error = { errorname: 'P1_HighLoad ', description: 'Inlet Sensor Load high, Current to sensor high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 1000) % 10 == 1)
        {
            var error = { errorname: 'P1_LowLoad', description: 'Inlet Sensor Load low, Current to sensor low' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 10000) % 10 == 1)
        {
            var error = { errorname: 'P2_PresssureLowBit', description: 'Outlet pressure critical low' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 100000) % 10 == 1)
        {
            var error = { errorname: 'P2_SignalLowBit ', description: 'No or low signal from Outlet sensor' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 1000000) % 10 == 1)
        {
            var error = { errorname: 'P2_HighLoad', description: 'Outlet Sensor Load high, Current to sensor high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 10000000) % 10 == 1)
        {
            var error = { errorname: 'P2_LowLoad', description: 'Outlet Sensor Load low, Current to sensor low' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 100000000) % 10 == 1)
        {
            var error = { errorname: 'P3_PresssureLowBit', description: '-' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 1000000000) % 10 == 1)
        {
            var error = { errorname: 'P3_SignalLowBit', description: '-' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 10000000000) % 10 == 1)
        {
            var error = { errorname: 'P3_HighLoad', description: '-' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 100000000000) % 10 == 1)
        {
            var error = { errorname: 'P3_LowLoad', description: '-' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 1000000000000) % 10 == 1)
        {
            var error = { errorname: 'Flow_HighLoad', description: 'Flow switch Load high, Current to flow switch high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode2 / 10000000000000) % 10 == 1)
        {
            var error = { errorname: 'Flow_LowLoad', description: 'Flow switch Load low, Current to flow switch low' }
            arrayOfErrors.push(error)
        }

    }
    if (errorCode3 != 0)
    {
        const ErrorCode3 = dec2bin(errorCode3);
        if (ErrorCode3 % 10 == 1)
        {
            var error = { errorname: 'currErrPhase1', description: 'Instantaneous current on motor phase 1 has been too high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 10) % 10 == 1)
        {
            var error = { errorname: 'currErrPhase2', description: 'Instantaneous current on motor phase 2 has been too high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 100) % 10 == 1)
        {
            var error = { errorname: 'currErrPhase3', description: 'Instantaneous current on motor phase 3 has been too high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 1000) % 10 == 1)
        {
            var error = { errorname: 'sumCurrErr', description: 'Instantaneous motor sum current has been too high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 10000) % 10 == 1)
        {
            var error = { errorname: 'dcLowVoltErr', description: 'DC Link voltage has been too low' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 100000) % 10 == 1)
        {
            var error = { errorname: 'HighVoltErr', description: 'DC Link voltage has been too high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 1000000) % 10 == 1)
        {
            var error = { errorname: 'hwOverCurrErr', description: 'Inverter hardware has detected over-current one of the phases' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 10000000) % 10 == 1)
        {
            var error = { errorname: 'safeTorqueOff', description: 'Safe torque off digital input has be activated' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 100000000) % 10 == 1)
        {
            var error = { errorname: 'moduleTempToHigh', description: 'IGBT temperature has been too high' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 1000000000) % 10 == 1)
        {
            var error = { errorname: 'moduleTempHigh (not shown)', description: 'not used' }
            arrayOfErrors.push(error)
        }
        if (Math.floor(ErrorCode3 / 10000000000) % 10 == 1)
        {
            var error = { errorname: 'Powerlimit (not shown)', description: 'not used' }
            arrayOfErrors.push(error)
        }

    }
    return arrayOfErrors
}

/**
 * 
 * Converts the warning code into a readeable error
 * @param {number} warningCode1 
 * @param {number} warningCode2 
 * @returns {Array.<Object>} Array of objects
 * 
 *  @example
 *  [{warningname: 'test-warningname', description: 'test-description'}]
 */
const decryptWarningCodes = (warningCode1, warningCode2) =>
{
    var arrayOfWarnings = [];
    if (warningCode1 != 0)
    {
        const WarningCode1 = dec2bin(warningCode1);
        if (WarningCode1 % 10 == 1)
        {
            var warning = { warningname: 'P1_LowWarningBit', description: 'Low inlet pressure' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 10) % 10 == 1)
        {
            var warning = { warningname: 'P1_HighLimitUpBit', description: '-' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 100) % 10 == 1)
        {
            var warning = { warningname: 'P1_HighLimitLowBit', description: '-' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 1000) % 10 == 1)
        {
            var warning = { warningname: 'Not used', description: 'Not used' }
            // arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 10000) % 10 == 1)
        {
            var warning = { warningname: 'P2_HighLimitUpBit', description: '-' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 100000) % 10 == 1)
        {
            var warning = { warningname: 'P2_HighLimitLowBit', description: 'Outlet pressure low' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 1000000) % 10 == 1)
        {
            var warning = { warningname: 'P3_LowWarningBit', description: '-' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 10000000) % 10 == 1)
        {
            var warning = { warningname: 'P3_HighLimitUpBit', description: '-' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 100000000) % 10 == 1)
        {
            var warning = { warningname: 'P3_HighLimitLowBit', description: '-' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 1000000000) % 10 == 1)
        {
            var warning = { warningname: 'TankControlHigh', description: 'High water level in tank, used when function tank control is enabled. And in this case buffertank is above critical level' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 10000000000) % 10 == 1)
        {
            var warning = { warningname: 'SuctionLance1Low', description: 'Detection of chemical level in can 1, triggered when level is low. Two levels this is first level, second level is error 8. correspoding to "almost" empty and "complete" empty' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 100000000000) % 10 == 1)
        {
            var warning = { warningname: 'SuctionLance2Low', description: 'Detection of chemical level in can 2, triggered when level is low. Two levels this is first level, second level is error 9. correspoding to "almost" empty and "complete" empty' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 1000000000000) % 10 == 1)
        {
            var warning = { warningname: 'ModuleHighTempBit', description: 'Inverter IGBT module temperature high' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 10000000000000) % 10 == 1)
        {
            var warning = { warningname: 'BoardHighTempBit', description: 'Inverter controller temperature high' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 100000000000000) % 10 == 1)
        {
            var warning = { warningname: 'Powerlimit', description: 'Max power for inverter, power limit activated' }
            arrayOfWarnings.push(warning)
        }
        if (Math.floor(WarningCode1 / 1000000000000000) % 10 == 1)
        {
            var warning = { warningname: 'Leakage start', description: 'Disables pressure start, until flow has been present' }
            arrayOfWarnings.push(warning)
        }
    }
    if (warningCode2 != 0)
    {
        const WarningCode2 = dec2bin(warningCode2);
        // if (WarningCode2 % 10 == 1)
        // {
        //   var warning = { warningname: 'P1_PresssureLowBit', description: 'Inlet pressure critical low' }
        //   arrayOfWarnings.push(warning)
        // }


    }

    return arrayOfWarnings
}

export
{
    decryptErrorCodes,
    decryptWarningCodes
};